import React, { Component } from "react";
import Search from "components/Search/Search";
import PhoenixAPI from "utils/PhoenixAPI";
import Status from "components/Common/Status";
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import AlertBox from "components/AlertBox/AlertBox";
import { Dropdown } from "semantic-ui-react";
import RichTextEditor from "react-rte";
import SelectCustomAddonCategory from "../../components/Common/SelectCustomAddonCategory";
import { UploadMediaFile } from "services/UploadMedia";
import CustomTestAddonCityPricing from "components/Common/CustomTestAddonCityPricing";

const STATUS_OPTIONS = [
  {
    key: true,
    value: true,
    text: "Active",
  },
  {
    key: false,
    value: false,
    text: "Inactive",
  },
];
class CustomTestAddons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      category: {},
      errors: {},
      editables: {},
      showModal: false,
      loadError: null,
      searchTerm: null,
      addonDescriptionEn: RichTextEditor.createEmptyValue(),
      addonDescriptionAr: RichTextEditor.createEmptyValue(),
    };

    this.columns = [
      {
        name: "ID",
        selector: "id",
        width: "100px",
        sortable: true,
      },
      {
        name: "Addon Name",
        selector: "addonNameEn",
        wrap: true,
        sortable: true,
      },
      {
        name: "Addon Category",
        selector: "addonCategory",
        wrap: true,
        sortable: true,
      },
      {
        name: "Status",
        selector: "is_active",
        maxWidth: "150px",
        center: true,
        cell: (p) => <Status isActive={p.isActive} />,
        sortable: true,
      },
      {
        name: "View",
        center: true,
        cell: (p) => this.renderViewButton(p.id),
      },
    ];
  }

  renderViewButton = (id) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleView(id);
        }}
      >
        View
      </button>
    </div>
  );

  componentDidMount() {
    this.fetchCustomTestAddOns();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.selectedCountry !== nextProps.selectedCountry) {
      this.setState({
        countries: nextProps.countries,
        selectedCountry: nextProps.selectedCountry,
      });
    }
  }

  fetchCustomTestAddOns = async () => {
    try {
      this.showLoading();
      const { data: categories = [] } = await PhoenixAPI.get("custom-addon");
      this.setState({ categories });
    } catch (error) {
      console.log("Error in fetching custom test categories");
      this.showErrorNotification(
        "Error in fetching custom test categories",
        error.message
      );
    } finally {
      this.hideLoading();
    }
  };

  handleChange = ({ currentTarget: input }) => {
    const category = { ...this.state.category };
    category[input.name] = input.value;
    this.setState({ category });
  };

  handleSearch = (queryString) => {
    this.setState({
      searchApplied: true,
      searchTerm: queryString ? queryString : "",
    });
  };

  handleSearchClear = () => {
    this.setState({
      searchTerm: "",
      searchApplied: false,
    });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({
        searchTerm: "",
        searchApplied: false,
      });
  };

  handleAddNew = () => {
    this.setState({ isNewCategory: true }, () => this.showModal());
  };

  handleRichText = (value, name) => {
    this.setState({ [name]: value });
  };

  handleSelectImage = async (e) => {
    if (e.target.files && e.target.files.length) {
      const image = e.target.files[0];

      try {
        const { message: uploadedImage = "" } = await UploadMediaFile(image);

        const category = {
          ...this.state.category,
          image_src: uploadedImage,
          image: uploadedImage,
        };
        this.showSuccessNotification("Image is uploaded successfully");
        this.setState({ category });
      } catch (error) {
        this.showErrorNotification("Error on uploading image: ", error.message);
      }
    }
  };

  handleView = (categoryId) => {
    const categories = this.state.categories || [];
    const category = categories.find((it) => it.id === categoryId);
    if (!category) {
      console.log(`Other Item with id ${categoryId} is not found!`);
      return;
    }
    const addonDescriptionEn = RichTextEditor.createValueFromString(
      category.addonDescriptionEn ?? "",
      "html"
    );
    const addonDescriptionAr = RichTextEditor.createValueFromString(
      category.addonDescriptionAr ?? "",
      "html"
    );
    category.image_src = category.image;
    this.setState(
      {
        category,
        isNewCategory: false,
        addonDescriptionEn,
        addonDescriptionAr,
      },
      () => this.showModal()
    );
  };

  showModal = () => {
    const { selectedCountry, isNewCategory } = this.state;
    if (isNewCategory) {
      const category = {
        countryId: selectedCountry?.id,

        isActive: false,
      };

      this.setState({
        category,

        showModal: true,
      });
    } else {
      this.setState({ showModal: true });
    }
  };

  hideModal = () => {
    this.setState({ showModal: false });
    this.resetForm();
  };

  resetForm = () => {
    this.setState({
      errors: {},
      editables: {},
      category: {},
      isNewCategory: false,
      addonDescriptionEn: RichTextEditor.createEmptyValue(),
      addonDescriptionAr: RichTextEditor.createEmptyValue(),
    });
  };

  findCountry = (id) => {
    const countries = this.state.countries || [];
    return countries.find((country) => country.id === id);
  };

  updateCategory = async (category) => {
    const { addonDescriptionEn, addonDescriptionAr } = this.state;
    const { customAddonCities, image_src, ...customTestAddon } = category;
    const payload = {
      ...customTestAddon,
      addonDescriptionEn: addonDescriptionEn?.toString("html"),
      addonDescriptionAr: addonDescriptionAr?.toString("html"),
    };
    try {
      this.showLoading();
      const response = await PhoenixAPI.patch(
        `custom-addon/${category.id}`,
        payload
      );
      return response;
    } catch (error) {
      console.log("Error in updating custom test category");
      this.showErrorNotification(
        "Error in updating custom test category",
        error.message
      );
    } finally {
      this.hideLoading();
    }
  };

  saveCategory = async (category) => {
    const { addonDescriptionEn, addonDescriptionAr } = this.state;
    const { customAddonCities, image_src, ...customTestAddon } = category;
    const payload = {
      ...customTestAddon,
      addonDescriptionEn: addonDescriptionEn?.toString("html"),
      addonDescriptionAr: addonDescriptionAr?.toString("html"),
    };
    try {
      this.showLoading();
      const response = await PhoenixAPI.post(`custom-addon`, payload);
      return response;
    } catch (error) {
      console.log("Error in creating custom test addon");
      this.showErrorNotification(
        "Error in creating custom test addon",
        error.message
      );
    } finally {
      this.hideLoading();
    }
  };

  handleSave = async () => {
    if (!this.state.selectedCountry) {
      this.showErrorNotification("Please select a country!");
      return;
    }
    const errors = this.validate();
    console.log("errors:", errors);
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }
    const { category } = this.state;
    const isValidId = category.id !== null && category.id !== undefined;
    const response = isValidId
      ? await this.updateCategory(category)
      : await this.saveCategory(category);
    try {
      this.hideLoading();
      this.hideModal();
      this.showSuccessNotification(
        isValidId ? "Updated Successfully." : "Created Successfully."
      );
      this.fetchCustomTestAddOns();
    } catch (error) {
      this.hideLoading();
      this.showErrorNotification("Some error occurred: " + error.message);
      console.error("Some error occurred", error.message);
    }
  };

  handleCityPackageItemsUpdated = (cityPackageItems = []) => {
    const { category } = this.state;
    this.setState({
      category: { ...category, customAddonCities: cityPackageItems },
    });
  };
  validate = () => {
    const {category, addonDescriptionEn,addonDescriptionAr }= this.state;
       const addOnEng= addonDescriptionEn?.toString("html");
       const addOnAr= addonDescriptionAr?.toString("html");
      

    const errors = {};
    if (addOnEng && addOnEng.length > 1000)
      errors.addonDescriptionEn = "Add on Description (English)  is limited to a 1000 characters";
      if (addOnAr && addOnAr.length > 1000)
        errors.addonDescriptionAr = "Add on Description (Arabic)  is limited to a 1000 characters";
    return Object.keys(errors).length === 0 ? null : errors;
  };

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  renderPencilIcon = (fieldName) => {
    const isEditable = this.props.isEditable ?? true;
    return isEditable && !this.state.isNewCategory ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );
  };

  getFilteredbanners = () => {
    const { categories, selectedCountry } = this.state;
    const categoriesForCurrentCountry =
      categories && selectedCountry
        ? categories.filter((p) => p.countryId === selectedCountry.id)
        : [];
    return this.getSearchedCategories(categoriesForCurrentCountry);
  };

  getSearchedCategories = (categories) => {
    const { searchApplied, searchTerm } = this.state;
    if (!searchApplied || !searchTerm) return categories;
    const newValue = searchTerm.slice(0).trim().toLowerCase();
    return categories.filter((category) => {
      const { id, name } = category;
      return (
        (name && name.toLowerCase().indexOf(newValue) !== -1) ||
        (id && id.toString() === newValue)
      );
    });
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  render() {
    const {
      searchTerm,
      editables,
      errors,
      isNewCategory,
      category,
      countries,
      selectedCountry,
    } = this.state;
    const finalList = this.getFilteredbanners();
    const isEditable = this.props.isEditable ?? true;
    const detailsForm = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="addonNameEn">Addons Name (English)</label>
            <div className="input-group">
              <input
                value={category?.addonNameEn || ""}
                onChange={this.handleChange}
                id="addonNameEn"
                name="addonNameEn"
                type="text"
                placeholder="AddonName in English"
                className={
                  "form-control py-2" + (errors.addonNameEn ? " has-error" : "")
                }
                disabled={!isNewCategory && !editables.addonNameEn}
              />
              {this.renderPencilIcon("addonNameEn")}
            </div>
            <span className="help-block">{errors.addonNameEn}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="addonNameAr">Addon Name (Arabic)</label>
            <div className="input-group">
              <input
                value={category?.addonNameAr || ""}
                onChange={this.handleChange}
                id="addonNameAr"
                name="addonNameAr"
                type="text"
                placeholder="Addon Name in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.addonNameAr ? " has-error" : "")
                }
                disabled={!isNewCategory && !editables.addonNameAr}
              />
              {this.renderPencilIcon("addonNameAr")}
            </div>
            <span className="help-block">{errors.addonNameAr}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label>Addons Category</label>
            <div className="input-group" style={{zIndex:"99999"}}>
              <SelectCustomAddonCategory
                selectedCustomPackageIds={category.addonCategoryId ?? []}
                id="select custom Test Addons"
                name="select custom Test Addons"
                countryId={selectedCountry?.id}
                onSelect={(options) => {
                  const newCategory = {
                    ...category,
                    addonCategoryId: options.value,
                  };
                  this.setState({
                    category: newCategory,
                  });
                }}
                menuPlacement={"bottom"}
                showOnlyActive={true}
                isDisabled={!isNewCategory && !editables.addonCategoryId}
                className={
                  "form-control" + (errors.addonCategoryId ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("addonCategoryId")}
            </div>
          </div>
          <div className="form-group col">
            <label htmlFor="status">Status</label>
            <div className="input-group"  style={{zIndex:"99999"}}>
              <Dropdown
                value={category?.isActive}
                onChange={(event, value) => {
                  this.setState({
                    category: {
                      ...category,
                      isActive: value.value,
                    },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewCategory && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col" style={{ maxWidth: "50%" }}>
            <label>Addon Description (with RichText English)</label>
            <div className="input-group">
              <RichTextEditor
                rootStyle={{
                  position: "relative",
                  flex: "1 1 auto",
                  width: "1%",
                  marginBottom: 0,
                }}
                editorStyle={{ height: "90px", overflow: "hidden" }}
                value={this.state.addonDescriptionEn}
                onChange={(value) =>
                  this.handleRichText(value, "addonDescriptionEn")
                }
                disabled={!isNewCategory && !editables.addonDescriptionEn}
              />
              {this.renderPencilIcon("addonDescriptionEn")}
            </div>
            <span className="help-block">{errors.addonDescriptionEn}</span>
          </div>

          <div className="form-group col" style={{ maxWidth: "50%" }}>
            <label>Addon Description (with RichText Arabic)</label>
            <div className="input-group">
              <RichTextEditor
                rootStyle={{
                  position: "relative",
                  flex: "1 1 auto",
                  width: "1%",
                  marginBottom: 0,
                }}
                editorStyle={{ height: "90px", overflow: "hidden" }}
                value={this.state.addonDescriptionAr}
                onChange={(value) =>
                  this.handleRichText(value, "addonDescriptionAr")
                }
                disabled={!isNewCategory && !editables.addonDescriptionAr}
              />
              {this.renderPencilIcon("addonDescriptionAr")}
            </div>
            <span className="help-block">{errors.addonDescriptionAr}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="imageInput">Image</label>
            <div className="input-group">
              <input
                onChange={this.handleSelectImage}
                id="imageInput"
                name="image"
                type="file"
                accept="image/*"
                disabled={!isNewCategory && !editables.image}
                className={"form-control" + (errors.image ? " has-error" : "")}
              />
              {this.renderPencilIcon("image")}
            </div>
            <span className="help-block">{errors.image}</span>
          </div>
          <div className="form-group col">
            {category.image_src && (
              <>
                <label htmlFor="selected_package_image">Preview</label>
                <div>
                  <img
                    id="selected_package_image"
                    name="selected_package_image"
                    src={category.image_src}
                    alt="Selected"
                    className="img-thumbnail mx-auto"
                    style={{
                      width: "100%",
                      maxHeight: "200px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );

    const customPackageCitiesDetails = (
      <>
        <div className="row mt-2">
          <div className="col separator">
            <span>City Prices</span>
          </div>
        </div>
        <CustomTestAddonCityPricing
          cityPackageDetails={category.customAddonCities || []}
          onCityPackageDetailsUpdated={this.handleCityPackageItemsUpdated}
          packageType={"customTestAddons"}
          packageId={category.id || null}
          selectedCountry={selectedCountry}
          isEditable={isEditable}
        />
      </>
    );

    const locationAndPricingForm = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="country">Country</label>
            <input
              value={
                isNewCategory
                  ? selectedCountry && selectedCountry.country_name
                  : selectedCountry && selectedCountry.country_name
              }
              type="text"
              id="country"
              name="country"
              readOnly
              className="form-control"
            />
          </div>
          <div className="form-group col">
            <label htmlFor="currency">Currency</label>
            <input
              value={
                isNewCategory
                  ? selectedCountry && selectedCountry.currency
                  : selectedCountry && selectedCountry.currency
              }
              type="text"
              id="currency"
              name="currency"
              readOnly
              className="form-control"
            />
          </div>
        </div>

        {customPackageCitiesDetails}
      </div>
    );

    return (
      <>
        <div className="manage-package">
          <div className="page-header">
            <div className="actions">
              <div className="action-item">
                <Search
                  searchExpanded={true}
                  searchTerm={searchTerm}
                  handleSearch={this.handleSearch}
                  clearSearch={this.handleSearchClear}
                  handleSearchExit={this.handleSearchExit}
                  ref={(input) => {
                    this.search = input;
                  }}
                />
              </div>
              <div className="action-item">
                <button
                  style={{ backgroundColor: "#8FD14F" }}
                  className="btn btn-md new-user"
                  onClick={this.handleAddNew}
                  disabled={!isEditable}
                >
                  <i className="fa fa-plus-circle"></i>Add New
                </button>
              </div>
            </div>
          </div>

          <Card body>
            <h3 className="p-head">Custom Addons</h3>
            <DataTable
              data={finalList}
              columns={this.columns}
              defaultSortField="id"
              defaultSortAsc={false}
              sortIcon={<ArrowDownward></ArrowDownward>}
              highlightOnHover
              responsive
              noHeader={this.state.searchApplied ? false : true}
              title={
                this.state.searchApplied ? (
                  <p className="table-header">
                    {"Found " + finalList.length + " results"}
                  </p>
                ) : (
                  ""
                )
              }
              noDataComponent={
                this.state.loadError ? (
                  <AlertBox
                    message={this.state.loadError}
                    error={true}
                  ></AlertBox>
                ) : (
                  <AlertBox message="There's nothing here."></AlertBox>
                )
              }
            />
          </Card>

          <Modal
            size="lg"
            show={this.state.showModal}
            onHide={this.hideModal}
            className="user-data-modal"
          >
            <Modal.Header closeButton>
              <Modal.Title></Modal.Title>
              <div className="user-title"></div>
              <button
                onClick={this.handleSave}
                disabled={!isEditable}
                className="btn modal-save-btn"
              >
                Save
              </button>
            </Modal.Header>
            <Modal.Body>
              <Tabs id="tabs_customaddons" justify>
                <Tab eventKey="key_form_details" title="Details">
                  {detailsForm}
                </Tab>
                <Tab eventKey="key_location_pricing" title="Location & Pricing">
                  {locationAndPricingForm}
                </Tab>
              </Tabs>
            </Modal.Body>
          </Modal>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomTestAddons);
