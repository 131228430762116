import React from "react";
import PropTypes from "prop-types";
import { Card } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import Select from "react-select";
import HomecareCosts from "pages/HomecareCosts/HomecareCosts";

function OperationsInternalCard(props) {
  const { cartItemDetails, operationsUsers, labs, paymentGateways, homeCareServices } =
    props;

  const {
    handledById,
    customTestPriceAdditional,
    lab1Id,
    lab2Id,
    lab1CustomCost,
    lab2CustomCost,
    notes,
    referenceNumber1,
    referenceNumber2,
    refundAmount,
    revisedPrice,
    revisedPriceAfterCoupon,
    paymentGateway1Id,
    paymentGateway2Id,
    couponAmount,
    customTestBiomarkers,
    homeCareCost,
    homeCareId,
    couponCodeValue,
    sampleDetailsLab1,
    sampleDetailsLab2,
    specialInstructions,
    paymentToBeCollected,
    labCost,
    labCostBreakUpPrice,
    labCostNotes,
    homecareCostNotes
  } = cartItemDetails;
  console.log("cartItemDetailscartItemDetails", cartItemDetails)

  const renderLabCostBreakPrices =labCostBreakUpPrice && labCostBreakUpPrice;
  return (
    <>
      <div className="form-content">
        <span>Operations Internal</span>
      </div>
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="handledBy">Handled By</label>
            <div className="input-group user-input-container">
              <select
                name="handled_by"
                className="form-control py-2"
                value={handledById}
                options={operationsUsers}
                disabled={true}
              >
                {
                  (!handledById) && <option key={-1} value={-1} hidden={true}>Select Handled By</option>
                }
                {operationsUsers &&
                  operationsUsers.map((status, index) => (
                    <option key={index} value={status.id}>
                      {status.userName}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="form-group col">
            <label htmlFor="customTestPrice">
              Custom Test Price Additional
            </label>
            <div className="input-group">
              <input
                value={customTestPriceAdditional}
                id="custom_test_price_additional"
                name="custom_test_price_additional"
                type="number"
                placeholder="Custom Test Price Additional"
                className={"form-control py-2"}
                readOnly={true}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="form-group col">
            <label htmlFor="lab1"> Lab 1</label>
            <div className="input-group user-input-container">
              <select
                name="lab_1_id"
                className={`form-control user-data-input`}
                value={lab1Id}
                disabled={true}
              >
                {!lab1Id && (
                  <option key={-1} value={-1} hidden={true}>
                    Select Lab 1
                  </option>
                )}
                {labs &&
                  labs.map((status, index) => (
                    <option key={index} value={status.id}>
                      {status.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="form-group col">
            <label htmlFor="customTestPrice">Lab 1 custom cost</label>
            <div className="input-group">
              <input
                value={lab1CustomCost}
                id="lab_1_custom_cost"
                name="lab_1_custom_cost"
                type="number"
                placeholder="Lab 1 custom cost"
                className={"form-control"}
                readOnly={true}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="homeCare">Homecare</label>
            <div className="input-group user-input-container">
              {/* <Dropdown
                value={homeCareId}
                id="home_care_id"
                name="home_care_id"
                placeholder="Homecare"
                search
                selection
                // options={this.getHomecareServiceOptions() ?? []}
                disabled={true}
                className={`form-control user-data-input`}
              /> */}
              <select
                name="home_care"
                className={`form-control user-data-input`}
                value={homeCareId}
                disabled={true}
              >
                {!homeCareId && (
                  <option key={-1} value={-1} hidden={true}>
                    Select HomeCare
                  </option>
                )}
                {homeCareServices &&
                  homeCareServices.map((status, index) => (
                    <option key={index} value={status.id}>
                      {status.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="form-group col">
            <label htmlFor="home_care_cost">Homecare Costs</label>
            <div className="input-group user-input-container">
              <input
                value={homeCareCost}
                type="number"
                id="home_care_cost"
                name="home_care_cost"
                placeholder="Homecare Costs"
                className={`form-control user-data-input`}
                readOnly={true}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="handledBy">Payment Gateway 1</label>
            <div className="input-group user-input-container">
              {/* <Dropdown
                value={paymentGateway1Id}
                id="payment_gateway_1"
                name="payment_gateway_1"
                placeholder="Payment Gateway 1"
                search
                selection
                // options={this.getPaymentGateways() ?? []}
                disabled={true}
                className={`form-control user-data-input`}
              /> */}
              <select
                name="lab_2"
                className={`form-control user-data-input`}
                value={paymentGateway1Id}
                disabled={true}
              >
                {!paymentGateway1Id && (
                  <option key={-1} value={-1} hidden={true}>
                    Select Payment Gateway 1
                  </option>
                )}
                {paymentGateways &&
                  paymentGateways.map((status, index) => (
                    <option key={index} value={status.id}>
                      {status.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="form-group col">
            <label htmlFor="customTestPrice">Reference Number 1</label>
            <div className="input-group">
              <input
                value={referenceNumber1}
                id="reference_number_1"
                name="reference_number_1"
                type="text"
                placeholder="Reference Number 1"
                className={"form-control"}
                readOnly={true}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="lab2">Lab 2</label>
            <div className="input-group user-input-container">
              <select
                name="lab_2"
                className={`form-control user-data-input`}
                value={lab2Id}
                disabled={true}
              >
                {!lab2Id && (
                  <option key={-1} value={-1} hidden={true}>
                    Select Lab 2
                  </option>
                )}
                {labs &&
                  labs.map((status, index) => (
                    <option key={index} value={status.id}>
                      {status.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="form-group col">
            <label htmlFor="customTestPrice">Lab 2 custom cost</label>
            <div className="input-group">
              <input
                value={lab2CustomCost}
                id="lab_2_custom_cost"
                name="lab_2_custom_cost"
                type="number"
                placeholder="Lab 2 custom cost"
                className={"form-control"}
                readOnly={true}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="handledBy">Payment Gateway 2</label>
            <div className="input-group user-input-container">
              {/* <Dropdown
                value={paymentGateway2Id}
                id="payment_gateway_2"
                name="payment_gateway_2"
                placeholder="Payment Gateway 2"
                search
                selection
                // options={this.getPaymentGateways() ?? []}
                disabled={true}
                className={`form-control user-data-input`}
              /> */}
              <select
                name="lab_2"
                className={`form-control user-data-input`}
                value={paymentGateway2Id}
                disabled={true}
              >
                {!paymentGateway2Id && (
                  <option key={-1} value={-1} hidden={true}>
                    Select Payment Gateway 2
                  </option>
                )}
                {paymentGateways &&
                  paymentGateways.map((status, index) => (
                    <option key={index} value={status.id}>
                      {status.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="form-group col">
            <label htmlFor="customTestPrice">Reference Number 2</label>
            <div className="input-group">
              <input
                value={referenceNumber2}
                id="reference_number_2"
                name="reference_number_2"
                type="text"
                placeholder="Reference Number 2"
                className={"form-control"}
                readOnly={true}
              />
            </div>
          </div>
        </div>
        <div className="row">
         
        <div className="form-group col">
            <label htmlFor="handledBy">Coupon Code</label>
            <div className="input-group user-input-container">
              <input
                value={couponCodeValue}
                id="coupon_amount"
                name="coupon_amount"
                type="text"
                placeholder="Coupon Code"
                className={"form-control"}
                readOnly={true}
              />
            </div>
          </div>
          <div className="form-group col">
            <label htmlFor="handledBy">Coupon</label>
            <div className="input-group user-input-container">
              {/* <Dropdown
                value={couponAmount}
                id="coupon_amount"
                name="coupon_amount"
                placeholder="Coupon Amount"
                search
                selection
                disabled={true}
                className={`form-control user-data-input`}
              /> */}
              <input
                value={couponAmount}
                id="coupon_amount"
                name="coupon_amount"
                type="number"
                placeholder="Coupon Amount"
                className={"form-control"}
                readOnly={true}
              />
            </div>
          </div>
        </div>
        <div className="row">
        <div className="form-group col">
            <label htmlFor="customTestPrice">Revised Price</label>
            <div className="input-group">
              <input
                value={revisedPrice}
                id="revised_price"
                name="revised_price"
                type="number"
                placeholder="Revised Price"
                className={"form-control"}
                readOnly={true}
              />
            </div>
          </div>
          <div className="form-group col">
            <label htmlFor="customTestPrice">Revised Price after Coupon</label>
            <div className="input-group">
              <input
                value={revisedPriceAfterCoupon}
                id="revised_price_after_coupon"
                name="revised_price_after_coupon"
                type="number"
                placeholder="Revised Price after Coupon"
                className={"form-control"}
                readOnly={true}
              />
            </div>
          </div>

        </div>
        <div className="row">
        <div className="form-group col">
            <label htmlFor="customTestPrice">Refund Amount</label>
            <div className="input-group">
              <input
                value={refundAmount}
                id="refund_amount"
                name="refund_amount"
                type="number"
                placeholder="Refund Amount"
                className={"form-control"}
                readOnly={true}
              />
            </div>
          </div>
          <div className="form-group col">
            <label htmlFor="customTestPrice">Custom Test - Biomarkers</label>
            <div className="input-group">
              <textarea
                value={customTestBiomarkers}
                id="custom_test_biomarkers"
                name="custom_test_biomarkers"
                type="text"
                placeholder="Custom Test - Biomarkers"
                className={"form-control"}
                readOnly={true}
              />
            </div>
          </div>
        </div>
        <div className="row">
        <div className="form-group col-6">
            <label htmlFor="customTestPrice">Notes</label>
            <div className="input-group">
              <textarea
                value={notes}
                id="notes"
                name="notes"
                type="text"
                placeholder="Notes"
                className={"form-control"}
                readOnly={true}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="sampleDetailsLab1">Sample Details Lab 1</label>
            <textarea
              value={sampleDetailsLab1 ?? ""}
              id="sampleDetailsLab1"
              name="sampleDetailsLab1"
              placeholder="Sample Details Lab 1"
              readOnly={true}
              className="form-control"
            />
          </div>
          <div className="form-group col">
            <label htmlFor="sampleDetailsLab2">Sample Details Lab 2</label>
            <textarea
              value={sampleDetailsLab2 ?? ""}
              id="sampleDetailsLab2"
              name="sampleDetailsLab2"
              placeholder="Sample Details Lab 2"
              readOnly={true}
              className="form-control"
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="specialInstructions">Special Instructions</label>
            <textarea
              value={specialInstructions ?? ""}
              id="specialInstructions"
              name="specialInstructions"
              placeholder="Special Instructions"
              readOnly={true}
              className="form-control"
            />
          </div>
          <div className="form-group col">
            <label htmlFor="paymentToBeCollected">Payment to be collected</label>
            <input
              value={paymentToBeCollected ?? ""}
              id="paymentToBeCollected"
              name="paymentToBeCollected"
              placeholder="Payment to be collected"
              readOnly={true}
              className="form-control"
              type="number"
            />
          </div>
        </div>
        <div className="row">
        <div className="form-group col">
            <label htmlFor="lab_cost">Lab cost</label>
            <div className="input-group">
              <input
                value={labCost}
                id="lab_cost"
                name="lab_cost"
                type="number"
                placeholder="Lab cost"
                className={"form-control"}
                readOnly={true}
              />
            </div>
          </div>
          <div className="form-group col">
            <label htmlFor="labCostBreakupPrice">Lab Cost Breakup Prices </label>
            <textarea
              value={renderLabCostBreakPrices ?? ""}
              id="labCostBreakupPrice"
              name="labCostBreakupPrice"
              placeholder="LabCost Breakup Prices"
              readOnly={true}
              className="form-control"
            />
          </div>
        </div>
        <div className="row">
        <div className="form-group col">
            <label htmlFor="labCostNotes">Lab Cost Notes </label>
            <textarea
              value={labCostNotes ?? ""}
              id="labCostNotes"
              name="labCostNotes"
              placeholder="Lab Cost Notes"
              readOnly={true}
              className="form-control"
            />
          </div>
          <div className="form-group col">
            <label htmlFor="homecareCostNotes">Homecare Cost Notes </label>
            <textarea
              value={homecareCostNotes ?? ""}
              id="homecareCostNotes"
              name="homecareCostNotes"
              placeholder="Homecare Cost Notes"
              readOnly={true}
              className="form-control"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default OperationsInternalCard;
