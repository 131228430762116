import React from "react";
import PropTypes from "prop-types";
import { Button, ButtonGroup, Dropdown } from "react-bootstrap";
import _ from "lodash";
import { compareStrings } from "utils/commons";

CityDropdown.propTypes = {
  cities: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedCity: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
};

CityDropdown.defaultTypes = {
  cities: [],
  onSelect: (f) => f,
};

function CityDropdown(props) {
  const { cities, selectedCity } = props;
  const [activeCities, inactiveCities] = _.partition(
    cities,
    (city) => city.is_active ?? false
  );
  const sortByName = (c1, c2) =>
    compareStrings(c1.city_name, c2.city_name, true);
  activeCities.sort(sortByName);
  inactiveCities.sort(sortByName);

  return (
    <Dropdown as={ButtonGroup}>
      <Button variant="secondary">
        {selectedCity ? selectedCity.city_name : "Select City"}
      </Button>
      <Dropdown.Toggle split variant="primary" id="dropdown-split-basic" />
      <Dropdown.Menu>
        <Dropdown.Header>Active Cities</Dropdown.Header>
        {activeCities && renderCityDropdownItems(activeCities, props)}
        <Dropdown.Divider />
        <Dropdown.Header>Inactive Cities</Dropdown.Header>
        {inactiveCities && renderCityDropdownItems(inactiveCities, props)}
      </Dropdown.Menu>
    </Dropdown>
  );
}

function renderCityDropdownItems(cities, { selectedCity, onSelect }) {
  return cities.map((city) => (
    <Dropdown.Item
      key={city.id}
      as="button"
      active={selectedCity && selectedCity.id === city.id}
      onClick={() => onSelect(city)}
    >
      {city.city_name}
    </Dropdown.Item>
  ));
}

export default CityDropdown;
