import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Dropdown } from "semantic-ui-react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import PaymentInfoForm from "./PaymentInfoForm";
import CartItemsForm from "./CartItemsForm";
import { fetchClientUsersByEmail,getClientByEmailOnCartOrders} from "services/UserService";
import { createOrder } from "services/OrderService";
import { fetchMiniPackageCityConfig, fetchSupplementDeliveryFeeConfig } from "services/productService";
import PhoenixAPI from "utils/PhoenixAPI";
import {getUserId} from "../../services/UserService";
const ORDER_FLOW_OPTIONS = [
  {
    key: "Current",
    value: "Current",
    text: "Update the slot",
  },
  {
    key: "Past",
    value: "Past",
    text: "Don't update slot",
  },
];

class CreateOrderViewModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderFlow: "Current",
      clients: [],
      cartItems: [],
      selectedClient: null,
      selectedClientAddress: null,
      selectedClientFamilyMembers: [],
      emailSearchTerm: null,
      clientOptions: [],
      clientAddressOptions: [],
      currentStepNumber: 0,
      discountDetails: this.getInitDiscountDetails(),
      selfResultUpload: false,
      miniPackageCityConfig: null,
      errors: {},
      supplementDeliveryFeeConfig:null,
      nationalityErrorModal:false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { isVisible } = this.props;
    const { isVisible: oldIsVisible } = prevProps;
    if (isVisible !== oldIsVisible) this.resetForm();

    const {
      orderFlow: oldOrderFlow,
      selectedClient: oldSelectedClient,
      selectedClientAddress: oldSelectedClientAddress,
    } = prevState;
    const { orderFlow, selectedClient, selectedClientAddress } = this.state;
    if (
      orderFlow !== oldOrderFlow ||
      selectedClient?.id !== oldSelectedClient?.id ||
      selectedClientAddress?.id !== oldSelectedClientAddress?.id
    ) {
      this.setState({
        cartItems: [],
        discountDetails: this.getInitDiscountDetails(),
      });
    }
  }

  fetchMiniPackageCityConfig = async () => {
    const { city: cityName, country: countryName } = this.state.selectedClientAddress ?? {};
    const city = this.findCity(cityName);
    if (!city) return;

    const country = this.findCountry(countryName);
    if (!country) return;

    try {
      const miniPackageCityConfig = await fetchMiniPackageCityConfig(country.id, city.id)
      this.setState({ miniPackageCityConfig });
    } catch (error) {
      console.log("fetchMiniPackageCityConfig:: Error on fetching mini package city config!", error);
      // Don't show error notification!
      // this.showErrorNotification("Error on fetching mini package city config! " + error.message);
    }
  }

  fetchSupplementDeliveryFeeConfig=async()=>{
    const { city: cityName, country: countryName } = this.state.selectedClientAddress ?? {};
    const city = this.findCity(cityName);
    if (!city) return;

    const country = this.findCountry(countryName);
    if (!country) return;
    try {
      const supplementDeliveryFeeConfig = await fetchSupplementDeliveryFeeConfig(country.id)
      console.log("supplementDeliveryFeeConfig", supplementDeliveryFeeConfig)
      this.setState({ supplementDeliveryFeeConfig });
    } catch (error) {
      console.log("supplementDeliveryFeeConfig:: Error on fetching supplement delivery fee config!", error);
      // Don't show error notification!
      // this.showErrorNotification("Error on fetching mini package city config! " + error.message);
    }

  }

  getInitDiscountDetails = () => ({
    couponApplied: false,
    couponCode: null,
    discount: 0,
    totalAmount: 0,
    deliveryCharges: 0,
    additionalDiscount:0,
    additionalDicountNote:"",
    finalAmount: 0,
    paymentMode: null,
    paymentGateway: null,
    transactionReference: null,

    orderCreatedById:getUserId(),

    cashbackAmount:0,
    vatAmount:0,
    childCouponDiscount:[],
    isNotifyClientEmail:true,
  });

  resetForm = () => {
    this.setState({
      orderFlow: "Current",
      currentStepNumber: 0,
      emailSearchTerm: null,
      selectedClient: null,
      selectedClientAddress: null,
      selectedCouponId: null,
      clientOptions: [],
      clientAddressOptions: [],
      cartItems: [],
      discountDetails: this.getInitDiscountDetails(),
      selfResultUpload: false,
      errors: {},
      miniPackageCityConfig:null,
      supplementDeliveryFeeConfig:null,
      nationalityErrorModal:false,
    });
  };

  handleSave = () => {
    console.log("CreateOrderViewModal: handleSave clicked");
    this.createOrder();
  };

  calculateSubscriptionPrice = (cartItem) => {
    const { price, subscriptionDetail } = cartItem;
    const { discountType, discountAmount } = subscriptionDetail ?? {};
    if (price && discountType && discountAmount) {
      if (discountType.toLowerCase() === "fixed") {
        return price - Math.min(price, discountAmount);
      } else {
        return price - Math.min(price, this.percentOf(price, discountAmount));
      }
    }
  }

  percentOf = (amount, percentage) => {
    const result = amount * (percentage / 100);
    return parseFloat(result.toFixed(2));
  }

  removeMedicinePrice = (cartItems) => {
    let itemTotal = 0
    let medicineItems = cartItems.filter((it) => it.itemType === "Medicine" ||  it.itemType === "Supplement")
    medicineItems.length > 0 && medicineItems.map((item) => {
        const {  price:finalPrice=0,subscriptionDetails = {} } = item
      
        let fPrice = 0
        if (subscriptionDetails) {
            const { discountType = "", discountAmount = "" } = subscriptionDetails
            let discount = 0
            if (discountType === "Fixed") {
                discount = discountAmount
            }
            else if (discountType === "Percentage") {
                discount = finalPrice * (discountAmount / 100);
            }
            fPrice = (finalPrice - discount) * item.quantity;
        }
        else {
            fPrice = finalPrice * (item.quantity || 1);
        }
        itemTotal = itemTotal + fPrice
    })
    return itemTotal
}

 calculateVatPercentage = async (total, countryObject) => {
  
  if (Object.keys(countryObject).length > 0) {
    const vatPercentage = countryObject.vat_percentage;
    const vatAmount = (total * vatPercentage) / 100;
    return vatAmount.toFixed(2);
  }
}

  createOrder = async () => {
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }
    
    const {
      orderFlow,
      cartItems,
      selectedClient,
      selectedClientAddress,
      discountDetails,
      selfResultUpload,
    } = this.state;
    const { city, country } = selectedClientAddress ?? {};
    const {
      couponApplied,
      couponCode,
      discount,
      totalAmount,
      deliveryCharges,
      finalAmount,
      paymentMode,
      paymentGateway,
      transactionReference,
      orderCreatedById,
      walletAmount,

      additionalDiscount,
      additionalDicountNote,


      cashbackAmount,
      nurseVisitFee,
      childCouponDiscount,
      isNotifyClientEmail,
    } = discountDetails ?? {};
    console.log("discountDetails createOrderViewModal", discountDetails);
    const cityObject = this.findCity(city);
    const countryObject = this.findCountry(country);
    const clientName = selectedClient.username
      ? selectedClient.username
      : `${selectedClient.first_name} ${selectedClient.last_name}`;
    const description = `${clientName}, MULTIPLE_ITEM${
      couponApplied ? `, ${couponCode}` : ", No Coupon"
    }`;
    
    
    
    const vatPercentage = countryObject
      ? Number(countryObject.vat_percentage)
      : null;
      let taxAmountFinal=totalAmount;
   const  taxPayAmount= cartItems.reduce((taxAmountFinal,cartItem) => {
        const copyOfCartItem = { ...cartItem };
        console.log("cart item in vat",copyOfCartItem);
       if(copyOfCartItem?.vat==0 && ["Blood_Package","Custom_Package","Supplement","Mini_Package"].includes(copyOfCartItem.itemType))
          taxAmountFinal=taxAmountFinal-copyOfCartItem.price*copyOfCartItem.quantity;
        if(copyOfCartItem.itemType=="Medicine")
        {
           taxAmountFinal=taxAmountFinal-copyOfCartItem.price*copyOfCartItem.quantity;

        }
        return taxAmountFinal;
      }
    ,totalAmount)
 
 
    const taxAmount =
    
      vatPercentage
      ? (taxPayAmount * vatPercentage) / 100
      : null;  

  // let medicineItemPrice = this.fetchSupplementsMedicinesPricingData(cartItems)
  // const finalTotalAmount = Math.abs(totalAmount - medicineItemPrice)
  // const taxAmount=await this.calculateVatPercentage(finalTotalAmount, countryObject);
    console.log("cartItems cartItems:", cartItems, selectedClient)
    
    const nationality= selectedClient.nationality ??"";
    let modifiedCartItems=cartItems
    let nationalityTaxAmount=0;
    if(nationality==="saudi" && country==="Kingdom of Saudi Arabia"){
      nationalityTaxAmount=0;
    }
    else if (nationality==="expats" && country==="Kingdom of Saudi Arabia"){
      nationalityTaxAmount=modifiedCartItems.length>0? modifiedCartItems.map((cartItem)=>{
        if(cartItem.vat==0 && ["Blood_Package","Custom_Package","Mini_Package"].includes(cartItem.itemType))
         return 0;
        else{ if(!["Supplement"].includes(cartItem.itemType) ){
        const taxAmount= (cartItem.totalPrice)*(vatPercentage)/100;
        return taxAmount;
        }
        else{
          return 0;
        }
        }
    }).reduce((total, current) => total + current, 0):0;

    }   // tax amount calculation of nationality 
    
    let createOrderFinalTaxAmount=0;
    if(nationality==="saudi" && country==="Kingdom of Saudi Arabia"){
      createOrderFinalTaxAmount=nationalityTaxAmount
    }
    else if(nationality==="expats" && country==="Kingdom of Saudi Arabia"){
      createOrderFinalTaxAmount=nationalityTaxAmount 
    }
    else {
      createOrderFinalTaxAmount=taxAmount 
    }
    console.log("nationalitynationality taxass",nationality,nationalityTaxAmount, createOrderFinalTaxAmount)
    const finalCartItems = cartItems.map(itemH => {
      const matchingGItem = childCouponDiscount.length>0 ?childCouponDiscount.find(itemG => itemG.orderItemId === itemH.itemId):[];
      if (matchingGItem) {
          return {
              ...itemH,
              couponDiscount: matchingGItem.childOrderDiscount ??0
          };
      } else {
          return itemH;
      }
  });

  console.log("finalCartItems:", finalCartItems);
    try {
      this.showLoading();
      const payload = {
        orderFlow, 
        orderType: "one-time",
        userId: selectedClient?.id,
        addressId: selectedClientAddress?.id,
        countryCode: countryObject?.country_code,
        countryId: countryObject?.id,
        cityId: cityObject?.id,
        description,
        paymentMode: paymentMode ?? null,
        paymentGateway: paymentGateway ?? null,
        totalAmount,
        cashbackAmount:Number(cashbackAmount)>0?cashbackAmount:null,
        // finalAmount: walletAmount ? finalAmount - Number(walletAmount) : finalAmount,
        finalAmount,
        currency: countryObject?.currency,
        orderSource: "ADMIN_PANEL",
        orderCategory: "CART",
        couponApplied: couponApplied ?? false,
        couponCode: couponApplied ? couponCode : null,
        discount: couponApplied ? discount : null,
        paymentType: "Regular",
        orderCreatedById:orderCreatedById,
        taxAmount:createOrderFinalTaxAmount-discount*(vatPercentage)/100,
        shippingAmount: 0,
        cartItems:
          finalCartItems &&
          finalCartItems.map((cartItem) => {
            const copyOfCartItem = { ...cartItem };
          
            copyOfCartItem.finalPrice = copyOfCartItem.price;
            if (copyOfCartItem.isFamilyMemberBooking) {
              copyOfCartItem.dependentId = copyOfCartItem.familyMemberId;
            }
            if (copyOfCartItem.isSubscriptionOrder) {
              copyOfCartItem.orderType = "subscription";
              const { subscriptionDetail } = cartItem;
              const discountedPrice= this.calculateSubscriptionPrice(cartItem)
              copyOfCartItem.finalPrice = discountedPrice;
              copyOfCartItem.subscriptionDetail = {
                "subscriptionItemId": subscriptionDetail?.id,
                "numberOfMonths": subscriptionDetail?.noOfMonths,
                "pricingType": subscriptionDetail?.discountType,
                "packageType": copyOfCartItem.itemType,
                "packageId": copyOfCartItem.itemId,
                "discountType": subscriptionDetail?.discountType,
                "discountAmount": subscriptionDetail?.discountAmount,
                "quantity": copyOfCartItem.quantity,
                "status": subscriptionDetail?.status,
                 "numberOfDays":subscriptionDetail?.noOfDays,
                 "subscriptionFreqType":subscriptionDetail.noOfMonths?"MONTHS":"DAYS"
              }   
              console.log("copy of cart Item",copyOfCartItem.subscriptionDetail);
            }

            if (copyOfCartItem.itemType === "Blood_Package") {
              copyOfCartItem.addOnMiniPackagesPrice = copyOfCartItem.miniPackagePrice||0;
              copyOfCartItem.price = (copyOfCartItem.price + (copyOfCartItem.miniPackagePrice || 0))
              if (copyOfCartItem.isSubscriptionOrder) {
                const { price, quantity, miniPackagePrice } = copyOfCartItem;
                copyOfCartItem.price = (price + (miniPackagePrice || 0))
                const discountedPrice= this.calculateSubscriptionPrice(cartItem)
                copyOfCartItem.finalPrice = discountedPrice + (miniPackagePrice || 0);
              } else if(copyOfCartItem.isBloodMiniPackageBundle) {
                copyOfCartItem.price = copyOfCartItem.finalPrice;
                const { price, quantity, miniPackagePrice } = copyOfCartItem;
                copyOfCartItem.price = copyOfCartItem.finalPrice + (miniPackagePrice || 0);
                copyOfCartItem.finalPrice =(price + (miniPackagePrice || 0))
              }
            }
           
            delete copyOfCartItem.id;
            delete copyOfCartItem.totalPrice;
            delete copyOfCartItem.isFamilyMemberBooking;
            delete copyOfCartItem.familyMemberId;
            delete copyOfCartItem.isSubscriptionOrder;
            // delete copyOfCartItem.subscriptionDetail;
            delete copyOfCartItem.miniPackagePrice;
            return copyOfCartItem;
          }),
        customerCity: city ?? "",
        transactionReference: transactionReference ?? null,
        selfResultUpload,
        deliveryCharges: deliveryCharges ?? 0,
        nurseVisitFee:nurseVisitFee??0,
        additionalDiscount:additionalDiscount?Number(additionalDiscount):0,
        additionalDicountNote:additionalDicountNote,
        isWalletBalanceUsed: walletAmount ? true : false,
        walletAmount: walletAmount ? Number(walletAmount) : null,
        isNotifyClientEmail:isNotifyClientEmail,
      };
      let prevTime = this.state.prevTime || 0;
      
      if (Date.now() - prevTime > 1000) {
        this.setState({ prevTime: Date.now() });
        await createOrder(payload);
        this.setState({ isOrderCreated: true});
        this.props.onSave();
      }
      // await createOrder(payload);
      // this.setState({isOrderCreated:"true"});
      // this.props.onSave();
    } catch (error) {
      console.log("Error on creating order:", error);
      this.showErrorNotification("Error on create order " + error.message);
    } finally {
      this.hideLoading();
    }
  };

  validate = () => {
    const {
      paymentMode,
      paymentGateway,
      transactionReference,
      walletDetails,
      walletAmount,
      finalAmount,
      totalAmount,
      additionalDicountNote,
      additionalDiscount,
    } = this.state.discountDetails ?? {};
    const errors = {};
    if (!paymentMode) errors.paymentMode = "Payment Mode is a required field!";
    if (!paymentGateway) errors.paymentGateway = "Payment Gateway is a required field!";
    if(paymentMode!=="COD_MAMOPAY"){
    if (!transactionReference || transactionReference.trim() === "")
      errors.transactionReference = "Service reference is a required field!";
    }
    if (walletAmount) {
      if (Number.isNaN(Number(walletAmount)))
        errors.walletAmount = "Wallet amount should be a valid number!";
      else if (Number(walletAmount) < 0)
        errors.walletAmount = "Wallet amount should not be a negative number!";
      else if (walletDetails && Number(walletAmount) > walletDetails.balance)
        errors.walletAmount = "Wallet amount should not be greater than wallet balance!";
      else if (Number(walletAmount) > finalAmount)
        errors.walletAmount = "Wallet amount should not be greater than total amount!";
    }
    if(additionalDiscount){
      if(Number.isNaN(Number(additionalDiscount)))
      errors.additionalDiscount="Additional Discount should be a valid number";
      else if (Number(additionalDiscount) < 0)
      errors.additionalDiscount = "Additional Discount should not be a negative number!";
    // else if(Number(additionalDiscount)> finalAmount){
    //      errors.additionalDiscount="Additional Discount should not be greater than final amount ";
    // }
  }
    if(additionalDiscount>0){
      if(additionalDicountNote==""|| additionalDicountNote=="undefined" || additionalDicountNote=="null")
       errors.additionalDicountNote="additional discount note is required field";
    }

    return Object.keys(errors).length === 0 ? null : errors;
  }

  handleSearchClientsByEmail = () => this.fetchClientUsersByEmail();

  fetchClientUsersByEmail = async () => {
    const { emailSearchTerm } = this.state;
    try {
      this.showLoading();
      const clients = await getClientByEmailOnCartOrders(emailSearchTerm);
      console.log("CreateOrderViewModal:: fetchClientUsersByEmail", clients);
      this.setState({ clients: clients ?? [] }, () =>
        this.createClientOptions()
      );
    } catch (error) {
      console.log("Error on fetching client users by email:", error);
    } finally {
      this.hideLoading();
    }
  };

  fetchFamilyMembers = async (clientId) => {
    if (!clientId) {
      console.log(
        "fetchFamilyMembers:: Cannot fetch family members! Invalid client id",
        { clientId }
      );
      return;
    }
    try {
      this.showLoading();
      const { data: familyMembers = [] } = await PhoenixAPI.get("/multiple-profile",{params: { id: clientId }});
      if (familyMembers && familyMembers.length) {
        familyMembers
          .filter(member => member?.relation?.toLowerCase() !== "self")
          .forEach(member => {
            const { firstName, lastName } = member;
            const fullName = `${firstName ?? ""} ${lastName ?? ""}`.trim();
            member.fullName = fullName;
          });
      }
      this.setState({ selectedClientFamilyMembers: familyMembers });
    } catch (error) {
      console.log("Error in fetching family members:", error);
      this.showErrorNotification(
        "Error in fetching family members!",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  handleClientSelected = (selectedClientId) => {
    const selectedClient = this.findClient(selectedClientId);
    this.fetchFamilyMembers(selectedClient?.id);
    this.setState({ selectedClient, selectedClientAddress: null }, () =>
      this.createClientAddressOptions()
    );
  };

  handleClientAddressSelected = (selectedClientAddressId) => {
    const { selectedClient } = this.state;
    const {nationality=null}= selectedClient || {}
    console.log("selectedClientAddress", selectedClient);
    const clientAddresses = selectedClient?.user_address ?? [];
    const selectedClientAddress = clientAddresses.find(
      (it) => it.id === selectedClientAddressId
    );
    console.log("clientAddresses", selectedClientAddress);
    this.setState({ selectedClientAddress }, () => {this.fetchMiniPackageCityConfig();this.fetchSupplementDeliveryFeeConfig()});
  };

  createClientOptions = () => {
    const { clients = [] } = this.state;
    const clientOptions = clients.map(this.createClientOption);
    this.setState({ clientOptions });
  };

  createClientOption = (client) => {
    if (!client) return;
    const { username, first_name, last_name, email } = client;
    const name =
      username || `${first_name || ""} ${last_name || ""}`.trim() || "";
    return {
      key: client.id,
      value: client.id,
      label: `${email} (${name})`,
    };
  };

  createClientAddressOptions = () => {
    const { selectedClient } = this.state;
    const clientAddresses = selectedClient?.user_address ?? [];
    const clientAddressOptions = clientAddresses.map(
      this.createClientAddressOption
    );
    this.setState({ clientAddressOptions });
  };

  createClientAddressOption = (clientAddress) => {
    if (!clientAddress) return;
    const { id, label=null, city="", country=""} = clientAddress;
    const showingCity= (label && label) || (city && city) || (country && country)
    return {
      key: id,
      value: id,
      label:showingCity,
    };
  };

  findClient = (clientId) => {
    const { clients = [] } = this.state;
    return clients.find((client) => client.id === clientId);
  };

  findCity = (cityName) => {
    const { cities = [] } = this.props;
    return cities.find((city) =>
      [city.city_name, city.city_name_ar].includes(cityName)
    );
  };

  findCountry = (countryName) => {
    const { countries = [] } = this.props;
    return countries.find((country) =>
      [country.country_name, country.country_name_ar].includes(countryName)
    );
  };

  handleCartItemsUpdated = (cartItems) => {
    console.log(
      "CreateOrderViewModal:: handleCartItemsUpdated, cartItems",
      cartItems
    );
    const {nationality=""}= this.state.selectedClient ??{};
    const {country}= this.state.selectedClientAddress??{}
    const countryObject = this.findCountry(country);
    const vatPercentage = countryObject
    ? Number(countryObject.vat_percentage)
    : null;
     let modifiedCartItems=cartItems.filter((it) => (it.itemType!== "Medicine") &&( it.itemType !== "Supplement"))
    let nationalityTaxAmount=0;
     if(nationality==="expats" && country==="Kingdom of Saudi Arabia"){
      nationalityTaxAmount=modifiedCartItems.length>0 && modifiedCartItems.map((cartItem)=>{
        if(cartItem.vat==0 && ["Blood_Package","Custom_Package","Mini_Package"].includes(cartItem.itemType))
          return 0;
         else{
          const taxAmount= (cartItem.totalPrice)*(vatPercentage)/100;
          return taxAmount;
         }
    
      }).reduce((total, current) => total + current, 0);
       
      console.log("nationalityTaxAmount", nationalityTaxAmount)
     }
     else if(nationality==="saudi" && country==="Kingdom of Saudi Arabia"){
      nationalityTaxAmount=0
     }

     
    
    const totalAmount = cartItems
      // .map((cartItem) => {
      //   const { quantity, price } = cartItem;
      //   return quantity * price;
      // })
      .map((cartItem) => cartItem.totalPrice ?? 0)
      .reduce((total, current) => total + current, 0);
    const discountDetails = this.getInitDiscountDetails();
    discountDetails.totalAmount = totalAmount;
    discountDetails.nurseVisitFee = this.calculateNurseVisitFeeCharges(cartItems);

    discountDetails.deliveryCharges= this.calculateDeliveryCharges(cartItems)
    discountDetails.finalAmount = (totalAmount + discountDetails.deliveryCharges +discountDetails.nurseVisitFee+ nationalityTaxAmount)?? 0;
    discountDetails.vatAmount=nationalityTaxAmount ??0
    console.log("discountDetailsdiscountDetails", discountDetails)
    this.setState({
      cartItems: cartItems,
      discountDetails,
    });
  };

  fetchSupplementsMedicinesPricingData=(finalItems)=>{
    if(finalItems && finalItems.length>0){
      let itemsPrice = 0;
      finalItems.length > 0 && finalItems.map((item) => {
          const { price:finalPrice = 0, subscriptionDetails = {}} = item || {}
        
          let fPrice = 0
          if(subscriptionDetails){
              const {discountType="",discountAmount=""} =subscriptionDetails || {}
              console.log("subscriptionDetailssubscriptionDetails,", subscriptionDetails)
              let discount = 0
              if (discountType === "Fixed") {
                discount = discountAmount
              }
              else if (discountType === "Percentage") {
                discount = finalPrice * (discountAmount/100);
              }
              fPrice = (finalPrice - discount) * item.quantity;
          }else{
              fPrice = finalPrice * (item.quantity || 1)
          }
          itemsPrice = itemsPrice + fPrice; 
         
      })
      return itemsPrice
    }
    else{
      return 0
    }
 
    
  }
  calculateNurseVisitFeeCharges = (cartItems) => {

    const {miniPackageCityConfig={}, supplementDeliveryFeeConfig={}}= this.state;
    const {deliveryCharge=0, thresholdValue=0}=supplementDeliveryFeeConfig ||{}
    // console.log("calculateDeliveryCharges:: Calculating delivery charges for cart with items: ", {cartItems});
    // if (!cartItems || !cartItems.length) {
    //   console.log("calculateDeliveryCharges:: Cart has no items!", {cartItems});
    //   return 0;
    // }

    // const cartItemMiniPackage = cartItems.find(cartItem => cartItem.itemType === "Mini_Package");
    // if (!cartItemMiniPackage) {
    //   console.log("calculateDeliveryCharges:: There is no mini package bundle in cart!", {cartItems});
    //   return 0;
    // }

    // const { miniPackageCityConfig } = this.state;
    // if (!miniPackageCityConfig) {
    //   console.log("calculateDeliveryCharges:: Mini package city config is not found!", {miniPackageCityConfig});
    // }

    // const miniPackagesBundlePrice = cartItemMiniPackage.totalPrice;
    // if (miniPackagesBundlePrice < miniPackageCityConfig.pickupFreeValue) {
    //   return miniPackageCityConfig.pickupCharge;
    // }
    // return 0;
    let productDeliveryCharges=0;
    cartItems.length>0 && cartItems.map((item)=>{
      if (item && item.itemType && item.itemType === "Mini_Package") {
      
        const { pickupFreeValue = 0, pickupCharge = 0 } = this.state.miniPackageCityConfig || {};
         const miniPackagesBundlePrice = item.totalPrice;
    if (miniPackagesBundlePrice < pickupFreeValue) {
      productDeliveryCharges=productDeliveryCharges+parseInt(pickupCharge);
    }
    }

    })
    return productDeliveryCharges
  
  }

  calculateDeliveryCharges=(cartItems)=>{
    
    const {supplementDeliveryFeeConfig={}}= this.state || {};
    const {deliveryCharge=0, thresholdValue=0}=supplementDeliveryFeeConfig ||{}
    let productDeliveryCharges=0;
    const supplementMedicineItems = cartItems.filter((it) => it.itemType === "Supplement" || it.itemType === "Medicine");
    const supplementsItemsDeliveryPrice=this.fetchSupplementsMedicinesPricingData(supplementMedicineItems)
    if (supplementsItemsDeliveryPrice > 0 && supplementsItemsDeliveryPrice <= thresholdValue) {
     productDeliveryCharges = productDeliveryCharges + parseInt(deliveryCharge)
 }
 else {
   productDeliveryCharges = productDeliveryCharges
 }
   return productDeliveryCharges
  }

  shouldNextButtonEnabled = () => {
    const { currentStepNumber, selectedClientAddress, cartItems } = this.state;
    const { city, country } = selectedClientAddress ?? {};
    if (currentStepNumber === 0) {
      return city && country;
    } else if (currentStepNumber === 1) {
      return cartItems && cartItems.length;
    }
  };

  handleNationality=()=>{
    const { selectedClient,selectedClientAddress } = this.state;
     const {nationality=null}= selectedClient || {}
    if(selectedClientAddress.country==="Kingdom of Saudi Arabia"){
      if(!nationality){
         
         this.setState({nationalityErrorModal: true})
      }
      else{
        this.setState((prevState) => ({
          currentStepNumber: prevState.currentStepNumber + 1,
        }))
      } 
    }
    else{
      this.setState((prevState) => ({
        currentStepNumber: prevState.currentStepNumber + 1,
      }))
    } 
    
  }

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  render() {
    const { coupons, isVisible, onHide } = this.props;
    const {
      orderFlow,
      cartItems,
      emailSearchTerm,
      clientOptions,
      clientAddressOptions,
      selectedClient,
      selectedClientAddress,
      currentStepNumber,
      discountDetails,
      selfResultUpload,
      errors,
    } = this.state;

    const {
      label,
      address_line2: streetName,
      building_apt,
      address_line1: areaDistrict,
      city,
      country,
      phone_no,
    } = selectedClientAddress ?? {};

    const selectStyles = { container: (base) => ({ ...base, flex: 1 }) };

    const noAddressFoundMessage = (
      <p>
        There are no addresses saved for the user account - please go through
        user account management and add an address.
      </p>
    );

    const formAddressInfo = (
      <>
        <div className="row">
          <div className="form-group col-6">
            <label>Select Address</label>
            <Select
              key={`select_client_address_${selectedClient?.id ?? -1}`}
              value={this.createClientAddressOption(selectedClientAddress)}
              onChange={(option) =>
                this.handleClientAddressSelected(option.value)
              }
              options={clientAddressOptions ?? []}
              styles={selectStyles}
              placeholder="Select Address"
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="streetName">Street Name</label>
            <input
              value={streetName ?? ""}
              type="text"
              id="streetName"
              name="streetName"
              readOnly={true}
              className="form-control py-2"
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="building_apt">Building/Apt.</label>
            <input
              value={building_apt ?? ""}
              type="text"
              id="building_apt"
              name="building_apt"
              readOnly={true}
              className="form-control py-2"
            />
          </div>
          <div className="form-group col">
            <label htmlFor="areaDistrict">Area/District</label>
            <input
              value={areaDistrict ?? ""}
              type="text"
              id="areaDistrict"
              name="areaDistrict"
              readOnly={true}
              className="form-control py-2"
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="city">City</label>
            <input
              value={city ?? ""}
              type="text"
              id="city"
              name="city"
              readOnly={true}
              className="form-control py-2"
            />
          </div>
          <div className="form-group col">
            <label htmlFor="country">Country</label>
            <input
              value={country ?? ""}
              type="text"
              id="country"
              name="country"
              readOnly={true}
              className="form-control py-2"
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="phone_no">Phone Number</label>
            <input
              value={phone_no ?? ""}
              type="text"
              id="phone_no"
              name="phone_no"
              readOnly={true}
              className="form-control py-2"
            />
          </div>
        </div>
      </>
    );

    const formCustomerInfo = (
      <div className="container">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="pastFutureOrder">
              Slot Updation on Website/App
            </label>
            <Dropdown
              value={orderFlow ?? null}
              onChange={(event, value) => {
                this.setState({ orderFlow: value.value });
              }}
              id="pastFutureOrder"
              name="pastFutureOrder"
              placeholder="Past/Future Order"
              search
              selection
              options={ORDER_FLOW_OPTIONS}
              className="form-control"
            />
          </div>
          <div className="form-group col">
            <div className="form-check custom-control custom-checkbox ml-2" style={{ top: "35px" }}>
              <input
                value={true}
                checked={selfResultUpload}
                onChange={(event, value) => {
                  this.setState({
                    selfResultUpload: event.target.checked
                  });
                }}
                id="selfResultUpload"
                name="selfResultUpload"
                type="checkbox"
                className="custom-control-input"
              />
              <label className="custom-control-label" htmlFor="selfResultUpload">
                <b>is Self Upload Result Order?</b>
              </label>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label>Email</label>
            <div className="input-group mb-3">
              <input
                value={emailSearchTerm ?? ""}
                onChange={(e) =>
                  this.setState({ emailSearchTerm: e.target.value })
                }
                type="text"
                id="emailSearchTerm"
                name="emailSearchTerm"
                className="form-control"
                placeholder="Search Email"
              />
              <div className="input-group-append">
                <i
                  className="fa fa-search input-group-text"
                  onClick={this.handleSearchClientsByEmail}
                />
              </div>
            </div>
          </div>
          <div className="form-group col">
            <label>Select Email</label>
            <Select
              key={`select_client_email_${selectedClient?.id ?? -1}`}
              value={this.createClientOption(selectedClient)}
              onChange={(option) => this.handleClientSelected(option.value)}
              options={clientOptions ?? []}
              styles={selectStyles}
              placeholder="Select Email"
            />
          </div>
        </div>
        {selectedClient
          ? selectedClient.user_address && selectedClient.user_address.length
            ? formAddressInfo
            : noAddressFoundMessage
          : null}
      </div>
    );

    const stepTitles = [
      "Step 1: Customer Information",
      "Step 2: Add Items to Cart",
      "Step 3: Payment Information",
    ];
    const steps = [
      formCustomerInfo,
      <CartItemsForm
        orderFlow={orderFlow}
        city={this.findCity(selectedClientAddress?.city)}
        country={this.findCountry(selectedClientAddress?.country)}
        cartItems={cartItems}
        onCartItemsUpdated={this.handleCartItemsUpdated}
        client={this.state.selectedClient ?? null}
        familyMembers={this.state.selectedClientFamilyMembers ?? []}
        orderType={this.props.orderType}
      />,
      <PaymentInfoForm
        cartItems={cartItems}
        coupons={coupons}
        // selectedCouponId={selectedCouponId}
        country={this.findCountry(selectedClientAddress?.country)}
        city={this.findCity(selectedClientAddress?.city)}
        discountDetails={discountDetails}
        userId={selectedClient?.id}
        onDiscountDetailsUpdated={(discountDetails) =>
          this.setState({ discountDetails })
        }
        errors={errors ?? {}}
        nationality={selectedClient?.nationality ??""}
      />,
    ];

    const formFooter = (
      <div className="container">
        <div className="row">
          <div className="form-group col-6">
            {currentStepNumber > 0 && (
              <button
                onClick={(event) =>
                  this.setState((prevState) => ({
                    currentStepNumber: prevState.currentStepNumber - 1,
                  }))
                }
                type="button"
                className="btn btn-outline-secondary"
              >
                Back
              </button>
            )}
          </div>
          <div className="form-group col text-right">
            <button
              onClick={onHide}
              type="button"
              className="btn btn-outline-danger"
            >
              Cancel
            </button>
            {currentStepNumber === 2 ? (
              <button
                onClick={this.handleSave}
                type="button"
                className="btn btn-outline-success ml-2"
                disabled={this.state.isOrderCreated?true:false}
              >
                Save
              </button>
            ) : (
              <button
                onClick={(event) =>

                  this.handleNationality()
                  
                }
                type="button"
                className="btn btn-outline-success ml-2"
                disabled={!this.shouldNextButtonEnabled()}
              >
                Next
              </button>
            )}
          </div>
        </div>
      </div>
    );

    return (
      <>
       <Modal
        size="xl"
        show={isVisible}
        onHide={onHide}
        className="user-data-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <p style={{ fontSize: "1rem" }}>{stepTitles[currentStepNumber]}</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{steps[currentStepNumber]}</Modal.Body>
        <Modal.Footer>{formFooter}</Modal.Footer>
      </Modal>
      <Modal
          size="sm"
          show={this.state.nationalityErrorModal}
          onHide={()=>this.setState({nationalityErrorModal:false})}
        >
          <Modal.Body>
            Please Update Nationality for this client from Client section to create new order!
           
          </Modal.Body>
          <button className="nationality-close-btn" onClick={()=>this.setState({nationalityErrorModal:false})}>
             Close
            </button>
        </Modal>
      </>
  
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

CreateOrderViewModal.propTypes = {
  isVisible: PropTypes.bool,
  onHide: PropTypes.func,
  onSave: PropTypes.func,
};

CreateOrderViewModal.defaultProps = {
  isVisible: false,
  onHide: (f) => f,
  onSave: (f) => f,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateOrderViewModal);
