import React, { Component } from "react";
import _ from "lodash";
import AlertBox from "components/AlertBox/AlertBox";
import Range from "components/BiomarkerRange/Range";

class TestResultsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortConfig: { field: "position", direction: "asc" },
    };
  }

  requestSort = (newSortField) => {
    const { field, direction } = this.state.sortConfig || {};
    let newDirection = "asc";
    if (field === newSortField && direction === "asc") {
      newDirection = "desc";
    }

    this.setState({
      sortConfig: { field: newSortField, direction: newDirection },
    });
  };

  getClassNamesFor = (field) => {
    const { sortConfig } = this.state;
    if (!sortConfig) return;
    return sortConfig.field === field
      ? sortConfig.direction === "asc"
        ? "ascending"
        : "descending"
      : undefined;
  };

  getSortedTestResults = () => {
    const { testResults } = this.props;
    const { sortConfig } = this.state;
    const sortedTestResults =
      testResults && testResults.length ? [...testResults] : [];
    if (sortConfig) {
      const { field, direction } = sortConfig;
      if (field === "test_category") {
        return _.orderBy(
          sortedTestResults,
          [
            function (testResult) {
              return testResult.categories && testResult.categories.length
                ? testResult.categories[0]
                : "";
            },
            "test_name",
          ],
          [direction]
        );
      } else {
        return _.orderBy(sortedTestResults, [field], [direction]);
      }
    }
    return sortedTestResults;
  };

  getUnit = (item) => {
    const tests = this.props.tests ?? [];
    const markers = tests[item?.index]?.markers;
    return markers && markers.length ? markers[0].unit : "";
  };

  getTestCategories = (row) => {
    const { tests, testCategortList } = this.props;
    if(testCategortList && tests[row.index] && !tests[row.index].test_category.includes(null)){
      if (testCategortList && tests[row.index].test_category) {
        let temp = "";
        tests[row.index].test_category.map((item2, index) => {
          if (testCategortList.filter((item3) => item3.key === item2).length) {
            temp =
              temp +
              testCategortList.filter((item3) => item3.key === item2)[0].text;
            if (index !== row.test_category.length - 1) {
              temp = temp + ", ";
            }
          }
        });
        return temp;
      }
    }
   
  };

  getFlag(row) {
    const { tests, results } = this.props;
    let result =
      row.result && row.result
        ? row.result
        : results && results.length > 0
        ? results[row.index]
        : "";
    const markers =
    tests[row.index] && tests[row.index].markers && tests[row.index].markers.length > 0
        ? tests[row.index].markers[0]
        : "";
    var statusName = "";
    var statusStyle = {};
    const {is_biomarker_multiple_ranges,is_show_biomarker,problematic_biomarker_answer, problematic_biomarker_question }= markers || {};
    const isProblematicBiomarker =!is_show_biomarker && is_biomarker_multiple_ranges;
    const showProblematicQuestion= is_biomarker_multiple_ranges

    markers.biomarker_list &&
      markers.biomarker_list.map((val) => {
        if (
          result <= val.range_end &&
          (val.range_start === null || val.range_start === 0)
        ) {
          statusName = val.status_name;
          statusStyle = {
            background: val.status_color_code,
          };
        }
        if (result > val.range_start && result <= val.range_end) {
          statusName = val.status_name;
          statusStyle = {
            background: val.status_color_code,
          };
        } else if (
          result > val.range_start &&
          (val.range_end === null || val.range_end === 0)
        ) {
          statusName = val.status_name;
          statusStyle = {
            background: val.status_color_code,
          };
        } else if (
          result > val.range_start &&
          (val.range_end !== null || val.range_end !== 0)
        ) {
          statusName = val.status_name;
          statusStyle = {
            background: val.status_color_code,
          };
        } else if (!statusName && val.range_start && val.status_name) {
          statusName = val.status_name;
          statusStyle = {
            background: val.status_color_code,
          };
        }
      });
    if (!statusName) {
      statusName = "Out of Range";
      statusStyle = {
        background: "#CCCCCC",
      };
    }
    if (result !=null || "") {
      return (
        <>
          <div className="status-div" style={{ filter:isProblematicBiomarker? "blur(6px)":"" }}>
          <div>
            <div className="status" style={statusStyle}>
              <p style={{ borderRadius: "0.25rem" }}>{statusName}</p>
            </div>
          </div>
        </div>
        {showProblematicQuestion?<div style={{marginTop:"10px",fontStyle:"Italic"}}>This is a problematic biomarker</div>:""}
        </>
      
      );
    } else {
      return "Out Of Range";
    }
  }

  //new function for getRange for problematic condition
  getRange = (item) => {
    const { tests } = this.props;
    const markers =
    tests[item.index] &&
      tests[item.index].markers && tests[item.index].markers.length > 0
        ? tests[item.index].markers[0]
        : "";
    const { is_biomarker_multiple_ranges, is_show_biomarker, problematic_biomarker_answer, problematic_biomarker_question } = markers || {};
    const isProblematicBiomarker = !is_show_biomarker && is_biomarker_multiple_ranges;
    const showProblematicQuestion = is_biomarker_multiple_ranges
    return (<>

      {
       tests[item.index] && tests[item.index].markers && tests[item.index].markers.length > 0 && item.result != null || "" ? (
          <>
            <div className="form-row justify-content-center align-items-center" style={{ filter: isProblematicBiomarker ? "blur(6px)" : "" }}>
              <Range
                result={Number(item.result)}
                biomarker_list={
                  tests[item.index].markers && tests[item.index].markers.length > 0 ? tests[item.index].markers[0]
                    .biomarker_list : []
                }
                val={1}
              />


            </div>
            {/* {showProblematicQuestion && this.renderProblematicAnswer(problematic_biomarker_answer)} */}
          </>
        ) : ('Out Of Range')
      }
    </>)
  }
  // getRange = (item) => {
  //   const { tests } = this.props;
  //   return tests[item.index].markers &&
  //     tests[item.index].markers.length > 0 &&
  //     item.result !== "" ? (
  //     <div className="form-row justify-content-center align-items-center" style={{ minWidth: "250px" }}>
  //       <Range
  //         result={Number(item.result)}
  //         biomarker_list={
  //           tests[item.index].markers && tests[item.index].markers.length > 0
  //             ? tests[item.index].markers[0].biomarker_list
  //             : []
  //         }
  //         val={1}
  //       />
  //     </div>
  //   ) : (
  //     "Out Of Range"
  //   );
  // };


  renderResult = (item) => {
    const { viewMode, userType, result_messages, handleChange } = this.props;
    return (
      <input
        readOnly={viewMode || userType !== "lab" ? true : false}
        className={
          "form-control " + (result_messages[item.index] && "has-error")
        }
        type="number"
        placeholder="Result"
        step="any"
        onChange={(e) => handleChange(e, item)}
        min={0}
        value={item.result}
        name="marker-result"
        style={{ minWidth: "80px" }}
      />
    );
  };

  getOCRParsedResult = (testName) => {
    const parsedLabTestResults = this.props.parsedLabTestResults ?? [];
    return parsedLabTestResults.find((it) => it.valeoTestName === testName);
  }

  renderTestResultsTable = (testResults) => {
    if (!testResults || !testResults.length) return null;

    const rows = testResults.map((item) => {
      const ocrResult = this.getOCRParsedResult(item.name);
      const condition = ocrResult 
        ? ocrResult.conversionFactor && (ocrResult.unit !== ocrResult.valeoUnit) 
        : false;
      const convertedResult = ocrResult && ocrResult.conversionFactor && ocrResult.result
        ? ocrResult.conversionFactor * ocrResult.result
        : null;
      return (
        <>
          <tr key={item.id} className="result-table-row overflow">
            <td>{item.position ?? ""}</td>
            <td className="text-wrap">{item.name}</td>
            <td>{this.getTestCategories(item)}</td>
            <td>{this.renderResult(item)}</td>
            <td className="text-center">{this.getUnit(item)}</td>
            <td>{this.getFlag(item)}</td>
            <td>{this.getRange(item)}</td>
          </tr>
          {condition && (
            <tr className="my-auto">
              <td></td>
              <td colspan="6">
                <p>
                  <span className="font-weight-bold">Test:</span><span className="pl-1 pr-3">{ocrResult.testName}</span>
                  <span className="font-weight-bold">Result:</span><span className="pl-1 pr-3">{ocrResult.result}</span>
                  <span className="font-weight-bold">Unit:</span><span className="pl-1 pr-3">{ocrResult.unit}</span>
                </p>
                <p>
                  <span className="font-weight-bold">Conversion Factor:</span><span className="pl-1 pr-3">{ocrResult.conversionFactor}</span>
                  <span className="font-weight-bold">Converted Result:</span><span className="pl-1 pr-3">{convertedResult?.toFixed(2) ?? ""}</span>
                </p>
              </td>
            </tr>
          )}
        </>
      )
    });
    return (
      <table className="result-table">
        <thead className="result-table-head">
          <tr>
            <th
              onClick={() => this.requestSort("position")}
              className={this.getClassNamesFor("position")}
            >
              Position
            </th>

            <th
              onClick={() => this.requestSort("name")}
              className={this.getClassNamesFor("name")}
            >
              Name
            </th>
            <th
              onClick={() => this.requestSort("test_category")}
              className={this.getClassNamesFor("test_category")}
            >
              Category
            </th>
            <th>Result</th>
            <th>Unit</th>
            <th>Flag</th>
            <th>Range</th>
          </tr>
        </thead>
        <tbody className="result-table-body">{rows}</tbody>
      </table>
    );
  };

  render() {
    const sortedTestResults = this.getSortedTestResults();
    return sortedTestResults && sortedTestResults.length ? (
      this.renderTestResultsTable(sortedTestResults)
    ) : (
      <AlertBox message="There's nothing here." />
    );
  }
}

export default TestResultsTable;
