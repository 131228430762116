import React, { Component } from "react";
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import CountryDropdown from "components/Common/CountryDropdown";
import Search from "components/Search/Search";
import AlertBox from "components/AlertBox/AlertBox";
import { Dropdown } from "semantic-ui-react";
import Status from "components/Common/Status";
import { fetchCities, fetchCountries } from "services/RegionService";
import CityDropdown from "components/Common/CityDropdown";
import SelectTests from "./SelectTests";
import API from "utils/API";
import PhoenixAPI from "utils/PhoenixAPI";
import { getUserId } from "services/UserService";
import { Page } from "utils/constant";
import { isAccessDenied, isUserSuperAdmin, isUserAdmin } from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";

import moment from "moment";
import { fetchValeoPartners } from "services/ValeoPartnersService";
import Select from "react-select";

import PackageCityDetails from "components/Common/PackageCityDetails";


import SelectSuggestedSupplement from "../../pages/CoachManagment/SelectSuggestedSupplement";
import SelectPeriod from "../../pages/CoachManagment/SelectPeriod";
import { fetchSupplementsSummaryByCountry } from "services/SupplementService";
import PackageSampleDetails from "../../components/Common/PackageSampleDetails";


import internalCategoryOptions from "utils/InterenalCategory";


const TWO_MEGA_BYTE = 2_097_152;
const BASE_URL_MINI_PACKAGES = "/api/v1/mini-packages/all/country/";
const BASE_URL_POST_MINI_PACKAGES="/api/v1/mini-packages/save-all";
const BASE_URL_PATCH_MINI_PACKAGES="/api/v1/mini-packages/update-all";
const BASE_URL_MINI_PACKAGES_NEW = "api/v1/mini-packages/admin/country";
const sampleDetails_url="admin/sample-details";
const BASE_URL_TESTS = "/tests";
const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];
const STATUS_OPTIONS = [
  {
    key: true,
    value: true,
    text: "Active",
  },
  {
    key: false,
    value: false,
    text: "Inactive",
  },
];




class MiniPackages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      miniPackages: [],
      miniPackage: {},
      productCategories: [],
      miniPackageCities:[],
      tests: [],
      countries: [],
      selectedCountry: null,
      cities: [],
      selectedCity: null,
      errors: {},
      editables: {},
      loadError: null,
      searchTerm: null,
      showModal: false,
      isNewMiniPackage: false,
      rankOptions: [],
      valeoPartners:[],
      valeoPartnerOptions:[],
      discountOptions: [
        {
          text: "Percentage",
          value: "percentage",
          key: "percentage",
        },
        {
          text: "Fixed",
          value: "fixed",
          key: "fixed",
        },
      ],
      miniPackagesSupplements:[],
      miniPackageSupplement:{},
      requiredSupplement:{},
      periods:[],
      suggestion:{},
      isGenerate:false,
      mappingPackageSamples:[]
    };

    this.columns = [
      {
        name:"UID",
        selector:"minipackageUid",
        wrap:true,
        sortable:true,
      },
     
      {
        name: "M. Package Name",
        selector: "name",
        wrap: true,
        sortable: true,
      },
      
      {
        name: "Rank",
        selector: "rank",
        width: "100px",
        sortable: true,
        center: true,
      },
      
      {
        name: "View",
        center: true,
        cell: (miniPackage) => this.renderViewButton(miniPackage.name),
      },
    ];

    this.miniPackageSupplementsColumns = [
      {
       name:"Id",
       selector: "id",
       wrap:true,
       sortable:true,
      },
      {
        name: "Supplement Name",
        selector: "supplementName",
        wrap: true,
        sortable: true,
      },
      {
        name: "Dosage English",
        selector: "dosesEng",
        wrap: true,
        sortable: true,
      },
      {
        name: "Dosage Arabic",
        selector: "dosesAr",
        wrap: true,
        sortable: true,
      },
      {
        name: "Period",
        selector: "periodName",
        wrap: true,
        sortable: true,
      },
      {
        name: "Status",
        selector: "status",
        maxWidth: "150px",
        center: true,
        cell: (supplement) => (
          <Status isActive={supplement.isActive ?? false} />
        ),
        sortable: true,
      },
      {
        name: "View",
        center: true,
        cell: (supplement) => this.renderViewSupplementButton(supplement.supplementId),
      },
    ]
  }
  

  async componentDidMount() {
    await Promise.all([
      this.fetchAclUser(),
      this.fetchCountries(),
      this.fetchCities(),
      this.fetchTests(),
    ]);
    await this.fetchMiniPackages();
    this.fetchValeoPartners();
    this.fetchSupplements();
    this.fetchPeriods();
    this.fetchSampleDetails();
  }

  componentWillUnmount() {
    this.hideLoading();
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if(!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  }

  fetchAclUser = async () => {
    const userId = getUserId();
      if (!userId) {
        console.log("fetchAclUser:: Invalid user id!", {userId});
        return;
      }
  
      try {
        const pageName = Page.MINI_PACKAGE_UPDATE;
        const { data: aclUser } = await PhoenixAPI.get(`/api/v1/acl/users/userId/${userId}`);
        const pagePermission = aclUser?.pagePermissions?.find(it => pageName?.toLowerCase() === it.pageName?.toLowerCase()) ?? null;
        const pageAccessRights = pagePermission?.pageAccessRights?.filter(it => it.isActive ?? false)?.map(it => it.accessRightName?.toLowerCase()) ?? null;
        const accessDenied = isAccessDenied(aclUser, pageAccessRights);
        this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
          const isEditable = this.hasPageAccessRight("edit");
          this.setState({ isEditable });
        });
      } catch (error) {
        console.log("fetchAclUser:: Error on fetching acl user!", error);
      }
  }

  fetchCountries = async () => {
    try {
      this.showLoading();
      const countries = (await fetchCountries()) ?? [];
      const selectedCountry = countries.find((country) => country.is_default);
      this.setState({ countries, selectedCountry });
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching countries!" + error.message
      );
    } finally {
      this.hideLoading();
    }
  };

  fetchSampleDetails=async()=>{
    try {
      this.showLoading();
      const { data: sampleDetails = [] } = await PhoenixAPI.get(`${sampleDetails_url}`);
      if(sampleDetails.length>0){
        const activeOptions = sampleDetails
  .filter(sample => sample.isActive)
  .map(sample => ({
    key: sample.id,
    value: sample.id,
    text: sample.sampleName,
    group: "Active Samples"  // Custom attribute for logical grouping
  }));

const inactiveOptions = sampleDetails
  .filter(sample => !sample.isActive)
  .map(sample => ({
    key: sample.id,
    value: sample.id,
    text: sample.sampleName,
    group: "Inactive Samples"  // Custom attribute for logical grouping
  }));

// Combine active and inactive options
const sampleDetailsOptions = [
  { key: 'active-header', text: 'Active Samples', disabled: true },
  ...activeOptions,
  { key: 'inactive-header', text: 'Inactive Samples', disabled: true },
  ...inactiveOptions
];
      this.setState({ sampleDetails,sampleDetailsOptions});

      }
      else{
        this.setState({sampleDetails: [], sampleDetailOptions: []});
      }
      
    } catch (error) {
      console.log(
        "fetchCorporatePartners:: Error on fetching corporate partners!",
        error
      );
      this.showErrorNotification(
        "Error on fetching corporate partners! " + error.message ?? ""
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
   }

  fetchCities = async () => {
    try {
      this.showLoading();
      const cities = (await fetchCities()) ?? [];
      cities.forEach((city) => {
        city.countryId = city.country?.id;
        delete city.country;
      });
      this.setState({ cities });
    } catch (error) {
      this.showErrorNotification("Error on fetching cities!" + error.message);
    } finally {
      this.hideLoading();
    }
  };

  fetchTests = async () => {
    try {
      this.showLoading();
      const { data: tests = [] } = await API.get(BASE_URL_TESTS);
      this.setState({ tests });
    } catch (error) {
      this.showErrorNotification("Error on fetching tests!" + error.message);
    } finally {
      this.hideLoading();
    }
  };

  fetchMiniPackages = async () => {
    try {
      this.showLoading();
      const {selectedCountry}=this.state;
     
      const url=`${BASE_URL_MINI_PACKAGES_NEW}/${selectedCountry?.id}`
      const { data} = await PhoenixAPI.get(
        url
      );
      const {miniPackages=[], requiredSupplement={}  }= data || {};
      const {miniPackagesSupplements=[]}=requiredSupplement || {};
      this.setState({ miniPackages,miniPackagesSupplements:miniPackagesSupplements??[], requiredSupplement });
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching mini packages: " + error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  
  fetchValeoPartners = async () => {
    try {
      this.showLoading();
      const partners = (await fetchValeoPartners()) ?? [];
        const partnerOptions=partners.map((it)=>{
          return {key:it.id, value:it.id, label:it.name}
        })
        partnerOptions.push({key:"select", value:"select", label:"Select"})
        this.setState({valeoPartners:partners,valeoPartnerOptions:partnerOptions})
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching valeo partners: ",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  fetchSupplements=async()=>{
    const { selectedCountry } = this.state;
    const countryId = selectedCountry?.id ?? null;
    if (!countryId) {
      console.log("fetchSupplements:: Invalid country!", { selectedCountry });
      return;
    }
    try {
      this.showLoading();
      const { data: supplements = [] } = await fetchSupplementsSummaryByCountry(
        countryId
      );
      this.setState({supplements});
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching supplements: ",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  }

  fetchPeriods = async () => {
    this.showLoading();
    try {
      const { data: periods = [] } = await PhoenixAPI.get(`supplement-periods`);

      this.setState({ periods });
    } catch (error) {
      console.log("Error in fetching periods");

      this.showErrorNotification(
        "Error in fetching coach suggestion supplement periods",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };
  findCountry = (id) => {
    const countries = this.state.countries || [];
    return countries.find((country) => country.id === id);
  };

  findCity = (id) => {
    const cities = this.state.cities || [];
    return cities.find((city) => city.id === id);
  };

  findProductCategory = (id) => {
    const productCategories = this.state.productCategories || [];
    return productCategories.find(
      (productCategory) => productCategory.id === id
    );
  };

  getFormattedPrice = (miniPackage) => {
    const { country: countryId, price } = miniPackage;
    const country = this.findCountry(countryId);
    return `${price ?? ""} ${country?.currency ?? ""}`.trim();
  };

  handleChange = ({ currentTarget: input }) => {
    const numberInputs = [
      "price",
      "fastingTimeInHours",
      "reportDeliveryTimeInDaysMinimum",
      "reportDeliveryTimeInDaysMaximum",
      "orderSla",
      "operationsSla",
      "coachSla",
    ];
    const miniPackage = { ...this.state.miniPackage };
    const {selectedCountry}= this.state;
    if (numberInputs.includes(input.name)) {
      const value = input.valueAsNumber;
      miniPackage[input.name] = Number.isNaN(value) ? null : value;
    } else {
      if(input.name==="name"){
        miniPackage.miniPackageInternalName=input.value+" - "+selectedCountry.country_name;
      }
      miniPackage[input.name] = input.value;
    }
    this.setState({ miniPackage });
  };

  handleSuppplementChange=({ currentTarget: input })=>{
    const suggestion = { ...this.state.suggestion };
    suggestion[input.name] = input.value;
    this.setState({ suggestion });
  }

  handleCountrySelection = (selectedCountry) =>
    this.setState({ selectedCountry, selectedCity: null },()=>this.fetchMiniPackages());

  handleCitySelection = (selectedCity) => this.setState({ selectedCity });

  handleSearch = (queryString) => {
    this.setState({
      searchApplied: true,
      searchTerm: queryString ? queryString : "",
    });
  };

  handleSearchClear = () => {
    this.setState({ searchTerm: "", searchApplied: false });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({ searchTerm: "", searchApplied: false });
  };

  handleSelectImage = (e) => {
    if (e.target.files && e.target.files.length) {
      const imageFile = e.target.files[0];
      const miniPackage = { ...this.state.miniPackage, profileIcon: imageFile };
      this.setState({ miniPackage });

      this.readImage(imageFile);
    }
  };



  readImage = (imageFile) => {
    const reader = new FileReader();
    reader.onloadend = function () {
      const miniPackage = {
        ...this.state.miniPackage,
        profileIconSrc: [reader.result],
      };
      this.setState({ miniPackage });
    }.bind(this);
    reader.readAsDataURL(imageFile);
  };

  handleAddNew = () => {
    // const { selectedCountry, selectedCity } = this.state;
    // const miniPackage = {
    //   countryId: selectedCountry?.id,
    //   cityId: selectedCity?.id,
    //   isActive: false,
    // };
    // this.setState({ isNewMiniPackage: true, miniPackage }, () =>
    //   this.showModal()
    // );
    this.setState({ isNewMiniPackage: true }, () =>
      this.showMiniPackageViewModal()
    );
  };

  handleAddNewSupplement=()=>{
    this.setState({ isNewSupplement: true }, () =>
      this.showSupplementViewModal()
    );
  }
  handleView = (name) => {
    const miniPackages = this.state.miniPackages || [];
    const miniPackage = miniPackages.find((it) => it.name === name);
    const {valeoPartners}= this.state;
    
    if (miniPackage) {
      miniPackage.profileIconSrc = miniPackage.profileIcon ?? null;
      const partnerId= miniPackage.partnerId ?? null;
      const selectedPartner= valeoPartners.find((it)=>it.id===partnerId);
      const newMiniPackage={...miniPackage,valeoPartnerId: selectedPartner?.id || null,
        valeoPartnerName:selectedPartner?.name || ""}
        const miniPackageCitiesUpdate= miniPackage.miniPackageCityLevel.map((data)=>{return({...data,cityName:this.state.cities.filter((city)=>data.cityId==city.id)[0].city_name})})

        
        this.setState({mappingPackageSamples:miniPackage.miniPackageSampledetails})
        this.setState({miniPackageCities:miniPackageCitiesUpdate})
      this.setState({ isNewMiniPackage: false, miniPackage:newMiniPackage }, () =>
        this.showMiniPackageViewModal()
      );
    }
  };

  handleViewSupplement=(supplementId)=>{
      const miniPackagesSupplements= this.state.miniPackagesSupplements??[];

      const suggestion = miniPackagesSupplements.find(
        (it) => it.supplementId === supplementId
      );
      if (!suggestion) {
        console.log(`Suggestion id ${supplementId} is not found!`);
        return;
      }
      this.setState({ suggestion, isNewSupplement: false }, () =>
      this.showSupplementViewModal()
    );
  }

  showMiniPackageViewModal = () => {
    const { selectedCountry, isNewMiniPackage } = this.state;
    const rankOptions = this.createRankOptions() || [];

    if (isNewMiniPackage) {
      const rank = Math.max(...rankOptions.map((it) => it.value));
      const miniPackage = {
        countryId: selectedCountry?.id,
        
        rank,
        searchDocId:Date.now(),
        isActive: false,
        partnerId:null,
        miniPackageCityLevel:this.state.miniPackageCities,

      };
      this.setState({ miniPackage, rankOptions }, () => this.showModal());
    } 
    else {
      if(this.state.miniPackage.searchDocId==null)
      {
    const miniPackage={...this.state.miniPackage,
      searchDocId:Date.now()
    }
    this.setState({miniPackage,rankOptions,showModal: true})
  }
     else{
      this.setState({ rankOptions }, () => this.showModal());
    }
  }
  }

  showSupplementViewModal=()=>{
    const { selectedCountry, isNewSupplement } = this.state;
    if(isNewSupplement){
      const suggestion={
        countryId: selectedCountry?.id,
      }
      this.setState({ suggestion, isSupplementViewModalVisible: true })
    }
    else{
      this.setState({ isSupplementViewModalVisible: true })

    }
  }
  handleSave = async () => {
    if (!this.state.selectedCountry) {
      this.showErrorNotification("Please select a country!");
      return;
    }

    const { isNewMiniPackage,selectedCountry } = this.state;

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }

    const { miniPackage } = this.state;
    console.log("minipackage is",miniPackage);
    if (miniPackage.internalCategory == "None") {
      miniPackage.internalCategory = null;
    }
    const payload = { ...miniPackage };
    delete payload.profileIconSrc;
    delete payload["valeoPartnerId"];
    delete payload["valeoPartnerName"];
    if (typeof payload.profileIcon !== "string") {
      delete payload.profileIcon;
    }
    
    try {
      const formData = new FormData();
      formData.append("miniPackage",JSON.stringify(payload));
      if (typeof miniPackage.profileIcon !== "string") {
        formData.append("profileIcon", miniPackage.profileIcon);
      }
      const config = {
        method: isNewMiniPackage ? "POST":"PATCH",
        url:isNewMiniPackage
          ? `${BASE_URL_POST_MINI_PACKAGES}`
          : `${BASE_URL_PATCH_MINI_PACKAGES}`,
        data: formData,
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        },
      };
      const response=await PhoenixAPI.request(config);
      this.hideModal();
      this.showSuccessNotification("Saved Successfully.");
     // await this.SaveSearchDocument(miniPackage,response)
     console.log("minipackage response",response);
     if(response.data.miniPackageCityLevel.length>0)
     {console.log("minipackage",response.data.miniPackageCityLevel.length);
      response.data.miniPackageCityLevel.map((miniPackagedetail)=> this.SaveSearchDocument(miniPackage,miniPackagedetail,response))
     

     }
      this.fetchMiniPackages();
    } catch (error) {
      this.showErrorNotification("Some error occurred: " + error.message);
    } finally {
      this.hideLoading();
    }
  };

  saveSupplement=async(supplement)=>{
    try{
      const payload = { ...supplement };
      const response= await PhoenixAPI.post("mini-package-supplement", payload);
       return response
    }
    catch(error){
      this.showErrorNotification("Some error occurred: " + error.message);
    }
  }

  updateSupplement=async(supplement)=>{
    try{
      const payload = { ...supplement };
      const response= await PhoenixAPI.patch(`mini-package-supplement/${supplement.id}`, payload);
       return response
    }
    catch(error){
      this.showErrorNotification("Some error occurred: " + error.message);
    }
  }

  generateSearchTags=async()=>{
    const{name,miniPackageInternalName,searchTags}=this.state.miniPackage;
    const payload=[name,
      miniPackageInternalName ||null,searchTags ||null].filter(item => item !== null && item !=="")
      .join(',');
    try{
      const prevSearchTags = searchTags ? searchTags?.split(',') : [];
      this.setState({isGenerate:true});
     const response= await PhoenixAPI.post("generate-keywords",payload);
     if(response.data.statusCode === 200){
      const newSearchTags = response.data.message.split(',');
      const combinedSearchTags = Array.from(new Set([...prevSearchTags, ...newSearchTags]));
       const miniPackage={
        ...this.state.miniPackage,
        searchTags:combinedSearchTags.join(',')
       }
       this.setState({miniPackage,isGenerate:false});
       this.showSuccessNotification("Generated Successfully");
     }
    }
    catch(error ){
      this.showErrorNotification("Some error occurred: " + error.message);
    }
    
  }

  handleSaveSupplement=async()=>{
    if (!this.state.selectedCountry) {
      this.showErrorNotification("Please select a country!");
      return;
    }
    const { isNewSupplement,selectedCountry } = this.state;
    // const errors = this.validate();
    // this.setState({ errors: errors || {} });
    // if (errors) {
    //   this.showErrorNotification("There are some invalid form fields!");
    //   return;
    // }
    const { suggestion } = this.state;

    
     try{
      this.showLoading();
      const isValidId = suggestion.id!== null && suggestion.id !== undefined;
      const response = isValidId
      ? await this.updateSupplement(suggestion)
      : await this.saveSupplement(suggestion);
      console.log("response:", response);
      this.fetchMiniPackages()
      this.hideSupplementModal()
     }
     catch(error){
      console.log("Error in occured in saving supplement :",error.message);
      this.showErrorNotification("Some error occurred: " + error.message)
     }
     finally{
      this.hideLoading();
     }
  }

  SaveSearchDocument =async(miniPackage,miniPackagedetail,response)=>{
    let dateTime=new Date();
    const dateform = moment(dateTime).format("DD/MM/YYYY");
    let searchTag="";
    if(miniPackage.searchTags!==null && miniPackage.searchTags !=="" && miniPackage.searchTags !==undefined)
     searchTag=miniPackage.searchTags;
    const payload={
      "docId":miniPackagedetail.searchDocId==null?Date.now():miniPackagedetail.searchDocId,
          
      "itemId": miniPackagedetail?.id,
      "itemCategory": "Mini Package",
      "productNameH1":miniPackage.name,
      "descriptionH2":miniPackage.miniPackageInternalName?miniPackage.miniPackageInternalName:"",
      
      "whatsIncluded":"",
      "relatedSymptoms":"",
      "dateAdded":dateform,
      "imageS3Url":Object.keys(miniPackage?.profileIcon).length>0?miniPackage.profileIcon:response.data.profileIcon,
      "thumbnailS3Url":"",
      "price":miniPackagedetail.price,
      "seoUrl":"",
      "searchTags":searchTag,
    }
    let url="create/search-document/"+miniPackage.countryId;
    console.log("url is",url);
    try{
  // const response= selectedPackage?.es_doc_id?PhoenixAPI.patch(url,payload):PhoenixAPI.post(url,payload);
  const response=PhoenixAPI.post(url,payload);
     
    }
    catch(error)
    {   console.log("Error on update or create  Package CMS search doc",error);
      //this.showErrorNotification("Some error occured in search document"+error); 
    }

  }

  validate = () => {
    const errors = {};
    const {
      name,
      nameArabic,
      tests,
      miniPackageCityLevel,
      fastingTimeInHours,
      profileIcon,
      searchTags,
      isActive: status,
      rank,
      internalCategory,
    } = this.state.miniPackage;

    if (!name || name.trim() === "")
      errors.name = "Name (English) cannot be blank!";
    else if (name.length > 50)
      errors.name = "Name (English) is limited to a 50 characters!";
    if(searchTags && searchTags.length>2000)
      errors.searchTags=" Tag is limited to 2000 characters";
    if (!nameArabic || nameArabic.trim() === "")
      errors.nameArabic = "Name (Arabic) cannot be blank!";
    else if (nameArabic.length > 50)
      errors.nameArabic = "Name (Arabic) is limited to a 50 characters!";

    if (!tests || !tests.length)
      errors.tests = "Biomarker List cannot be blank!";
    if(miniPackageCityLevel.length==0)
      errors.miniPackageCityLevel="Add atleast one City Level details!";
   
    
    if (fastingTimeInHours !== null && fastingTimeInHours !== undefined) {
      if (Number.isNaN(fastingTimeInHours))
        errors.fastingTimeInHours =
          "Fasting Time (in hours) should be a valid number!";
      else if (fastingTimeInHours < 1)
        errors.fastingTimeInHours =
          "Fasting Time (in hours) should be greater than 0!";
    }

    if (!profileIcon) errors.profileIcon = "Profile Icon cannot be blank";
    else if (
      profileIcon &&
      profileIcon.size &&
      profileIcon.size > TWO_MEGA_BYTE
    )
      errors.profileIcon = "Profile Icon should be less than 2 MB!";

   

    if (rank === null || rank === undefined)
      errors.rank = "Rank cannot be blank";

     
    

      
    return Object.keys(errors).length === 0 ? null : errors;
  };

  showModal = () => {
    this.setState({ showModal: true });
  };

  hideModal = () => {
    this.setState({ showModal: false });
    this.resetForm();
  };
  hideSupplementModal=()=>{
    this.setState({ isSupplementViewModalVisible: false, suggestion:{}, errors:{}, editables: {} });
  }


  createRankOptions = () => {
    const { isNewMiniPackage } = this.state;
    const miniPackagesForCurrentCity = this.getMiniPackagesForCurrentCity();
    const ranks = miniPackagesForCurrentCity.filter((it) => it.rank).map((it) => it.rank);
    const minRank = Math.min(1, ...ranks);
    const maxRank = Math.max(miniPackagesForCurrentCity.length, ...ranks);
    const until = isNewMiniPackage ? maxRank + 1 : maxRank;
    const rankOptions = [];
    for (let i = minRank; i <= until; i++)
      rankOptions.push({ key: i, value: i, text: i });

    if (rankOptions.length === 0)
      rankOptions.push({ key: 1, value: 1, text: 1 });
    console.log("Rank Options: ", rankOptions);
    return rankOptions;
  };

  resetForm = () => {
    this.setState({
      errors: {},
      editables: {},
      miniPackage: {},
      miniPackageCities:[],
      isNewMiniPackage: false,
    });
  };

  renderViewButton = (name) => (
    <div>
      <button
        onClick={() => this.handleView(name)}
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
      >
        View
      </button>
    </div>
  );
  renderViewSupplementButton=(name)=>{
    return (
      <div>
        <button
          onClick={() => this.handleViewSupplement(name)}
          className="btn px-5"
          style={{ backgroundColor: "#CEE741" }}
        >
          View
        </button>
      </div>
    );
  }
  renderPencilIcon = (fieldName) => {
    const setEditable = (field) => {
      const editables = { ...this.state.editables };
      editables[field] = true;
      this.setState({ editables });
    };

    if (!this.state.isEditable || this.state.isNewMiniPackage) return "";
    return (
      <div
        onClick={() => setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    );
  };

  renderSupplementPencilIcon = (fieldName) => {
    const setEditable = (field) => {
      const editables = { ...this.state.editables };
      editables[field] = true;
      this.setState({ editables });
    };

    if (!this.state.isEditable || this.state.isNewSupplement) return "";
    return (
      <div
        onClick={() => setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    );
  };


  getFilteredMiniPackages = () => {
    const miniPackagesForCurrentCity = this.getMiniPackagesForCurrentCity();
    return this.getSearchedMiniPackages(miniPackagesForCurrentCity);
  };

  getMiniPackagesForCurrentCity = () => {
    const { miniPackages, selectedCountry } = this.state;
    return miniPackages && selectedCountry 
      ? miniPackages.filter(
          (it) =>
            it.countryId === selectedCountry.id 
        )
      : [];
  };

  getSearchedMiniPackages = (miniPackages) => {
    const { searchApplied, searchTerm } = this.state;

    if (!searchApplied || !searchTerm) return miniPackages;

    const newValue = searchTerm.slice(0).trim().toLowerCase();
    return miniPackages.filter((miniPackage) => {
      const { id, name, price } = miniPackage;
      return (
        (id && id.toString() === newValue) ||
        (name && name.toLowerCase().indexOf(newValue) !== -1) ||
        (price && price.toString() === newValue)
      );
    });
  };

  handleCityMiniPackageItemsUpdated = (cityPackageItems = []) => {
  
    this.setState({ miniPackageCities: cityPackageItems});
    this.setState({miniPackage:{...this.state.miniPackage,"miniPackageCityLevel":cityPackageItems}});
  };
  handleSelectPartner=(partnerId)=>{
  
    const {miniPackage, valeoPartnerOptions, valeoPartners}=this.state || {}
    if(partnerId==="select"){
      const newMiniPackage={...miniPackage, partnerId:null, valeoPartnerId: null,valeoPartnerName:"SELECT"}
      this.setState({miniPackage:newMiniPackage})
    }
    else{
      const selectedId = valeoPartners.find(
        (it) => it.id === partnerId 
      ) ?? {};
    
      const newMiniPackage={...miniPackage, partnerId:selectedId.id ?? null, valeoPartnerId:selectedId.id ?? null,valeoPartnerName:selectedId.name ?? ""}
      this.setState({miniPackage:newMiniPackage})
    }
  }

  handlePackageSampleDetailsUpdate = (sampleDetails) => { 

    this.setState({mappingPackageSamples:sampleDetails})
    this.setState({miniPackage:{...this.state.miniPackage, miniPackageSampledetails:sampleDetails}});
  }

  handleRequiredSupplement=async(event)=>{
   const {requiredSupplement}= this.state;

  const isMiniPackageSupplementRequired= event.target.checked ?? false;
  //  this.setState({
  //   requiredSupplement:{...requiredSupplement,isMiniPackageSupplementRequired:event.target.checked}
  // })

  if (!this.state.selectedCountry) {
    this.showErrorNotification("Please select a country!");
    return;
  }
  const {id=null}=requiredSupplement || {};
   const updatedpayload={
     countryId: this.state.selectedCountry.id,
     id:id,
     "isMiniPackageSupplementRequired":isMiniPackageSupplementRequired
   }
   const savePayload={
    countryId: this.state.selectedCountry.id,
     "isMiniPackageSupplementRequired":isMiniPackageSupplementRequired
   }
  try{
    this.showLoading();
     const isValidId= requiredSupplement.id!==null && requiredSupplement.id!==undefined;
     const response=isValidId? await PhoenixAPI.patch(`mini-package-required-supplement/${requiredSupplement.id}`,updatedpayload):await PhoenixAPI.post("mini-package-required-supplement",savePayload);
     console.log("response: " , response)
     this.fetchMiniPackages()
  }
  catch(error){
    console.log("Error in saving mini-package-required-supplement", error.message)
    this.showErrorNotification("Error in saving mini-package-required-supplement",error.message)
  }
  finally{
    this.hideLoading();
  }
  }
  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  createPartnerOption=(miniPackage)=>{
    return {
     key:miniPackage.valeoPartnerId,
     value:miniPackage.valeoPartnerId ,
     label:miniPackage.valeoPartnerName ,
    }
   }
  render() {
    const {
      isNewMiniPackage,
      miniPackage,
      tests,
      countries,
      selectedCountry,
      cities,
      selectedCity,
      editables,
      errors,
      searchTerm,
      isEditable,
      valeoPartnerOptions,
      miniPackagesSupplements,
      isSupplementViewModalVisible, isNewSupplement,
      suggestion,
      periods,supplements,
      requiredSupplement,
      sampleDetails,sampleDetailsOptions
    } = this.state;

    const miniPackages = this.getFilteredMiniPackages();

    const  selectStyles = {
      container: (base) => ({
        ...base,
        flex: 1,
      }),
    };
    


    const detailsForm = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="name">M. Package Name (English)*</label>
            <div className="input-group">
              <input
                value={miniPackage.name}
                onChange={this.handleChange}
                id="name"
                name="name"
                type="text"
                placeholder="Name in English"
                className={
                  "form-control py-2" + (errors.name ? " has-error" : "")
                }
                disabled={!isNewMiniPackage && !editables.name}
              />
              {this.renderPencilIcon("name")}
            </div>
            <span className="help-block">{errors.name}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="nameArabic">M. Package Name (Arabic)*</label>
            <div className="input-group">
              <input
                value={miniPackage.nameArabic}
                onChange={this.handleChange}
                id="nameArabic"
                name="nameArabic"
                type="text"
                placeholder="Name in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.nameArabic ? " has-error" : "")
                }
                disabled={!isNewMiniPackage && !editables.nameArabic}
              />
              {this.renderPencilIcon("nameArabic")}
            </div>
            <span className="help-block">{errors.nameArabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="tests">Biomarker List*</label>
            <div className="input-group">
              <SelectTests
                id="tests"
                tests={tests ?? []}
                selectedTestIds={miniPackage.tests ?? []}
                onSelect={(values) => {
                  this.setState({
                    miniPackage: {
                      ...miniPackage,
                      tests: values.map((it) => it.value),
                    },
                  });
                }}
                isDisabled={!isNewMiniPackage && !editables.tests}
                className={"form-control" + (errors.tests ? " has-error" : "")}
              />
              {this.renderPencilIcon("tests")}
            </div>
            <span className="help-block">{errors.tests}</span>
          </div>
         
       
       
        <div className="form-group col">
            <label htmlFor="rank">Rank</label>
            <div className="input-group">
              <Dropdown
                value={miniPackage?.rank}
                onChange={(event, value) => {
                  this.setState({
                    miniPackage: { ...miniPackage, rank: value.value },
                  });
                }}
                id="rank"
                placeholder="Rank"
                search
                selection
                options={this.state.rankOptions}
                disabled={!isNewMiniPackage && !editables.rank}
                className={"form-control" + (errors.rank ? " has-error" : "")}
              />
              {this.renderPencilIcon("rank")}
            </div>
            <span className="help-block">{errors.rank}</span>
          </div>
          
         
          
        </div>
        
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="fastingTimeInHours">Fasting Time (in hours)</label>
            <div className="input-group">
              <input
                type="number"
                value={miniPackage.fastingTimeInHours ?? ""}
                onChange={this.handleChange}
                id="fastingTimeInHours"
                name="fastingTimeInHours"
                placeholder="Fasting Time (in hours)"
                readOnly={!isNewMiniPackage && !editables.fastingTimeInHours}
                className={
                  "form-control py-2" +
                  (errors.pickupCharges ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("fastingTimeInHours")}
            </div>
            <span className="help-block">{errors.fastingTimeInHours}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="partnerId">Select Partner Id</label>
            <Select
                key={`my_unique_select_key__${miniPackage && miniPackage.partnerId}`}
                value={miniPackage && this.createPartnerOption(miniPackage)}
                onChange={(event) => {
                  this.handleSelectPartner(event.value);
                }}
                options={valeoPartnerOptions || []}
                style={selectStyles}
                placeholder="Select Parnter"
                searchable
                lazyLoad
              />
              {/* {this.renderPencilIcon("partner_id")} */}
            
            <span className="help-block">{errors.partner_id}</span>
          </div>
          
        </div>
        <div className="row">
      <div className="form-group col-6">
        <label htmlFor="name">Tags for Search</label>
        <div className="input-group">
        <textarea
                value={ miniPackage.searchTags !== "null" ?miniPackage && miniPackage.searchTags  : ""}
                onChange={this.handleChange}
                id="tags"
                name="searchTags"
                placeholder="tags"
                className={
                  "form-control py-2" + (errors.searchTags ? " has-error" : "")
                }

                readOnly={
                  !isNewMiniPackage && !editables.searchTags
                }
              ></textarea>
          
          {this.renderPencilIcon("searchTags")}
        </div>
        <span className="help-block">{errors.searchTags}</span>
    </div>
    <div className="form-group col">
                        <label></label><br />
                        <button
                            type="button"
                            onClick={this.generateSearchTags}
                            className={
                                "form-control btn btn-info mt-2 py-1" +
                                (this.state.searchTags ? "non-editable " : "editable ")
                            }
                            disabled={this.state.isGenerate}
                        >
                            Generate through AI
                        </button>
                        </div>

    </div>
        
          <div className="row">
          <div className="form-group col-6">
            <label htmlFor="internalCategory">Internal Category</label>
            <div className="input-group">
              <Dropdown
                value={miniPackage?.internalCategory||"None"}
                onChange={(event, value) => {
                  this.setState({
                    miniPackage: { ...miniPackage, internalCategory: value.value },
                  });
                }}
                id="internalCategory"
                placeholder="internalCategory"
                search
                selection
                options={internalCategoryOptions}
                disabled={!isNewMiniPackage && !editables.internalCategory}
                className={"form-control" + (errors.internalCategory ? " has-error" : "")}
              />
              {this.renderPencilIcon("internalCategory")}
            </div>
            <span className="help-block">{errors.internalCategory}</span>
      </div>
      </div>
        <div className="row mt-3 mb-3">
          <div className="col separator">
            <span>Media</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="profileIcon">Profile Icon*</label>
            <div className="input-group">
              <input
                onChange={this.handleSelectImage}
                id="profileIcon"
                name="profileIcon"
                type="file"
                accept="image/*"
                disabled={!isNewMiniPackage && !editables.profileIcon}
                className={
                  "form-control" + (errors.profileIcon ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("profileIcon")}
            </div>
            <span className="help-block">{errors.profileIcon}</span>
          </div>
          <div className="form-group col">
            {miniPackage?.profileIconSrc && (
              <>
                <label htmlFor="selected_profile_icon">
                  Selected Profile Icon
                </label>
                <div>
                  <img
                    id="selected_profile_icon"
                    name="selected_profile_icon"
                    src={miniPackage.profileIconSrc}
                    alt="Selected"
                    className="img-thumbnail mx-auto"
                    style={{
                      width: "100%",
                      maxHeight: "200px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>

        <PackageSampleDetails
              packageSamples={this.state.mappingPackageSamples?? []}
              countryId={selectedCountry?.id ?? null}
              allSamples={sampleDetails}
              packageSamplesUpdate={this.handlePackageSampleDetailsUpdate} 
              isEditable={isEditable}
              sampleOptions={sampleDetailsOptions}
              type="MiniPackage"
            />
        
       
       
      </div>
    );
      
    
   const  miniPackageCitiesDetails=(
    <>
    <div className="row mt-2">
        <div className="col separator">
          <span>City Level Details</span>
        </div>
      </div>
      <PackageCityDetails
        cityPackageDetails={this.state.miniPackageCities || []}
        onCityPackageDetailsUpdated={this.handleCityMiniPackageItemsUpdated}
        packageType={"miniPackage"}
        packageId={this.state.miniPackage.name || null}
        selectedCountry={selectedCountry}
        isEditable={isEditable}
      />
      <span className="help-block">{errors.miniPackageCityLevel}</span>
    </>
  );
  const LocationForm=(<>
   <div className="container mt-4">
       <div className="row">
       <div className="form-group col-6">
            <div className="input-group">
              <input
                value={true}
                checked={miniPackage.isVatExcluded || false}
                onChange={(event, value) => {
                  this.setState({miniPackage: { ...miniPackage,
                    isVatExcluded: event.target.checked
                  },});
                }}
                id="isVATRemove"
                name="isVATRemove"
                type="checkbox"
                className="larger-checkbox"
              />
               &nbsp;&nbsp; No VAT for this product
               </div> 
            </div>
       </div>

        <div className="row">
          <div className="form-group col">
            <label htmlFor="country">Country</label>
            <input
              value={isNewMiniPackage
                ? selectedCountry && selectedCountry.country_name
                : miniPackage && this.findCountry(miniPackage.countryId)?.country_name
              }
              type="text"
              id="country"
              name="country"
              readOnly
              className="form-control"
            />
          </div>
          <div className="form-group col">
            <label htmlFor="currency">Currency</label>
            <input
              value={isNewMiniPackage
                ? selectedCountry && selectedCountry.currency
                : miniPackage && this.findCountry(miniPackage.countryId)?.currency
              }
              type="text"
              id="currency"
              name="currency"
              readOnly
              className="form-control"
            />
          </div>
        </div>
        
        {miniPackageCitiesDetails}
       </div>
  </>)


const supplementForm=(<>
    <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="cityId">Select Supplement *</label>
            <div className="input-group">
              <SelectSuggestedSupplement
                id="supplementId"
                supplements={supplements ?? []}
                selectedSuggestedSupplement={suggestion?.supplementId ?? null}
                onSelect={(value) =>
                  this.setState({
                    suggestion: {
                      ...suggestion,

                      supplementId: value.value,
                    },
                  })
                }
                isDisabled={!isNewSupplement && !editables.supplementId}
                className={
                  "form-control" + (errors.supplementId ? " has-error" : "")
                }
              />
              {this.renderSupplementPencilIcon("supplementId")}
            </div>
            <span className="help-block">{errors.supplementId}</span>
          </div>

          <div className="form-group col">
            <label htmlFor="status">Status</label>
            <div className="input-group">
              <Dropdown
                value={suggestion?.isActive}
                onChange={(event, value) => {
                  this.setState({
                    suggestion: { ...suggestion, isActive: value.value },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewSupplement && !editables.isActive}
                className={
                  "form-control" + (errors.isActive ? " has-error" : "")
                }
              />
              {this.renderSupplementPencilIcon("isActive")}
            </div>
            <span className="help-block">{errors.isActive}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="dosesEng">Doses (English)</label>
            <div className="input-group">
              <input
                value={(suggestion && suggestion.dosesEng) || ""}
                onChange={this.handleSuppplementChange}
                id="dosesEng"
                name="dosesEng"
                type="text"
                placeholder="Enter Doses in English"
                className={
                  "form-control input py-2" +
                  (errors.dosesEng ? " has-error" : "")
                }
                disabled={!isNewSupplement && !editables.dosesEng}
              />
              {this.renderSupplementPencilIcon("dosesEng")}
            </div>
            <span className="help-block">{errors.dosesEng}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="dosesAr">Doses (Arabic)</label>
            <div className="input-group">
              <input
                value={(suggestion && suggestion.dosesAr) || ""}
                onChange={this.handleSuppplementChange}
                id="dosesAr"
                name="dosesAr"
                type="text"
                placeholder="Enter Doses in Arabic"
                className={
                  "form-control input py-2 input-arabic" +
                  (errors.dosesAr ? " has-error" : "")
                }
                disabled={!isNewSupplement && !editables.dosesAr}
              />
              {this.renderSupplementPencilIcon("dosesAr")}
            </div>
            <span className="help-block">{errors.dosesAr}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="cityId">Select Period *</label>
            <div className="input-group">
              <SelectPeriod
                id="periodId"
                periods={periods}
                selectedPeriod={suggestion?.periodId ?? null}
                onSelect={(value) =>
                  this.setState({
                    suggestion: {
                      ...suggestion,
                      periodId: value.value,
                      periodName: value.label,
                    },
                  })
                }
                isDisabled={!isNewSupplement && !editables.periodId}
                className={
                  "form-control" + (errors.periodId ? " has-error" : "")
                }
              />
              {this.renderSupplementPencilIcon("periodId")}
            </div>
            <span className="help-block">{errors.periodId}</span>
          </div>
        </div>
      </div>
</>)

    if (this.state.accessDenied) {
      return <AccessDenied />
    }

    return (
      <div className="manage-package">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-3">
              <CountryDropdown
                countries={countries ?? []}
                selectedCountry={selectedCountry}
                onSelect={this.handleCountrySelection}
              />
            </div>
            
          </div>
        </div>
        <div className="page-header">
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={searchTerm}
                handleSearch={this.handleSearch}
                clearSearch={this.handleSearchClear}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              ></Search>
            </div>
            <div className="action-item">
              <button
                onClick={this.handleAddNew}
                style={{ backgroundColor: "#8FD14F" }}
                className="btn btn-md new-user"
                disabled={!isEditable || !selectedCountry }
              >
                <i className="fa fa-plus-circle"></i>Add New
              </button>
            </div>
          </div>
        </div>

        <Card body>
          <DataTable
            data={miniPackages}
            columns={this.columns}
            defaultSortField="id"
            defaultSortAsc={false}
            sortIcon={<ArrowDownward></ArrowDownward>}
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            highlightOnHover
            pagination
            responsive
            noHeader={this.state.searchApplied ? false : true}
            title={
              this.state.searchApplied ? (
                <p className="table-header">
                  {"Found " + miniPackages.length + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              this.state.loadError ? (
                <AlertBox
                  message={this.state.loadError}
                  error={true}
                ></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
    
        <div className="input-group" style={{marginLeft:'10px'}}>
            <input
              value={true}
              checked={requiredSupplement.isMiniPackageSupplementRequired && requiredSupplement.isMiniPackageSupplementRequired || false}
              onChange={(event, value) => {
                 this.handleRequiredSupplement(event);
              }}
              id="isRequired"
              name="isRequired"
              type="checkbox"
              className="larger-checkbox"
            />
            <label style={{marginLeft:"10px",marginTop:"10px"}}>is Default Suggested Supplements Required</label>
          </div>
        <div className="page-header">
          <div className="actions">
            <div className="action-item">
             
            </div>
            <div className="action-item">
              <button
                onClick={this.handleAddNewSupplement}
                style={{ backgroundColor: "#8FD14F" }}
                className="btn btn-md new-user"
                disabled={!isEditable || !selectedCountry }
              >
                <i className="fa fa-plus-circle"></i>Add New
              </button>
            </div>
          </div>
        </div>
    
      
        <Card body className="mt-2">
        <p className="font-weight-bold">Coach Suggested Supplements</p>
        <DataTable
            data={miniPackagesSupplements}
            columns={this.miniPackageSupplementsColumns}
            defaultSortField="id"
            defaultSortAsc={false}
            sortIcon={<ArrowDownward></ArrowDownward>}
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            highlightOnHover
            pagination
            responsive
            noHeader={this.state.searchApplied ? false : true}
            title={
              this.state.searchApplied ? (
                <p className="table-header">
                  {"Found " + miniPackagesSupplements.length + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              this.state.loadError ? (
                <AlertBox
                  message={this.state.loadError}
                  error={true}
                ></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
        <Modal
          size="lg"
          show={this.state.showModal}
          onHide={this.hideModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <p style={{ fontWeight: "normal", fontSize: "1rem" }}>
                <span style={{ fontWeight: "bold" }}>Mini Package: </span>
                {miniPackage?.name ?? ""}
              </p>
            </Modal.Title>
            <div className="user-title"></div>
            <button onClick={this.handleSave} disabled={!isEditable} className="btn modal-save-btn">
              Save
            </button>
          </Modal.Header>
          <Modal.Body>
            <Tabs id="tabs_mini_packages" justify>
              <Tab eventKey="key_details_form" title="Details">
                {detailsForm}
              </Tab>
              <Tab eventKey="key_location_Pricing" title="Location & Pricing">
                {LocationForm}
              </Tab>
            </Tabs>
          </Modal.Body>
        </Modal>
        <Modal
          size="lg"
          show={isSupplementViewModalVisible}
          onHide={this.hideSupplementModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {/* <p style={{ fontWeight: "normal", fontSize: "1rem" }}>
                <span style={{ fontWeight: "bold" }}>Mini Package: </span>
                {miniPackage?.name ?? ""}
              </p> */}
            </Modal.Title>
            <div className="user-title"></div>
            <button onClick={this.handleSaveSupplement} disabled={!isEditable} className="btn modal-save-btn">
              Save
            </button>
          </Modal.Header>
          <Modal.Body>
          {supplementForm}
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MiniPackages);
