import React, { Component } from "react";
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Card from "react-bootstrap/Card";
import DataTable from "react-data-table-component";
import { Modal } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import _ from "lodash";
import Status from "components/Common/Status";
import { rangeOfNumbers } from "utils/commons";
import PhoenixAPI from "utils/PhoenixAPI";
import API from "utils/API";
import Select from "react-select";
import Logs from "components/Logs/Logs";
import { Tab, Tabs } from "react-bootstrap";


const STATUS_OPTIONS = [
  {
    key: "Active",
    value: "Active",
    text: "Active",
  },
  {
    key: "Inactive",
    value: "Inactive",
    text: "Inactive",
  },
];

const STATUS_MINI_OPTIONS = [
  {
    key: "Active",
    value: true,
    text: "Active",
  },
  {
    key: "Inactive",
    value: false,
    text: "Inactive",
  },
];

const DISCOUNT_TYPE_OPTIONS = [
  {
    key: "Fixed",
    value: "Fixed",
    text: "Fixed",
  },
  {
    key: "Percentage",
    value: "Percentage",
    text: "Percentage",
  },
];

const BASE_URL_CITIES = "cities/";

class PackageCityDetails extends Component {
  constructor(props) {
    super(props);

    const {
      cityPackageDetails = [],
      onCityPackageDetailsUpdated = (f) => f,
      packageId,
      packageType,
      selectedCountry,
    } = this.props;

    this.onCityPackageDetailsUpdated = onCityPackageDetailsUpdated;

    this.state = {
      cityPackageDetails,
      cityPackage: {},
      errors: {},
      cities: [],
      selectedCity: null,
      editables: {},
      sortColumn: "rank",
      sortDirection: "asc",
      isConfirmDeleteModalVisible: false,
      pageIdForLogs: null,
    };

    
    this.columns = [
       
      {
        name: "City ID",
        selector: "cityId",
        sortable: true,
        center:true,
        wrap:true,
      },
      {
        name: "City Name",
        selector: "cityName",
        sortable: true,
        wrap: true,
      },
      {
        name: "Retail Price",
        selector: "retailPrice",
        sortable: true,
        wrap: true,
      },
      {
        name: "Discount Value",
        selector: "discountValue",
        sortable: true,
        wrap: true,
      },
      {
        name: "Final Price",
        selector: packageType=="miniPackage"?"price":"finalPrice",
        width: "50px",
        maxWidth: "100px",
        center: true,
        sortable: "true",
      },
      {
        name: "Status",
        selector: "status",
        sortable: true,
        center: true,
        maxWidth: "150px",
        cell: (cityPackage) => ( packageType=="miniPackage"?  <Status isActive={cityPackage.active} />:
          <Status isActive={cityPackage.status === "Active"} />
        ),
      },
      {
        name: "Edit",
        // maxWidth: "100px",
        center: true,
        allowOverflow: true,
        cell: (cityPackage) => this.renderEditButton(cityPackage.id),
      },
    ];
    this. miniId=[{
      name: "Id",
      selector: "id",
      sortable: true,
      wrap:true,
      width:"70px",
      } ,...this.columns];

  }
  componentDidMount() {
    this.getCities();
  }

  createCityOptions = () => {
    let { cities = [] } = this.state;
    const isActiveCity = (city) => (city ? city.is_active : false);
    const isInactiveCity = (city) => (city ? !city.is_active : false);
    let activeCities = cities && cities.filter(isActiveCity);

    let inactiveCities = cities && cities.filter(isInactiveCity);

    const cityOptions = [
      {
        label: "Active",
        options: activeCities.map(this.createCityOption),
      },
      {
        label: "Inactive",
        options: inactiveCities.map(this.createCityOption),
      },
    ];
    this.setState({ cityOptions });
  };

  createCityOption = (city) => {
    return {
      key: city.id,
      value: city.id,
      label: city.city_name,
    };
  };

  findCity = (cityId) => {
    const { cities } = this.state;
    return cities.find((it) => it.id === cityId);
  };

  getCities = async () => {
    try {
      const { data: cities = [] } = await API.get(BASE_URL_CITIES);
      const selectedCity =
        this.state.selectedCity &&
        cities.find((it) => it.id === this.selectedCity.id);
      const selectedCountry = this.props.selectedCountry;
      const selectedCountryCities = cities.filter(
        (it) => it.country.id === selectedCountry.id
      );
      this.setState({ cities: selectedCountryCities, selectedCity }, () =>
        this.createCityOptions()
      );
    } catch (error) {
      console.log("Error on fetching cities:", error.message);
    }
  };

  clearForm = () => {
    this.setState({
      errors: {},
      editables: {},
      cityPackage: {},
      selectedCity: null,
    });
  };

  handleChange = ({ currentTarget: input }) => {
    const numberInputs = ["discountValue", "finalPrice","price", "retailPrice","orderSla","operationsSla","coachSla"];
    const cityPackage = { ...this.state.cityPackage };
    if (numberInputs.includes(input.name)) {
      cityPackage[input.name] = input.value ? Number(input.value) : null;
    } else {
      cityPackage[input.name] = input.value;
    }
    this.setState({ cityPackage });
  };

  handleEdit = (id) => {

    console.log("this is the package type on which",this.props.packageType)

    this.setState({
      pageIdForLogs: id
    })

    this.clearForm();
    const { cityPackageDetails = [], cities } = this.state;
    const cityPackage = cityPackageDetails.find((it) => it.id === id);
    if (!cityPackage) {
      return;
    } else {
      const selectedCity = cities.find((it) => it.id === cityPackage.cityId);
      if (selectedCity) {
        cityPackage.cityId = selectedCity.id;
        cityPackage.cityName = selectedCity.city_name;
      }

      this.setState({ cityPackage, selectedCity }, () =>
        this.showAddEditModal()
      );
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const{packageType}=this.props;
   
    
    const errors = packageType=="miniPackage"?this.validateMiniPackage():this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }
      
    const { cityPackage = {}, cityPackageDetails, selectedCity } = this.state;

    if (selectedCity && (cityPackage.id === null || cityPackage.id === undefined)) {
      let isCityExist = cityPackageDetails.map(
        (it) => it.cityId === selectedCity.id
      );
      if (isCityExist.includes(true)) {
        this.showErrorNotification(
          "City already exists: please select other city"
        );
        return;
      }
    }
    
    if (cityPackage.id === null || cityPackage.id === undefined)
      this.createCityPackage();
    else this.updateCityPackage(cityPackage);
    this.hideAddEditModal();
  };

  validateMiniPackage=()=>{
    const errors={};
    const{active, discountValue, price, retailPrice ,
      reportDeliveryTimeInDaysMaximum, reportDeliveryTimeInDaysMinimum, operationsSla }=this.state.cityPackage||{};
      if ( discountValue && Number.isNaN(Number(discountValue)))
      errors.discountValue = "Discount Value should be a valid number";
      else if ( discountValue && !this.isInBetween(Number(discountValue), 0, 99999999))
      errors.discountValue = "Discount Value should be bewteen 0 to 99999999";
      
      if (
        price === null ||
        price === undefined ||
        price.toString().trim() === ""
      )
        errors.price = "Final Price cannot be blank";
      else if (Number.isNaN(Number(price)))
        errors.price = "Final Price should be a valid number";
      else if (!this.isInBetween(Number(price), 0, 99999999))
        errors.price = "Final Price should be bewteen 0 to 99999999";
        if (retailPrice && Number.isNaN(Number(retailPrice)))
        errors.retailPrice = "Retail Price should be a valid number";
      else if (retailPrice && !this.isInBetween(Number(retailPrice), 0, 99999999))
        errors.retailPrice = "Retail Price should be bewteen 0 to 99999999";
  
    
       
      if(reportDeliveryTimeInDaysMinimum===null || reportDeliveryTimeInDaysMinimum=== undefined||
        reportDeliveryTimeInDaysMinimum.toString().trim()==="")
        errors.reportDeliveryTimeInDaysMinimum = "Please Enter Numeric Value";
        
        else if (Number.isNaN(Number(reportDeliveryTimeInDaysMinimum)))
        errors.reportDeliveryTimeInDaysMinimum = "Report Delivery Minimum should be a valid number"; 
        
        else if  (!this.isInBetween(Number(reportDeliveryTimeInDaysMinimum), 0,100))
        errors.reportDeliveryTimeInDaysMinimum  = "Report Delivery Minimum should be bewteen 0 to 100";

     if(reportDeliveryTimeInDaysMaximum===null || reportDeliveryTimeInDaysMaximum=== undefined||
          reportDeliveryTimeInDaysMaximum.toString().trim()==="")
          errors.reportDeliveryTimeInDaysMaximum = "Please Enter Numeric Value";
          
          else if (Number.isNaN(Number(reportDeliveryTimeInDaysMaximum)))
          errors.reportDeliveryTimeInDaysMinimum = "Report Delivery Maximum should be a valid number"; 
          
          else if  (!this.isInBetween(Number(reportDeliveryTimeInDaysMaximum), 0,100))
          errors.reportDeliveryTimeInDaysMaximum= "Report Delivery Maximum should be bewteen 0 to 100";

        else if(Number(reportDeliveryTimeInDaysMaximum)<Number(reportDeliveryTimeInDaysMinimum))
           errors.reportDeliveryTimeInDaysMinimum="Report Delivery Time Minimum lessthan or equalto Maximum";
    if (operationsSla === null || operationsSla === undefined || operationsSla===""){
      errors.operationsSla ="Operations SLA field cannot be blank"
    }
          return Object.keys(errors).length === 0 ? null : errors;

  }

  validate = () => {
    const errors = {};
  
    const { status, discountType, discountValue, finalPrice, retailPrice, operationsSla } =
      this.state.cityPackage || {};
  
   if (!discountType) errors.discountType = "Discount Type cannot be blank";
  
    if (
      discountValue === null ||
      discountValue === undefined ||
      discountValue.toString().trim() === ""
    )
      errors.discountValue = "Discount Value cannot be blank";
    else if (Number.isNaN(Number(discountValue)))
      errors.discountValue = "Discount Value should be a valid number";
    else if (!this.isInBetween(Number(discountValue), 0, 99999999))
      errors.discountValue = "Discount Value should be bewteen 0 to 99999999";

    if (
      finalPrice === null ||
      finalPrice === undefined ||
      finalPrice.toString().trim() === ""
    )
      errors.finalPrice = "Final Price cannot be blank";
    else if (Number.isNaN(Number(finalPrice)))
      errors.finalPrice = "Final Price should be a valid number";
    else if (!this.isInBetween(Number(finalPrice), 0, 99999999))
      errors.finalPrice = "Final Price should be bewteen 0 to 99999999";

    if (
      retailPrice === null ||
      retailPrice === undefined ||
      retailPrice.toString().trim() === ""
    )
      errors.retailPrice = "Retail Price cannot be blank";
    else if (Number.isNaN(Number(retailPrice)))
      errors.retailPrice = "Retail Price should be a valid number";
    else if (!this.isInBetween(Number(retailPrice), 0, 99999999))
      errors.retailPrice = "Retail Price should be bewteen 0 to 99999999";

    if (!status) errors.status = "Status cannot be blank";
    if (operationsSla === null || operationsSla === undefined || operationsSla === "") {
      errors.operationsSla = "Operations SLA field cannot be blank"
    }

    return Object.keys(errors).length === 0 ? null : errors;
  };

  isInBetween = (number, start, end) => number >= start && number <= end;

  createCityPackage = async () => {
    const { cityPackageDetails = [], cityPackage, selectedCity } = this.state;
    const { packageId, packageType } = this.props;
   
    if(packageType=="miniPackage")
    {
      if (selectedCity) {
        cityPackage.cityId = selectedCity.id;
        cityPackage.cityName = selectedCity.city_name;
        cityPackage.searchDocId=Date.now();
      }
      const ids =
      cityPackageDetails.length > 0 ? cityPackageDetails.map((it) => it.id) : null;
    cityPackage.id = ids ? Math.max(...ids) + 1 : 1;
    cityPackage.is_new = true;
      this.setState(
        { cityPackageDetails: [...cityPackageDetails, cityPackage] },
        () => { 
          this.onCityPackageUpdated();
        }
      )

    }
   else{
    if (!packageId) {
      if (selectedCity) {
        cityPackage.cityId = selectedCity.id;
        cityPackage.cityName = selectedCity.city_name;
      }
      this.setState(
        { cityPackageDetails: [...cityPackageDetails, cityPackage] },
        () => {
          this.onCityPackageUpdated();
        }
      );
     
      return;
    } else {
      if (selectedCity) {
        cityPackage.cityId = selectedCity.id;
        cityPackage.cityName = selectedCity.city_name;
      }
      if (packageType === "bloodPackage") {
        const url = `blood-packages-cities/`;
        const payload = { ...cityPackage, bloodPackageId: packageId };
        try {
          const apiOptions = {
            method: "POST",
            url,
            data: payload,
          };
          const response = await PhoenixAPI.request(apiOptions);
          if (response && response.status === 200) {
            const updatedPackageDetails = await this.fetchBloodPackageCities(
              packageId
            );
            this.setState({ cityPackageDetails: updatedPackageDetails });
          }
        } catch (error) {
          this.showErrorNotification(
            "Error on saving city prices of blood Package!" + error.message
          );
        }
      }
      if (packageType === "customPackage") {
        const url = `custom-packages-cities/`;
        const payload = { ...cityPackage, customPackageId: packageId };
        try {
          const apiOptions = {
            method: "POST",
            url,
            data: payload,
          };
          const response = await PhoenixAPI.request(apiOptions);
          if (response && response.status === 200) {
            const updatedPackageDetails = await this.fetchCustomPackageCities(
              packageId
            );
            this.setState({ cityPackageDetails: updatedPackageDetails });
          }
        } catch (error) {
          this.showErrorNotification(
            "Error on saving city prices of Custom Package!" + error.message
          );
        }
      }
      if(packageType==="coachConsultationPackage"){
        this.setState(
          { cityPackageDetails: [...cityPackageDetails, cityPackage] },
          () => {
            this.onCityPackageUpdated();
          }
        );
      }
    }
    }
  };

  fetchBloodPackageCities = async (packageId) => {
    if (!packageId) {
      return null;
    }
    try {
      this.showLoading();
      const url = `/blood-packages-cities/bloodPackageId/${packageId}`;
      const { data } = await PhoenixAPI.get(url);
      console.log("fetchBloodPackageCities:: Fetched Blood Packages: ", data);
      return data;
    } catch (error) {
      console.log("fetchBloodPackageCities:: Fetched Blood Packages: ", error);
      this.showErrorNotification(
        "Error on fetching BloodPackage items! " + error.message
      );
      return null;
    } finally {
      this.hideLoading();
    }
  };

  fetchCustomPackageCities = async (packageId) => {
    if (!packageId) {
      return null;
    }
    try {
      this.showLoading();
      const url = `/custom-packages-cities/customPackageId/${packageId}`;
      const { data } = await PhoenixAPI.get(url);
      console.log("fetchCustomPackageCities:: Fetched custom Packages: ", data);
      return data;
    } catch (error) {
      console.log(
        "fetchCustomPackageCities:: Fetched custom Packages: ",
        error
      );
      this.showErrorNotification(
        "Error on fetching Custom Package items! " + error.message
      );
      return null;
    } finally {
      this.hideLoading();
    }
  };

  

  updateCityPackage = async (cityPackage) => {
    const { selectedCity } = this.state;
   
    const { packageId, packageType } = this.props;
    if (selectedCity) {
      cityPackage.cityId = selectedCity.id;
      cityPackage.cityName = selectedCity.city_name;
      if(packageType === "miniPackage"){
        if(cityPackage.searchDocId==null)
        cityPackage.searchDocId=Date.now();
      }
    }
    if (packageType === "bloodPackage") {
      const url = `blood-packages-cities/${cityPackage.id}`;
      const payload = { ...cityPackage };
      try {
        const apiOptions = {
          method: "PATCH",
          url,
          data: payload,
        };
        const response = await PhoenixAPI.request(apiOptions);
        if (response && response.status === 200) {
          const updatedPackageDetails = await this.fetchBloodPackageCities(
            packageId
          );
          this.setState({ cityPackageDetails: updatedPackageDetails });
        }
      } catch (error) {
        this.showErrorNotification(
          "Error on saving city prices of blood Pacage!" + error.message
        );
      }
    }
    if (packageType === "customPackage") {
      const url = `custom-packages-cities/${cityPackage.id}`;
      const payload = { ...cityPackage };
      try {
        const apiOptions = {
          method: "PATCH",
          url,
          data: payload,
        };
        const response = await PhoenixAPI.request(apiOptions);
        if (response && response.status === 200) {
          const updatedPackageDetails = await this.fetchCustomPackageCities(
            packageId
          );
          this.setState({ cityPackageDetails: updatedPackageDetails });
        }
      } catch (error) {
        this.showErrorNotification(
          "Error on saving city prices of blood Pacage!" + error.message
        );
      }
    }
    if (packageType === "miniPackage") {
      const { cityPackageDetails } = this.state;
      console.log("citypackage", cityPackageDetails);
      
      const newCityPackageDetails = cityPackageDetails.map((it) =>
        it.id === cityPackage.id ? cityPackage : it
      );

      this.setState(
        { cityPackageDetails: newCityPackageDetails },
        () => {
          this.onCityPackageUpdated();
        }
      )

    }
    if(packageType ==="coachConsultationPackage"){
      const { cityPackageDetails } = this.state;
      const newCityPackageDetails = cityPackageDetails.map((it) =>
        it.id === cityPackage.id ? cityPackage : it
      );

      this.setState(
        { cityPackageDetails: newCityPackageDetails },
        () => {
          this.onCityPackageUpdated();
        }
      )
    }


  };

  onCityPackageUpdated = () => {
    this.clearForm();
    this.onCityPackageDetailsUpdated(
      this.state.cityPackageDetails.map((cityPackage) => {
        const q = { ...cityPackage };
        if (q.is_new) {
          delete q.id;
          delete q.is_new;
        }
        return q;
      })
    );
  };

  handleAddNewCityPackage = () => {
    const cityPackage = this.props.packageType=="miniPackage"?{active:false}:{ status: "Inactive" };
    this.setState({ cityPackage }, () => this.showAddEditModal());
  };

  showAddEditModal = () => {
    this.setState({ isAddEditModalVisible: true });
  };

  hideAddEditModal = () => {
    this.clearForm();
    this.setState({ isAddEditModalVisible: false });
  };

  renderEditButton = (packageId) => (
   
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => { 
          this.handleEdit(packageId);
        }}
      >
        Edit
      </button>
    </div>
  );

  renderPencilIcon = (fieldName) => {
    const isEditable = this.props.isEditable ?? true;
    return isEditable && this.state.cityPackage.id ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );
  }

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  render() {
    const {
      cityPackageDetails = [],
      cityPackage = {},
      errors,
      editables,
      sortColumn,
      selectedCity,
    } = this.state;
    const isNewCityPackage =
      cityPackage.id === null || cityPackage.id === undefined;
    const isEditable = this.props.isEditable ?? true;
    const{packageType}=this.props;
    const selectStyles = {
      container: (base) => ({
        ...base,
        flex: 1,
      }),
    };
    const form = (
      <>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="cityId">City Id</label>
            <div className="input-group">
              <input
                value={selectedCity?.id || ""}
                onChange={this.handleChange}
                id="cityId"
                name="cityId"
                type="number"
                placeholder="City Id"
                className={
                  "form-control py-2" + (errors.cityId ? " has-error" : "")
                }
                disabled={!isNewCityPackage && !editables.cityId}
              />
              {/* {this.renderPencilIcon("cityId")} */}
            </div>
            <span className="help-block">{errors.cityId}</span>
          </div>
          {isNewCityPackage && (
            <div className="form-group col">
              <label htmlFor="cityName">City Name</label>
              <Select
                key={`my_unique_select_key__${selectedCity && selectedCity.id}`}
                value={selectedCity && this.createCityOption(selectedCity)}
                onChange={(event) => {
                  const selectedCity = event
                    ? this.findCity(event.value)
                    : null;
                  this.setState({ selectedCity });
                }}
                options={this.state.cityOptions || []}
                style={selectStyles}
                placeholder="City"
                disabled={!isNewCityPackage && !editables.cityName}
                searchable
                lazyLoad
              />
            </div>
          )}
          {!isNewCityPackage && (
            <div className="form-group col">
              <label htmlFor="cityId">City Name</label>
              <div className="input-group">
                <input
                  value={cityPackage?.cityName || ""}
                  onChange={this.handleChange}
                  id="cityName"
                  name="cityName"
                  type="text"
                  placeholder="CityName"
                  className={
                    "form-control py-2" + (errors.cityId ? " has-error" : "")
                  }
                  disabled={!isNewCityPackage && !editables.cityName}
                />
                {/* {this.renderPencilIcon("cityId")} */}
              </div>
            </div>
          )}
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="retailPrice">{packageType=="miniPackage"?"Retail Price ":"Retail Price *"}</label>
            <div className="input-group">
              <input
                value={cityPackage.retailPrice ?? ""}
                onChange={this.handleChange}
                id="retailPrice"
                name="retailPrice"
                type="number"
                placeholder=" Retail Price"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) event.preventDefault();
                }}
                className={
                  "form-control py-2" + (errors.retailPrice ? " has-error" : "")
                }
                disabled={!isNewCityPackage && !editables.retailPrice}
              />
              {this.renderPencilIcon("retailPrice")}
            </div>
            <span className="help-block">{errors.retailPrice}</span>
          </div>
          {packageType=="miniPackage"?
          <div className="form-group col">
          <label htmlFor="status">Status</label>
          <div className="input-group">
            <Dropdown
              value={cityPackage?.active}
              onChange={(event, value) => {
                this.setState({
                  cityPackage: { ...cityPackage, active: value.value },
                });
              }}
              id="active"
              name="active"
              placeholder="Status"
              search
              selection
              options={STATUS_MINI_OPTIONS}
              disabled={!isNewCityPackage && !editables.active}
              className={"form-control" + (errors.active ? " has-error" : "")}
            />
            {this.renderPencilIcon("active")}
          </div>
          <span className="help-block">{errors.active}</span>
        </div>
          :<div className="form-group col">
            <label htmlFor="status">Status</label>
            <div className="input-group">
              <Dropdown
                value={cityPackage?.status}
                onChange={(event, value) => {
                  this.setState({
                    cityPackage: { ...cityPackage, status: value.value },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewCityPackage && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>}
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="discountType">{packageType=="miniPackage"?"Discount Type":"Discount Type *"}</label>
            <div className="input-group">
              <Dropdown
                value={cityPackage.discountType}
                onChange={(event, value) => {
                  this.setState({
                    cityPackage: {
                      ...cityPackage,
                      discountType: value.value,
                    },
                  });
                }}
                id="discountType"
                name="discountType"
                placeholder="Discount Type"
                search
                selection
                options={DISCOUNT_TYPE_OPTIONS}
                disabled={!isNewCityPackage && !editables.discountType}
                className={
                  "form-control" + (errors.discountType ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("discountType")}
            </div>
            <span className="help-block">{errors.discountType}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="discountType">{packageType=="miniPackage"?"Discount Value":"Discount Value *"}</label>
            <div className="input-group">
              <input
                value={cityPackage.discountValue ?? ""}
                onChange={this.handleChange}
                id="discountValue"
                name="discountValue"
                type="number"
                placeholder="Discount Value"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) event.preventDefault();
                }}
                className={
                  "form-control py-2" +
                  (errors.discountValue ? " has-error" : "")
                }
                disabled={!isNewCityPackage && !editables.discountValue}
              />
              {this.renderPencilIcon("discountValue")}
            </div>
            <span className="help-block">{errors.discountValue}</span>
          </div>
        </div>
        <div className="row">
         {packageType=="miniPackage"?
         <div className="form-group col-6">
         <label htmlFor="finalPrice">Final Price *</label>
         <div className="input-group">
           <input
             value= {cityPackage.price ?? ""}
             onChange={this.handleChange}
             id="finalPrice"
             name="price"
             type="number"
             placeholder="Final Price"
             onKeyPress={(event) => {
               if (!/[0-9]/.test(event.key)) event.preventDefault();
             }}
             className={
               "form-control py-2" + (errors.price ? " has-error" : "")
             }
             disabled={!isNewCityPackage && !editables.price}
           />
           {this.renderPencilIcon("price")}
         </div>
         <span className="help-block">{errors.price}</span>
       </div>
          :<div className="form-group col-6">
            <label htmlFor="finalPrice">Final Price *</label>
            <div className="input-group">
              <input
                value= {cityPackage.finalPrice ?? ""}
                onChange={this.handleChange}
                id="finalPrice"
                name="finalPrice"
                type="number"
                placeholder="Final Price"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) event.preventDefault();
                }}
                className={
                  "form-control py-2" + (errors.finalPrice ? " has-error" : "")
                }
                disabled={!isNewCityPackage && !editables.finalPrice}
              />
              {this.renderPencilIcon("finalPrice")}
            </div>
            <span className="help-block">{errors.finalPrice}</span>
          </div>}
          <div className="form-group col-6">
            <label htmlFor="orderSla">Order SLA (in hours)</label>
            <div className="input-group">
              <input
                value={cityPackage.orderSla
                  ?? ""}
                onChange={this.handleChange}
                id="orderSla"
                name="orderSla"
                type="number"
                placeholder="Order SLA"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) event.preventDefault();
                }}
                className={
                  "form-control py-2" + (errors.orderSla ? " has-error" : "")
                }
                disabled={!isNewCityPackage && !editables.orderSla}
              />
              {this.renderPencilIcon("orderSla")}
            </div>
            <span className="help-block">{errors.orderSla}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="operationsSla">Operations SLA (in hours) *</label>
            <div className="input-group">
              <input
                value={cityPackage.operationsSla
                  ?? ""}
                onChange={this.handleChange}
                id="operationsSla"
                name="operationsSla"
                type="number"
                placeholder="operations SLA"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) event.preventDefault();
                }}
                className={
                  "form-control py-2" + (errors.operationsSla ? " has-error" : "")
                }
                disabled={!isNewCityPackage && !editables.operationsSla}
              />
              {this.renderPencilIcon("operationsSla")}
            </div>
            <span className="help-block">{errors.operationsSla}</span>
          </div>
          <div className="form-group col-6">
            <label htmlFor="coachSla">Coach SLA (in hours)</label>
            <div className="input-group">
              <input
                value={cityPackage.coachSla ?? ""}
                onChange={this.handleChange}
                id="coachSla"
                name="coachSla"
                type="number"
                placeholder="Coach SLA"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) event.preventDefault();
                }}
                className={
                  "form-control py-2" + (errors.coachSla ? " has-error" : "")
                }
                disabled={!isNewCityPackage && !editables.coachSla}
              />
              {this.renderPencilIcon("coachSla")}
            </div>
            <span className="help-block">{errors.coachSla}</span>
          </div>
        </div>
       {packageType=="miniPackage"?  <div className="row">
          <div className="form-group col">
            <label htmlFor="reportDeliveryTimeInDaysMinimum">Report Delivery Time Min. (in days)*</label>
            <div className="input-group">
              <input
                value={cityPackage.reportDeliveryTimeInDaysMinimum ?? ""}
                onChange={this.handleChange}
                id="reportDeliveryTimeInDaysMinimum"
                name="reportDeliveryTimeInDaysMinimum"
                type="number"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) event.preventDefault();
                }}
                placeholder="Report Delivery Time"
                className={
                  "form-control py-2" +
                  (errors.reportDeliveryTimeInDaysMinimum ? " has-error" : "")
                }
                disabled={!isNewCityPackage && !editables.reportDeliveryTimeInDaysMinimum}
              />
              {this.renderPencilIcon("reportDeliveryTimeInDaysMinimum")}
            </div>
            <span className="help-block">{errors.reportDeliveryTimeInDaysMinimum}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="reportDeliveryTimeInDaysMaximum">Report Delivery Time Max. (in days)*</label>
            <div className="input-group">
              <input
                value={cityPackage.reportDeliveryTimeInDaysMaximum ?? ""}
                onChange={this.handleChange}
                id="reportDeliveryTimeInDaysMaximum"
                name="reportDeliveryTimeInDaysMaximum"
                type="number"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) event.preventDefault();
                }}
                placeholder="Report Delivery Time"
                className={
                  "form-control py-2" +
                  (errors.reportDeliveryTimeInDaysMaximum? " has-error" : "")
                }
                disabled={!isNewCityPackage && !editables.reportDeliveryTimeInDaysMaximum}
              />
              {this.renderPencilIcon("reportDeliveryTimeInDaysMaximum")}
            </div>
            <span className="help-block">{errors.reportDeliveryTimeInDaysMaximum}</span>
          </div>
        </div>
       : <div className="row">
          <div className="form-group col">
            <label htmlFor="deliveryTime">Delivery Time</label>
            <div className="input-group">
              <input
                value={cityPackage.deliveryTime ?? ""}
                onChange={this.handleChange}
                id="deliveryTime"
                name="deliveryTime"
                type="text"
                placeholder="Delivery Time"
                className={
                  "form-control py-2" +
                  (errors.deliveryTime ? " has-error" : "")
                }
                disabled={!isNewCityPackage && !editables.deliveryTime}
              />
              {this.renderPencilIcon("deliveryTime")}
            </div>
            <span className="help-block">{errors.deliveryTime}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="deliveryTimeArabic">Delivery Time (Arabic)</label>
            <div className="input-group">
              <input
                value={cityPackage.deliveryTimeArabic ?? ""}
                onChange={this.handleChange}
                id="deliveryTimeArabic"
                name="deliveryTimeArabic"
                type="text"
                placeholder="Delivery Time Arabic"
                className={
                  "form-control py-2" +
                  (errors.deliveryTimeArabic ? " has-error" : "")
                }
                disabled={!isNewCityPackage && !editables.deliveryTimeArabic}
              />
              {this.renderPencilIcon("deliveryTimeArabic")}
            </div>
            <span className="help-block">{errors.deliveryTimeArabic}</span>
          </div>
        </div>}
        <div className="row" style={{direction: "rtl"}} >
          <div className="edit-subscription-buttons form-group"  style={{margin:"2px 11px"}}>
            <button
              className="btn btn-secondary rounded-pill px-4 py-2"
              onClick={this.hideAddEditModal}
              style={{margin:"2px 4px"}}
            >
              Cancel
            </button>
            <button
              onClick={this.handleSubmit}
              disabled={!isEditable}
              className="btn btn-lg btn-success rounded-pill px-4 py-2"
              style={{margin:"2px 4px"}}
            >
              {cityPackage.id ? "Save" : "Add"}
            </button>
          </div>
        </div>
      </>
    );

    return (
      <>
        <div className="row">
          <div className="form-group col px-0">
            <Card>
              <DataTable
                highlightOnHover
                columns={ this.props.packageType=="miniPackage"?this.miniId:this.columns}
                data={cityPackageDetails || []}
                onSort={(sortColumn, sortDirection) =>
                  this.setState({
                    sortColumn: sortColumn.selector,
                    sortDirection,
                  })
                }
                pagination
                responsive
                defaultSortField={sortColumn}
                sortIcon={<ArrowDownward></ArrowDownward>}
              />
              <button
                onClick={this.handleAddNewCityPackage}
                disabled={!isEditable}
                className="btn btn-success mt-1"
              >
                Add New
              </button>
            </Card>
          </div>
        </div>
        <Modal
          size="lg"
          show={this.state.isAddEditModalVisible}
          onHide={this.hideAddEditModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {cityPackage.id === null || cityPackage.id === undefined
                ? "Add City Level Details"
                : "Edit City Level Details"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tabs>
              <Tab eventKey="mini-package-citywise-details" title="Details" >
                {form}
              </Tab>
              {this.props.packageType=="miniPackage" && this.state.pageIdForLogs!=null ?
                <Tab eventKey="logs-mini-package" title="Logs" >
                  <Logs pageId={this.state.pageIdForLogs} pageType="MINI_PACKAGE" />
                </Tab> : ""
              }
              
            </Tabs>
          </Modal.Body>
          {/* <Modal.Footer>
            <button
              className="btn btn-secondary rounded-pill px-4 py-2"
              onClick={this.hideAddEditModal}
            >
              Cancel
            </button>
            <button
              onClick={this.handleSubmit}
              disabled={!isEditable}
              className="btn btn-lg btn-success rounded-pill px-4 py-2"
            >
              {cityPackage.id ? "Save" : "Add"}
            </button>
          </Modal.Footer> */}
        </Modal>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PackageCityDetails);
