import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";

function ConfirmCancelRefundNotes(props) {
 
  const { refundNotes: notes, title, isVisible,notifyClient, onHide, onSave,onCancel} = props;
  const [refundNotes, setRefundNotes] = useState(notes);
  const[error,setError]=useState("");
  const[notificationClient,setNotificationClient]=useState(notifyClient);
  useEffect(() => setRefundNotes(notes), [notes, isVisible]);
  
  console.log("refund",notificationClient);
  const RefundNotesValidation=(refundvalid)=>{
   if(refundvalid=="" || refundvalid==null)
   {
    setError("please enter reason");
    return false;
  
   }
   else{
    setError("");
    return true;
   }

  }
  const handleChange=()=>{
    console.log("checking");
    setNotificationClient(!notificationClient);
  }


  return (
    <Modal show={isVisible} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          <p style={{ fontSize: "1rem" }}>{title}</p>
        </Modal.Title>
        <div className="user-title"></div>
      </Modal.Header>
      <Modal.Body>
        <>

          <div className="row mt-1 ml-1">
            <div >Are you sure you want to cancel the order? Please mention the reasons in the notes below *</div>
            <div className="form-group col">
              <textarea
                value={refundNotes ?? ""}
                onChange={(event) => {setRefundNotes(event.target.value);setError("");}}
                id="refundNotes"
                name="refundNotes"
                placeholder="Refund Notes"
                className="form-control py-2"
              ></textarea>
            </div>
          </div>
          <div className="row ml-3 mb-2"><span className="help-block">{error}</span></div>
             
          <div className="row ">
          <div className="form-group ml-1">
          <div className="custom-control custom-checkbox">
                                <input
                                    checked={notificationClient}
                                    onChange={handleChange}
                                 
                                    type="checkbox"
                                     /> &nbsp;
                                <label style={{"textAlign":"center"}}>Notify Client?</label> 
                            
                                </div>

            </div>          
            <div className="form-group  col text-right">
            <button
                onClick={(event) => {if(RefundNotesValidation(refundNotes))onSave(refundNotes,notificationClient);
                
                }}
                type="button"
                className="btn button btn-danger"
              >
                Refund & Cancel Order 
              </button>  &nbsp;&nbsp;
              <button
                onClick={onHide}
                type="button"
                className="btn btn-secondary "
              >
                Exit
              </button>
              
            </div>
          </div>
        </>
      </Modal.Body>
    </Modal>
  );
}

ConfirmCancelRefundNotes.propTypes = {
  orderNotes: PropTypes.string,
  title: PropTypes.string,
  isVisible: PropTypes.bool,
  onHide: PropTypes.func,
  onSave: PropTypes.func,
  onCancel:PropTypes.func,
};

ConfirmCancelRefundNotes.defaultProps = {
  orderNotes: null,
  title: "",
  isVisible: false,
  onHide: (f) => f,
  onSave: (f) => f,
  onCancel:(f)=>f,
};





export default ConfirmCancelRefundNotes;