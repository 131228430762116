import React, { Component } from "react";
import { connect } from "react-redux";
import CountryDropdown from "components/Common/CountryDropdown";
import { fetchCountries } from "services/RegionService";
import AccessDenied from "../../components/Common/AccessDenied";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { Dropdown } from "semantic-ui-react";
import AlertBox from "components/AlertBox/AlertBox";
import { fetchAllCategories } from "services/CMSNavigationService"
import {
  isAccessDenied,
  isUserSuperAdmin,
  isUserAdmin,
} from "services/aclService";
import { getUserId, getUserName } from "services/UserService";
import { Page } from "utils/constant";
import Status from "components/Common/Status";
import Select from "react-select";
import PhoenixAPI from "utils/PhoenixAPI";
import MobileWebsiteCategories from "./MobileWebsiteCategories";

const STATUS_OPTIONS = [
  {
    key: "Active",
    value: true,
    text: "Active",
  },
  {
    key: "Inactive",
    value: false,
    text: "Inactive",
  },
];

class CMSNavigationMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      countries: [],
      selectedCountry: null,
      allCategories: [],
      mobileCategories: [],
      desktopCategories: []

    };
  }


  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if (!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  };

  fetchAclUser = async () => {
    const userId = getUserId();
    if (!userId) {
      console.log("fetchAclUser:: Invalid user id!", { userId });
      return;
    }

    try {
      const pageName = Page.VALEO_AMBASSADORS;
      const { data: aclUser } = await PhoenixAPI.get(
        `/api/v1/acl/users/userId/${userId}`
      );
      const pagePermission =
        aclUser?.pagePermissions?.find(
          (it) => pageName?.toLowerCase() === it.pageName?.toLowerCase()
        ) ?? null;
      const pageAccessRights =
        pagePermission?.pageAccessRights
          ?.filter((it) => it.isActive ?? false)
          ?.map((it) => it.accessRightName?.toLowerCase()) ?? null;
      const accessDenied = isAccessDenied(aclUser, pageAccessRights);
      this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
        const isEditable = this.hasPageAccessRight("edit");
        this.setState({ isEditable });
      });
    } catch (error) {
      console.log("fetchAclUser:: Error on fetching acl user!", error);
    }
  };
  fetchMobileAndWebsiteCategories = async () => {
    const { selectedCountry } = this.state;
    try {
      this.showLoading();
      const categories = await fetchAllCategories() || [];
      console.log("categories in fetch", categories, selectedCountry);
      const mobileCategories = categories.filter((category) => category.type === "MOBILE" && category.countryId == selectedCountry?.id);
      const desktopCategories = categories.filter((category) => category.type === "DESKTOP" && category.countryId == selectedCountry?.id);
      this.setState({ mobileCategories, desktopCategories });
    } catch (error) {
      this.showErrorNotification("Error on fetching categories: ", error.message);
    } finally {
      this.hideLoading();
    }
  }

  async componentDidMount() {
    await Promise.all([this.fetchAclUser(), this.fetchCountries()]);
    // this.fetchMobileAndWebsiteCategories();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedCountry !== this.state.selectedCountry) {
      this.fetchMobileAndWebsiteCategories();
    }
  }
  componentWillUnmount() {
    this.hideLoading();
  }

  fetchCountries = async () => {
    try {
      this.showLoading();
      const countries = (await fetchCountries()) ?? [];
      const selectedCountry = countries.find((country) => country.is_default);
      this.setState({ countries, selectedCountry });
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching countries!" + error.message
      );
    } finally {
      this.hideLoading();
    }
  };

  handleCountrySelection = (selectedCountry) =>
    this.setState({ selectedCountry });

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });
  render() {
    const {
      allCategories,
      selectedCountry,
      countries,
      isEditable,
      mobileCategories,
      desktopCategories
    } = this.state;


    return (
      <div className="manage-package">
        <CountryDropdown
          countries={countries || []}
          selectedCountry={selectedCountry}
          onSelect={this.handleCountrySelection}
        />
        <MobileWebsiteCategories categories={mobileCategories} country={selectedCountry} type={"MOBILE"} isEditable={isEditable} />
        <MobileWebsiteCategories categories={desktopCategories} country={selectedCountry} type={"DESKTOP"} isEditable={isEditable} />

      </div>
    )

  }

}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CMSNavigationMenu);