import { TurnedIn } from "@material-ui/icons";
import React from "react";
import Select from "react-select";
const OPTION_NONE = { key: "NONE", value: null, label: "NONE" };
function SelectListPackage(props) {
  const { ItemTypes, onItemTypeSelect, selectedItemTypeId ,filterText="Select Package", isDisabled} = props;
  const createOption = (itemType) => {
  
    if (!itemType) return ;
    return {
      key: itemType.packageId,
      value: itemType.packageId,
      label: itemType.packageName
      ,
    };
  };

  const findItemType = (itemTypeId) =>
    ItemTypes.find((itemType) => itemType.packageId === selectedItemTypeId);
  const ItemTypeOptions = ItemTypes.map(createOption);
  const selectedItemType = findItemType(selectedItemTypeId);
  const selectStyles = {
    container: (base) => ({
      ...base,
      flex: 1,
    }),
  };

  return (
    <Select
      value={createOption(selectedItemType)}
      onChange={(option) => onItemTypeSelect(option.value)}
      options={ItemTypeOptions}
      styles={selectStyles}
      placeholder={filterText}
        isDisabled={isDisabled}
        clearble={true}
    />
  );
}

export default SelectListPackage;
