import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArchiveOutlinedIcon from "@material-ui/icons/ArchiveOutlined";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import Drag from "@material-ui/icons/DragIndicatorOutlined";
import Card from "react-bootstrap/Card";
import ActionBar from "react-bootstrap/Dropdown";
import DataTable from "react-data-table-component";
import ReactDragListView from "react-drag-listview/lib/index.js";
import { Modal } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import _ from "lodash";
import Status from "components/Common/Status";

const STATUS_OPTIONS = [
  {
    key: "Active",
    value: "Active",
    text: "Active",
  },
  {
    key: "Inactive",
    value: "Inactive",
    text: "Inactive",
  },
];

class FAQForm extends Component {
  constructor(props) {
    super(props);

    const { questions = [], onQuestionUpdated = (f) => f } = this.props;

    this.onQuestionUpdated = onQuestionUpdated;

    this.state = {
      questions,
      question: {},
      errors: {},
      editables: {},
      sortColumn: "rank",
      sortDirection: "asc",
      isConfirmDeleteModalVisible: false,
    };

    this.columns = [
      {
        name: "",
        width: "50px",
        cell: () => <Drag className="drag-handle"></Drag>,
      },
      {
        name: "ID",
        selector: "id",
        sortable: true,
        width: "50px",
        maxWidth: "100px",
      },
      {
        name: "Question (English)",
        selector: "questionEnglish",
        sortable: true,
        wrap: true,
        format: (question) => (
          <div className="text-overflow-ellipsis">
            {question.questionEnglish ?? ""}
          </div>
        ),
      },
      {
        name: "Question (Arabic)",
        selector: "questionArabic",
        sortable: true,
        wrap: true,
        right: true,
        format: (question) => (
          <div
            className="text-overflow-ellipsis"
            style={{ textAlign: "right" }}
          >
            {question.questionArabic ?? ""}
          </div>
        ),
      },
      {
        name: "Answer (English)",
        selector: "answerEnglish",
        sortable: true,
        wrap: true,
        format: (question) => (
          <div className="text-overflow-ellipsis">
            {question.answerEnglish ?? ""}
          </div>
        ),
      },
      {
        name: "Answer (Arabic)",
        selector: "answerArabic",
        sortable: true,
        wrap: true,
        right: true,
        format: (question) => (
          <div
            className="text-overflow-ellipsis"
            style={{ textAlign: "right" }}
          >
            {question.answerArabic ?? ""}
          </div>
        ),
      },
      {
        name: "Status",
        selector: "status",
        sortable: true,
        center: true,
        maxWidth: "150px",
        cell: (question) => <Status isActive={question.status === "Active"} />,
      },
      {
        name: "Rank",
        selector: "rank",
        width: "50px",
        maxWidth: "100px",
        center: true,
        sortable: "true",
      },
      {
        name: "Actions",
        maxWidth: "100px",
        center: true,
        allowOverflow: true,
        cell: (question) => this.renderActionBar(question),
      },
    ];

    const updateRank = this.updateRank;
    this.dragProps = {
      onDragEnd(fromIndex, toIndex) {
        updateRank(fromIndex, toIndex);
      },
      nodeSelector: ".rdt_TableRow",
      handleSelector: ".drag-handle",
      enableScroll: true,
    };
  }

  clearForm = () => {
    // Array.from(document.querySelectorAll("input")).forEach(
    //   (it) => (it.value = "")
    // );
    // Array.from(document.querySelectorAll("textarea")).forEach(
    //   (it) => (it.value = "")
    // );
    this.setState({
      errors: {},
      editables: {},
      question: {},
    });
  };

  handleChange = ({ currentTarget: input }) => {
    const question = { ...this.state.question };
    question[input.name] = input.value;
    this.setState({ question });
  };

  handleEdit = (id) => {
    this.clearForm();
    const { questions = [] } = this.state;
    const question = questions.find((it) => it.id === id);
    if (!question) return;

    this.setState({ question }, () => this.showAddEditModal());
  };

  handleStatusChange = (id) => this.toggleStatus(id);

  handleDelete = (id) => this.deleteQuestion(id);

  handleSubmit = (event) => {
    event.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }

    const { question = {} } = this.state;
    if (question.id === null || question.id === undefined)
      this.createQuestion();
    else this.updateQuestion(question.id);
    this.hideAddEditModal();
  };

  validate = () => {
    const errors = {};
    const {
      questionEnglish,
      questionArabic,
      answerEnglish,
      answerArabic,
      status,
    } = this.state.question || {};

    if (!questionEnglish || questionEnglish.trim() === "")
      errors.questionEnglish = "Question (English) cannot be blank";
    else if (questionEnglish && questionEnglish.length > 1000)
      errors.questionEnglish =
        "Question (English) is limited to a 1000 characters";

    if (!questionArabic || questionArabic.trim() === "")
      errors.questionArabic = "Question (Arabic) cannot be blank";
    else if (questionArabic && questionArabic.length > 1000)
      errors.questionArabic =
        "Question (Arabic) is limited to a 1000 characters";

    if (!answerEnglish || answerEnglish.trim() === "")
      errors.answerEnglish = "Answer (English) cannot be blank";
    else if (answerEnglish && answerEnglish.length > 10000)
      errors.answerEnglish =
        "Answer (English) is limited to a 10000 characters";

    if (!answerArabic || answerArabic.trim() === "")
      errors.answerArabic = "Answer (Arabic) cannot be blank";
    else if (answerArabic && answerArabic.length > 10000)
      errors.answerArabic = "Answer (Arabic) is limited to a 10000 characters";

    if (!status) errors.status = "Status cannot be blank";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  createQuestion = () => {
    const { questions = [], question } = this.state;
    const ids = questions.length > 0 ? questions.map((it) => it.id) : null;
    question.id = ids ? Math.max(...ids) + 1 : 1;

    const ranks =
      questions.length > 0
        ? questions.filter((it) => it.rank).map((it) => it.rank)
        : null;
    question.rank = ranks ? Math.max(...ranks) + 1 : 1;
    question.is_new = true;
     console.log("FGhjklkjhghjkl", questions);
    this.setState({ questions: [...questions, question] }, () => {
      this.showSuccessNotification("FAQ Created Successfully!");
      this.onQuestionUpdatedLocally();
    });
  };

  updateQuestion = (id) => {
    const { questions = [], question } = this.state;
    const newQuestions = questions.map((it) => (it.id === id ? question : it));
    this.setState({ questions: newQuestions }, () => {
      this.showSuccessNotification("FAQ Updated Successfully!");
      this.onQuestionUpdatedLocally();
    });
  };

  toggleStatus = (id) => {
    const { questions = [] } = this.state;
    const newQuestions = questions.map((it) =>
      it.id === id
        ? { ...it, status: it.status === "Active" ? "Inactive" : "Active" }
        : it
    );
    this.setState({ questions: newQuestions }, () => {
      this.showSuccessNotification("FAQ Updated Successfully!");
      this.onQuestionUpdatedLocally();
    });
  };

  deleteQuestion = (id) => {
    const { questions = [] } = this.state;
    const questionToDelete = questions.find((it) => it.id === id) || {};
    const newQuestions = questions
      .filter((it) => it.id !== id)
      .map((it) =>
        it.rank > questionToDelete.rank ? { ...it, rank: it.rank - 1 } : it
      );
    this.setState({ questions: newQuestions }, () => {
      this.showSuccessNotification("FAQ Deleted Successfully!");
      this.onQuestionUpdatedLocally();
    });
  };

  updateRank = (fromIndex, toIndex) => {
    if (
      fromIndex === null ||
      fromIndex === undefined ||
      toIndex === null ||
      toIndex === undefined
    )
      return;

    const { questions = [], sortColumn, sortDirection } = this.state;
    const sortedQuestions = _.sortBy(questions, sortColumn, sortDirection);
    const fromQuestion = { ...sortedQuestions[fromIndex] };
    const toQuestion = { ...sortedQuestions[toIndex] };

    if (fromQuestion && toQuestion) {
      const tempRank = fromQuestion.rank;
      fromQuestion.rank = toQuestion.rank;
      toQuestion.rank = tempRank;

      const newQuestions = questions.map((it) => {
        if (it.id === fromQuestion.id) return fromQuestion;
        else if (it.id === toQuestion.id) return toQuestion;
        else return it;
      });
      this.setState({ questions: newQuestions }, () => {
        this.showSuccessNotification("Rank Updated Successfully!");
        this.onQuestionUpdatedLocally();
      });
    } else {
      this.showErrorNotification("Rank Updation Failed!");
    }
  };

  onQuestionUpdatedLocally = () => {
    this.clearForm();
    this.onQuestionUpdated(
      this.state.questions.map((question) => {
        const q = { ...question };
        if (q.is_new) {
          delete q.id;
          delete q.is_new;
        }
        return q;
      })
    );
  };

  handleAddNewQuestion = () => {
    const question = { status: "Inactive" };
    this.setState({ question }, () => this.showAddEditModal());
  };

  showAddEditModal = () => {
    this.setState({ isAddEditModalVisible: true });
  };

  hideAddEditModal = () => {
    this.clearForm();
    this.setState({ isAddEditModalVisible: false });
  };

  showConfirmDeleteModal = () => {
    this.setState({ isConfirmDeleteModalVisible: true });
  };

  hideConfirmDeleteModal = () => {
    this.setState({ isConfirmDeleteModalVisible: false });
  };

  renderActionBar = (question) => (
    <ActionBar alignRight className="action-menu valeo-dropdown">
      <ActionBar.Toggle disabled={!this.props.isEditable} variant="">
        <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
      </ActionBar.Toggle>
      <ActionBar.Menu>
        <ActionBar.Item onClick={() => this.handleEdit(question.id)}>
          <EditIcon className="icon-small" />
          Edit
        </ActionBar.Item>
        <ActionBar.Item onClick={() => this.handleStatusChange(question.id)}>
          {question.status === "Active" ? (
            <ArchiveOutlinedIcon className="icon-small" />
          ) : (
            <CheckCircleOutlinedIcon className="icon-small" />
          )}
          {question.status === "Active" ? "Inactive" : "Active"}
        </ActionBar.Item>
        <ActionBar.Item
          // onClick={() => this.handleDelete(question.id)}
          onClick={() =>
            this.setState({ questionToDelete: question.id }, () =>
              this.showConfirmDeleteModal()
            )
          }
        >
          <DeleteIcon className="icon-small" style={{ fill: "red" }} />
          <span style={{ color: "red" }}>Remove</span>
        </ActionBar.Item>
      </ActionBar.Menu>
    </ActionBar>
  );

  renderPencilIcon = (fieldName) =>
    this.state.question.id ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  render() {
    const {
      questions = [],
      question = {},
      errors,
      editables,
      sortColumn,
    } = this.state;

    const isNewQuestion = question.id === null || question.id === undefined;

    const form = (
      <>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="questionEnglish">Question (English)*</label>
            <div className="input-group">
              <textarea
                value={question.questionEnglish}
                onChange={this.handleChange}
                id="questionEnglish"
                name="questionEnglish"
                placeholder="Question in English"
                readOnly={!isNewQuestion && !editables.questionEnglish}
                className={
                  "form-control py-2" +
                  (errors.questionEnglish ? " has-error" : "")
                }
              ></textarea>
              {this.renderPencilIcon("questionEnglish")}
            </div>
            <span className="help-block">{errors.questionEnglish}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="questionArabic">Question (Arabic)*</label>
            <div className="input-group">
              <textarea
                value={question.questionArabic}
                onChange={this.handleChange}
                id="questionArabic"
                name="questionArabic"
                placeholder="Question in Arabic"
                readOnly={!isNewQuestion && !editables.questionArabic}
                className={
                  "form-control input-arabic py-2" +
                  (errors.questionArabic ? " has-error" : "")
                }
              ></textarea>
              {this.renderPencilIcon("questionArabic")}
            </div>
            <span className="help-block">{errors.questionArabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="answerEnglish">Answer (English)*</label>
            <div className="input-group">
              <textarea
                value={question.answerEnglish}
                onChange={this.handleChange}
                id="answerEnglish"
                name="answerEnglish"
                placeholder="Answer in English"
                readOnly={!isNewQuestion && !editables.answerEnglish}
                className={
                  "form-control py-2" +
                  (errors.answerEnglish ? " has-error" : "")
                }
              ></textarea>
              {this.renderPencilIcon("answerEnglish")}
            </div>
            <span className="help-block">{errors.answerEnglish}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="answerArabic">Answer (Arabic)*</label>
            <div className="input-group">
              <textarea
                value={question.answerArabic}
                onChange={this.handleChange}
                id="answerArabic"
                name="answerArabic"
                placeholder="Answer in Arabic"
                readOnly={!isNewQuestion && !editables.answerArabic}
                className={
                  "form-control input-arabic py-2" +
                  (errors.answerArabic ? " has-error" : "")
                }
              ></textarea>
              {this.renderPencilIcon("answerArabic")}
            </div>
            <span className="help-block">{errors.answerArabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="status">Status</label>
            <div className="input-group">
              <Dropdown
                value={question?.status}
                onChange={(event, value) => {
                  this.setState({
                    question: { ...question, status: value.value },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewQuestion && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
        </div>
      </>
    );

    return (
      <div className="container mt-4 px-0">
        <div className="row">
          <div className="form-group col">
            <Card>
              <ReactDragListView {...this.dragProps}>
                <DataTable
                  highlightOnHover
                  columns={this.columns}
                  data={questions || []}
                  onSort={(sortColumn, sortDirection) =>
                    this.setState({
                      sortColumn: sortColumn.selector,
                      sortDirection,
                    })
                  }
                  pagination
                  responsive
                  defaultSortField={sortColumn}
                  sortIcon={<ArrowDownward></ArrowDownward>}
                />
              </ReactDragListView>
              <button
                onClick={this.handleAddNewQuestion}
                disabled={!this.props.isEditable}
                className="btn btn-success mt-1"
              >
                Add New
              </button>
            </Card>
          </div>
        </div>
        <Modal
          size="lg"
          show={this.state.isAddEditModalVisible}
          onHide={this.hideAddEditModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {question.id === null || question.id === undefined
                ? "Add FAQ"
                : "Edit FAQ"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{form}</Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary rounded-pill px-4 py-2"
              onClick={this.hideAddEditModal}
            >
              Cancel
            </button>
            <button
              onClick={this.handleSubmit}
              className="btn btn-lg btn-success rounded-pill px-4 py-2"
            >
              {question.id ? "Save" : "Add"}
            </button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.isConfirmDeleteModalVisible}
          onHide={this.hideConfirmDeleteModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Remove Question</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to remove the question?</Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary rounded-pill px-4 py-1"
              onClick={this.hideConfirmDeleteModal}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                this.deleteQuestion(this.state.questionToDelete);
                this.hideConfirmDeleteModal();
              }}
              className="btn btn-danger rounded-pill px-4 py-1"
            >
              Yes
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

FAQForm.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.object).isRequired,
  onQuestionUpdated: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired
};

FAQForm.defaultProps = {
  questions: [],
  onQuestionUpdated: (f) => f,
  isEditable: true
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FAQForm);
