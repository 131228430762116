import React, { Component } from "react";
import Search from "components/Search/Search";
import Status from "components/Common/Status";
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { Card, Modal, ModalTitle, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import AlertBox from "components/AlertBox/AlertBox";
import { Dropdown } from "semantic-ui-react";
import PhoenixAPI from "utils/PhoenixAPI";
import {
  saveWebKeyword,
  updatedWebKeyword,
} from "services/SearchKeywordServices";

const BASE_URL_KEYWORDS_FOR_WEB = "/trending-search-web";

const STATUS_OPTIONS = [
  {
    key: "Active",
    value: true,
    text: "Active",
  },
  {
    key: "Inactive",
    value: false,
    text: "Inactive",
  },
];

class WebSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keywords: [],
      keyword: {},
      searchTerm: null,
      searchApplied: false,
      isKeywordViewModalVisible: false,
      errors: {},
      editables: {},
      loadError: "",
    };
    this.columns = [
      {
        name: "ID",
        selector: "id",
        width: "100px",
        sortable: true,
      },
      {
        name: "Keyword Name",
        selector: "keywordEnglish",
        wrap: true,
        sortable: true,
      },
      {
        name: "Redirection Link",
        selector: "redirectUrlEnglish",
        wrap: true,
        sortable: true,
      },
      {
        name: "Status",
        selector: "status",
        maxWidth: "150px",
        center: true,
        cell: (p) => <Status isActive={p.isActive ?? false} />,
        sortable: true,
      },
      {
        name: "Edit",
        center: true,
        cell: (p) => this.renderViewButton(p.id),
      },
    ];
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.selectedCountry !== nextProps.selectedCountry) {
      this.setState(
        {
          countries: nextProps.countries,
          selectedCountry: nextProps.selectedCountry,
        },
        () => this.fetchKeywords()
      );
    }
  }

  fetchKeywords = async () => {
    const { selectedCountry = null } = this.props || {};
    console.log("Fetching keywords selectedCountry", this.props);
    if (!selectedCountry) {
      console.log("Please select a country");
      return;
    }
    try {
      const { data: keywords = [] } = await PhoenixAPI.get(
        `${BASE_URL_KEYWORDS_FOR_WEB}/${selectedCountry.id}`
      );
      console.log("Keywords: ", keywords);
      this.setState({ keywords });
    } catch (error) {
      console.log("Error on fetching Keywords", error.message);
      this.showErrorNotification(
        "Some error has occured. Please try again.",
        error.message
      );
    }
  };

  findCountry = (id) => {
    const countries = this.state.countries || [];
    return countries.find((country) => country.id === id);
  };

  resetForm = () => {
    this.setState({
      keyword: {},
      errors: {},
      editables: {},
    });
  };

  renderViewButton = (id) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleViewKeyword(id);
        }}
      >
        Edit
      </button>
    </div>
  );

  handleViewKeyword = (keywordId) => {
    const keywords = this.state.keywords ?? [];
    const keyword = keywords.find((it) => it.id === keywordId);
    if (!keyword) {
      console.log(`Text Banner with ID ${keywordId} is not found!`);
      return;
    }
    this.setState({ keyword, isNewKeyword: false }, () =>
      this.showKeywordViewModal()
    );
  };

  handleSaveKeyword = async () => {
    const { keyword } = this.state;
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }
    try {
      this.showLoading();
      const isValidId = keyword.id !== null && keyword.id !== undefined;
      const response = isValidId
        ? await updatedWebKeyword(keyword)
        : await saveWebKeyword(keyword);

      this.hideKeywordViewModal();
      console.log("Response", response);
      this.showSuccessNotification(
        isValidId
          ? "Search Keyword Updated Successfully."
          : "Search Keyword Created Successfully."
      );
      this.fetchKeywords();
    } catch (error) {
      console.log("Some error occurred: " + error.message);
    } finally {
      this.hideLoading();
    }
  };

  validate = () => {
    const { keyword = {} } = this.state;
    const errors = {};

    const {
      keywordEnglish,
      keywordArabic,
      redirectUrlEnglish,
      redirectUrlArabic,
    } = keyword;

    if (!keywordEnglish || keywordEnglish.trim() === "")
      errors.keywordEnglish = "Keyword Name English cannot be blank";
    else if (keywordEnglish.length > 500)
      errors.keywordEnglish =
        "Keyword Name English is limited to a 500 characters";

    if (!keywordArabic || keywordArabic.trim() === "")
      errors.keywordArabic = "keyword Name Arabic cannot be blank";
    else if (keywordArabic.length > 500)
      errors.keywordArabic =
        "keyword Name Arabic is limited to a 500 characters";

    if (!redirectUrlEnglish || redirectUrlEnglish.trim() === "")
      errors.redirectUrlEnglish = "Redirection Link English cannot be blank";
    else if (redirectUrlEnglish.length > 255)
      errors.redirectUrlEnglish =
        "Redirection Link English is limited to a 255 characters";

    if (!redirectUrlArabic || redirectUrlArabic.trim() === "")
      errors.redirectUrlArabic = "Redirection Link Arabic cannot be blank";
    else if (redirectUrlArabic.length > 255)
      errors.redirectUrlArabic =
        "Redirection Link Arabic is limited to a 255 characters";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  handleAddNewBanner = () => {
    this.setState({ isNewKeyword: true }, () => this.showKeywordViewModal());
  };

  createRankOptions = () => {
    const { isNewKeyword } = this.state;
    const banners = this.getKeywordsForCurrentCountry();
    const ranks = banners.filter((it) => it.rank).map((it) => it.rank);
    const minRank = Math.min(1, ...ranks) || 0;
    const maxRank = Math.max(...ranks) || 0;
    const until = isNewKeyword ? maxRank + 1 : maxRank;
    const rankOptions = [];
    for (let i = minRank; i <= until; i++)
      rankOptions.push({ key: i, value: i, text: i });

    if (rankOptions.length === 0)
      rankOptions.push({ key: 1, value: 1, text: 1 });
    return rankOptions;
  };

  showKeywordViewModal = () => {
    const { selectedCountry, isNewKeyword } = this.state;
    // const rankOptions = this.createRankOptions() || [];
    if (isNewKeyword) {
      //   const rank = Math.max(...rankOptions.map((it) => it.value));
      const keyword = {
        countryId: selectedCountry?.id,

        isActive: false,
      };
      this.setState({
        keyword,
        isKeywordViewModalVisible: true,
        // rankOptions,
      });
    } else {
      this.setState({
        isKeywordViewModalVisible: true,
        // rankOptions,
      });
    }
  };

  hideKeywordViewModal = () => {
    this.setState({ isKeywordViewModalVisible: false });
    this.resetForm();
  };

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  renderPencilIcon = (fieldName) => {
    const isEditable = this.props.isEditable ?? true;
    return isEditable && !this.state.isNewKeyword ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );
  };

  handleChange = ({ currentTarget: input }) => {
    const keyword = { ...this.state.keyword };
    keyword[input.name] = input.value;
    this.setState({ keyword });
  };

  handleSearch = (queryString) => {
    this.setState({
      searchApplied: true,
      searchTerm: queryString ? queryString : "",
    });
  };

  handleSearchClear = () => {
    this.setState({
      searchTerm: "",
      searchApplied: false,
    });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({
        searchTerm: "",
        searchApplied: false,
      });
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  getFilteredKeywords = () => {
    const keywords = this.getKeywordsForCurrentCountry();
    return this.getSearchedKeyWords(keywords);
  };

  getKeywordsForCurrentCountry = () => {
    const { keywords, selectedCountry } = this.state;
    return keywords && selectedCountry
      ? keywords.filter((keyword) => keyword.countryId === selectedCountry.id)
      : [];
  };

  getSearchedKeyWords = (keywords) => {
    const { searchApplied, searchTerm } = this.state;
    if (!searchApplied || !searchTerm) return keywords;
    const newValue = searchTerm.slice(0).trim().toLowerCase();
    return keywords.filter((keyword) => {
      const { id, keywordEnglish, redirectUrlEnglish } = keyword;
      return (
        (keywordEnglish &&
          keywordEnglish.toLowerCase().indexOf(newValue) !== -1) ||
        (id && id.toString() === newValue)
      );
    });
  };

  render() {
    const {
      keywords,
      searchTerm,
      keyword,
      searchApplied,
      countries,
      selectedCountry,
      errors,
      editables,
      loadError,
      isNewKeyword,
    } = this.state;

    const isEditable = this.props.isEditable ?? true;
    const filteredKeywords = this.getFilteredKeywords(keywords) ?? [];

    const detailsForm = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="keywordEnglish"> Keyword (English) *</label>
            <div className="input-group">
              <textarea
                value={keyword?.keywordEnglish || ""}
                onChange={this.handleChange}
                id="keywordEnglish"
                name="keywordEnglish"
                type="text"
                placeholder="Enter Keyword in English"
                className={
                  "form-control py-2" +
                  (errors.keywordEnglish ? " has-error" : "")
                }
                disabled={!isNewKeyword && !editables.keywordEnglish}
              />
              {this.renderPencilIcon("keywordEnglish")}
            </div>
            <span className="help-block">{errors.keywordEnglish}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="keywordArabic">Keyword (Arabic)*</label>
            <div className="input-group">
              <textarea
                value={keyword?.keywordArabic || ""}
                onChange={this.handleChange}
                id="keywordArabic"
                name="keywordArabic"
                type="text"
                placeholder="Enter keyword in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.keywordArabic ? " has-error" : "")
                }
                disabled={!isNewKeyword && !editables.keywordArabic}
              />
              {this.renderPencilIcon("keywordArabic")}
            </div>
            <span className="help-block">{errors.keywordArabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="redirectUrlEnglish">
              Redirection Link (English)*
            </label>
            <div className="input-group">
              <input
                value={keyword?.redirectUrlEnglish || ""}
                onChange={this.handleChange}
                id="redirectUrlEnglish"
                name="redirectUrlEnglish"
                type="text"
                placeholder="Enter Redirection Link in English"
                className={
                  "form-control py-2" +
                  (errors.redirectUrlEnglish ? " has-error" : "")
                }
                disabled={!isNewKeyword && !editables.redirectUrlEnglish}
              />
              {this.renderPencilIcon("redirectUrlEnglish")}
            </div>
            <span className="help-block">{errors.redirectUrlEnglish}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="redirectUrlArabic">
              Redirection Link (Arabic)*
            </label>
            <div className="input-group">
              <input
                value={keyword?.redirectUrlArabic || ""}
                onChange={this.handleChange}
                id="redirectUrlArabic"
                name="redirectUrlArabic"
                type="text"
                placeholder="Enter Redirection Link in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.redirectUrlArabic ? " has-error" : "")
                }
                disabled={!isNewKeyword && !editables.redirectUrlArabic}
              />
              {this.renderPencilIcon("redirectUrlArabic")}
            </div>
            <span className="help-block">{errors.redirectUrlArabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="status">Status</label>
            <div className="input-group">
              <Dropdown
                value={keyword?.isActive}
                onChange={(event, value) => {
                  this.setState({
                    keyword: {
                      ...keyword,
                      isActive: value.value,
                    },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewKeyword && !editables.isActive}
                className={
                  "form-control" + (errors.isActive ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("isActive")}
            </div>
            <span className="help-block">{errors.isActive}</span>
          </div>
        </div>
      </div>
    );

    return (
      <>
        <div className="manage-package">
          <div className="page-header">
            <div className="actions">
              <div className="action-item">
                <Search
                  searchExpanded={true}
                  searchTerm={searchTerm || ""}
                  handleSearch={this.handleSearch}
                  clearSearch={this.handleSearchClear}
                  handleSearchExit={this.handleSearchExit}
                  ref={(input) => {
                    this.search = input;
                  }}
                />
              </div>
              <div className="action-item">
                <button
                  style={{ backgroundColor: "#8FD14F" }}
                  className="btn btn-md new-user"
                  onClick={this.handleAddNewBanner}
                  disabled={!isEditable}
                >
                  <i className="fa fa-plus-circle"></i>Add New
                </button>
              </div>
            </div>
          </div>

          <Card body>
            <h3 className="p-head">Popular Search Web</h3>
            <DataTable
              data={filteredKeywords}
              columns={this.columns}
              defaultSortField="id"
              defaultSortAsc={false}
              sortIcon={<ArrowDownward></ArrowDownward>}
              // paginationPerPage={DEFAULT_ROWS_PER_PAGE}
              // paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              highlightOnHover
              // pagination
              responsive
              noHeader={this.state.searchApplied ? false : true}
              title={
                this.state.searchApplied ? (
                  <p className="table-header">
                    {"Found " + filteredKeywords.length + " results"}
                  </p>
                ) : (
                  ""
                )
              }
              noDataComponent={
                this.state.loadError ? (
                  <AlertBox
                    message={this.state.loadError}
                    error={true}
                  ></AlertBox>
                ) : (
                  <AlertBox message="There's nothing here."></AlertBox>
                )
              }
            />
          </Card>
          <Modal
            size="lg"
            show={this.state.isKeywordViewModalVisible}
            onHide={this.hideKeywordViewModal}
            className="user-data-modal"
          >
            <Modal.Header closeButton>
              <ModalTitle>
                <div className="user-title"></div>
                <button
                  onClick={this.handleSaveKeyword}
                  disabled={!isEditable}
                  className="btn modal-save-btn"
                >
                  Save
                </button>
              </ModalTitle>
            </Modal.Header>
            <Modal.Body>
              <Tabs id="tabs_website_categories">
                <Tab eventKey="key_details_form" title="Details">
                  {detailsForm}
                </Tab>
              </Tabs>
            </Modal.Body>
          </Modal>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WebSearch);
