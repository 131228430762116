import API from "utils/API";
import PhoenixAPI from "utils/PhoenixAPI";

const BASE_URL_CUSTOM_PACKAGES = "/packages/";
const BASE_URL_BLOOD_PACKAGES_V2 = "/bloods/packages";
const BASE_URL_MINI_PACKAGES = "/api/v1/mini-packages";
const BASE_URL_MINI_PACKAGE_CITY_CONFIGS = "/api/v1/mini-package-city-configs";
const BASE_URL_SUPPLEMENT_DELIVERYFEE_CONFIG="/supplement-medicine-delivery-charges";

export async function fetchCustomPackages() {
  try {
    const { data: customPackages = [] } = await API.get(
      BASE_URL_CUSTOM_PACKAGES
    );
    return customPackages;
  } catch (error) {
    console.log("Error on fetching custom packages", error);
    throw error;
  }
}

export async function fetchBloodPackages() {
  try {
    const { data: bloodPackages = [] } = await PhoenixAPI.get(
      BASE_URL_BLOOD_PACKAGES_V2
    );
    return bloodPackages;
  } catch (error) {
    console.log("Error on fetching blood packages", error);
    throw error;
  }
}

export async function fetchMiniPackages() {
  try {
    const { data: miniPackages = [] } = await PhoenixAPI.get(
      BASE_URL_MINI_PACKAGES
    );
    return miniPackages;
  } catch (error) {
    console.log("Error on fetching mini packages", error);
    throw error;
  }
}

export async function fetchMiniPackageCityConfig(countryId, cityId) {
  // https://phoenix.bevaleo.dev/api/v1/mini-package-city-configs/country/1/city/2
  if (countryId === null || countryId === undefined) {
    console.log("fetchMiniPackageCityConfig:: Invalid country id!", {
      countryId,
    });
    return null;
  }
  if (cityId === null || cityId === undefined) {
    console.log("fetchMiniPackageCityConfig:: Invalid city id!", { cityId });
    return null;
  }

  try {
    const { data: miniPackageCityConfig } = await PhoenixAPI.get(
      `${BASE_URL_MINI_PACKAGE_CITY_CONFIGS}/country/${countryId}/city/${cityId}`
    );
    return miniPackageCityConfig;
  } catch (error) {
    console.log("Error on fetching mini package city config!", error);
    throw error;
  }
}

export async function fetchSupplementDeliveryFeeConfig(countryId){
  if (countryId === null || countryId === undefined) {
    console.log("supplementDeliveryFeeConfig:: Invalid country id!", {
      countryId,
    });
    return null;
  }
  try {
    const { data: supplementDeliveryFeeConfig } = await PhoenixAPI.get(
      `${BASE_URL_SUPPLEMENT_DELIVERYFEE_CONFIG}/countryId/${countryId}`
    );
    return supplementDeliveryFeeConfig;
  } catch (error) {
    console.log("Error on fetching mini package city config!", error);
    throw error;
  }
}
