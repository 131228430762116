import React, { Component } from "react";
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import CountryDropdown from "components/Common/CountryDropdown";
import Search from "components/Search/Search";
import API from "utils/API";
import AlertBox from "components/AlertBox/AlertBox";
import { Dropdown } from "semantic-ui-react";
import Status from "components/Common/Status";
import PhoenixAPI from "utils/PhoenixAPI";
import { getUserId } from "services/UserService";
import { Page } from "utils/constant";
import { isAccessDenied, isUserSuperAdmin, isUserAdmin } from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";
import { compareStrings } from "utils/commons";
import {
  fetchCoachPackages
  
} from "services/CoachConsultationPackageService";
import PackageRanking from "../../components/Common/PackageRanking";
import SelectCoachPackages from "components/Common/SelectCoachPackages";
const BASE_URL_WEBSITE_CATEGORIES = "web-category/";
const BASE_URL_COUNTRIES = "countries/";
const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];
const TWO_MEGA_BYTE = 2_097_152;
class WebsiteCategories extends Component {
  constructor(props) {
    super(props);

    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      countries: [],
      websiteCategories: [],
      statusOptions: [
        {
          key: true,
          value: true,
          text: "Active",
        },
        {
          key: false,
          value: false,
          text: "Inactive",
        },
      ],
      rankOptions: [],
      websiteCategory: {},
      errors: {},
      editables: {},
      loadError: null,
      searchTerm: null,
      selectedCountry: null,
      showModal: false,
      minRank: 1,
      maxRank: 0,
      packagesRankingWebsite:[],
      coachPackages:[],
      coachPackageOptions:[],
      miniPackageOptions:[
        {
          text: "YES",
          value: true,
          key: "yes",
        },
        {
          text: "NO",
          value: false,
          key: "no",
        },

      ],
    };

    this.columns = [
      {
        name: "ID",
        selector: "id",
        width: "100px",
        sortable: true,
      },
      {
        name: "Name",
        selector: "name",
        wrap: true,
        sortable: true,
      },
      {
        name: "Name (Arabic)",
        selector: "name_arabic",
        wrap: true,
        sortable: true,
        right: true,
        format: (category) => (
          <p style={{ textAlign: "right" }}>{category.name_arabic}</p>
        ),
      },
      {
        name: "Rank",
        selector: "position_value",
        width: "100px",
        sortable: true,
        center: true,
      },
      {
        name: "Status",
        selector: "is_active",
        maxWidth: "150px",
        center: true,
        cell: (category) => <Status isActive={category.is_active} />,
        sortable: true,
      },
      {
        name: "View",
        center: true,
        cell: (category) => this.renderViewButton(category.id),
      },
    ];
  }

  componentDidMount() {
    this.fetchAclUser();
    this.fetchCountries();
    this.fetchWebsiteCategories();
    this.fetchCoachPackages();

  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedCountry, tests } = this.state;
    if (selectedCountry !== prevState.selectedCountry)
      this.createCoachPackageOptionsList();
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if(!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  }

  fetchAclUser = async () => {
    const userId = getUserId();
      if (!userId) {
        console.log("fetchAclUser:: Invalid user id!", {userId});
        return;
      }
  
      try {
        const pageName = Page.WEBSITE_CATEGORIES;
        const { data: aclUser } = await PhoenixAPI.get(`/api/v1/acl/users/userId/${userId}`);
        const pagePermission = aclUser?.pagePermissions?.find(it => pageName?.toLowerCase() === it.pageName?.toLowerCase()) ?? null;
        const pageAccessRights = pagePermission?.pageAccessRights?.filter(it => it.isActive ?? false)?.map(it => it.accessRightName?.toLowerCase()) ?? null;
        const accessDenied = isAccessDenied(aclUser, pageAccessRights);
        this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
          const isEditable = this.hasPageAccessRight("edit");
          this.setState({ isEditable });
        });
      } catch (error) {
        console.log("fetchAclUser:: Error on fetching acl user!", error);
      }
  }

  fetchCountries = async () => {
    const isDefaultCountry = (country) => country.is_default;
    const countryMapper = (country) => ({
      id: country.id,
      country_name: country.country_name,
      currency: country.currency,
      is_active: country.is_active,
      is_default: country.is_default,
    });

    try {
      const { data: allCountries } = await API.get(BASE_URL_COUNTRIES);
      const countries = allCountries ? allCountries.map(countryMapper) : [];
      const defaultCountry = countries.find(isDefaultCountry);

      this.setState({
        countries,
        selectedCountry: defaultCountry,
      });
    } catch (error) {
      console.error(
        "Error on fetching countries from this URL: ",
        BASE_URL_COUNTRIES
      );
      console.error("Error on fetching countries", error);
    }
  };

  fetchWebsiteCategories = async () => {
    try {
      this.showLoading();
      const response = await API.get(BASE_URL_WEBSITE_CATEGORIES);
      const websiteCategories = response.data || [];
      const ranks = websiteCategories
        .filter((it) => it.position_value)
        .map((it) => it.position_value);
      const minRank = Math.min(...ranks) || 0;
      const maxRank = Math.max(...ranks) || 0;

      this.setState({ websiteCategories, minRank, maxRank }, () =>
        this.hideLoading()
      );
    } catch (error) {
      console.error(
        "Error on fetching website categories from this URL: ",
        BASE_URL_WEBSITE_CATEGORIES
      );
      console.error("Error on fetching website categories", error);

      this.hideLoading();
      this.showErrorNotification(
        "Error on loading website categories: ",
        error.message
      );
      this.setState({
        loadError: "Some error has occured. Please try again",
      });
    }
  };

  handleChange = ({ currentTarget: input }) => {
    const websiteCategory = { ...this.state.websiteCategory };
    websiteCategory[input.name] = input.value;
    this.setState({ websiteCategory });
  };

  handleCountrySelection = (selectedCountry) =>
    this.setState({ selectedCountry });

  handleSearch = (queryString) => {
    this.setState({
      searchApplied: true,
      searchTerm: queryString ? queryString : "",
    });
  };

  handleSearchClear = () => {
    this.setState({
      searchTerm: "",
      searchApplied: false,
    });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({
        searchTerm: "",
        searchApplied: false,
      });
  };

  handleAddNew = () => {
    const websiteCategory = {
      is_active: false,
      position_value: this.state.maxRank + 1,
      is_mini_packages_included:false,
      coach_cons_pkgs:[]
    };
    this.setState({ websiteCategory, isNewWebsiteCategory: true }, () =>
      this.showModal()
    );
  };

  

  handleView = async(id) => {
    const websiteCategories = this.state.websiteCategories || [];
    const websiteCategory = websiteCategories.find((it) => it.id === id);
    const coachPackageOptions = this.productNameList();
    console.log("coachpackage options",coachPackageOptions);
    if (websiteCategory) {
      const country = this.findCountry(websiteCategory.country);
      const allWebsitePackages= await this.getAllWebsitePackages(id);
      this.setState(
        {
          isNewWebsiteCategory: false,
          websiteCategory: {
            ...websiteCategory,
            country_name: country ? country.country_name : "",
            currency: country ? country.currency : "",
            request_icon:websiteCategory?.icon,
            coach_cons_pkgs:websiteCategory?.coach_cons_pkgs
          },
          packagesRankingWebsite:allWebsitePackages,
          coachPackageOptions:coachPackageOptions,
        },
        () => this.showModal()
      );
    }
  };

  productNameList=()=>{
    const { coachPackages, selectedCountry } =
      this.state;
    const coachPackagesForCountry =
      coachPackages && selectedCountry
        ? coachPackages.filter((product) => product.countryId === selectedCountry.id)
        : [];

  
      
    const activePackages = coachPackagesForCountry.filter(
      (product) => product.isActive 
    );
    activePackages.sort((p1, p2) => compareStrings(p1.externalName, p2.externalName, true));

    const inactivePackages = coachPackagesForCountry.filter(
      (product) => product.isActive
    );
    inactivePackages.sort((p1, p2) => compareStrings(p1.externalName, p2.externalName, true));

    return activePackages
  }


  createCoachPackageOptionsList = () => {
    const { coachPackages, selectedCountry, isNewPackage } =
      this.state;
    const coachPackagesForCountry =
      coachPackages && selectedCountry
        ? coachPackages.filter((product) => product.countryId === selectedCountry.id)
        : [];

   
      
    const activePackages = coachPackagesForCountry.filter(
      (product) => product.isActive
    );
    activePackages.sort((p1, p2) => compareStrings(p1.name, p2.name, true));

    const inactivePackages = coachPackagesForCountry.filter(
      (product) => product.isActive
    );
    inactivePackages.sort((p1, p2) => compareStrings(p1.name, p2.name, true));

    const noneOption = {
      key: "none",
      value: "none",
      text: "NONE",
    };
    const productOptions = [...activePackages, ...inactivePackages].map((product) => ({
      key: product.id,
      value: product.id,
      text: `${product.externalName}${
        product.status === "Inactive" ? " (Inactive)" : ""
      }`,
    }));
    this.setState({  coachPackageOptions:activePackages});
  };


  handleSave = async () => {
    if (!this.state.selectedCountry) {
      this.showErrorNotification("Please select a country!");
      return;
    }

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }

    const { websiteCategory } = this.state;
    const isValidId =
      websiteCategory.id !== null && websiteCategory.id !== undefined;

    const apiOptions = isValidId
      ? this.createApiOptions(
          "patch",
          `${BASE_URL_WEBSITE_CATEGORIES}${websiteCategory.id}/`
        )
      : this.createApiOptions("post", BASE_URL_WEBSITE_CATEGORIES);

    try {
      await API.request(apiOptions);
      if(isValidId){
        if (this.state.packagesRankingWebsite && this.state.packagesRankingWebsite.length>0) {
          const payload= this.state.packagesRankingWebsite.map((packageRank)=>({"itemType":packageRank.itemType,
          "itemId":packageRank.itemId,
          "rank":packageRank.rank,
          "id":packageRank.id?packageRank.id:null,
          "categoryId":this.state.websiteCategory.id,
          "isActive" : packageRank.isActive,
          "countryId":this.state.selectedCountry.id
      }))
      try {
          if (payload && payload.length > 0) {
      PhoenixAPI.patch("/cms-package-ranking",payload);
          }
          else {
              // Handle the case when payload is empty
              console.error("Payload is empty or invalid");
          }
      }catch (error) {
          // Handle errors from the API call
          console.error("Error while patching data:", error);
      }

  }    
      }
      this.hideLoading();
      this.hideModal();
      this.showSuccessNotification(
        isValidId ? "Updated Successfully." : "Created Successfully."
      );
      this.fetchWebsiteCategories();
    } catch (error) {
      console.log("Error on saving website category", error);
      this.hideLoading();
      this.showErrorNotification("Some error occurred: " + error.message);
    }
  };

  validate = () => {
    const errors = {};
    const { name, name_arabic, position_value, is_active,image } = this.state.websiteCategory;

    if (!name || name.trim() === "")
      errors.name = "Name (English) is a required field!";
    else if (name.length > 100)
      errors.name = "Name (English) can not take more than 100 characters!";

    if (!name_arabic) errors.name_arabic = "Name (Arabic) is a required field!";
    else if (name_arabic.length > 100)
      errors.name_arabic =
        "Name (Arabic) can not take more than 100 characters!";

    if (position_value === null || position_value === undefined)
      errors.rank = "Rank is a required field!";

    if (is_active === null || is_active === undefined)
      errors.status = "Status is a required field!";
      if (image && image.size && image.size > TWO_MEGA_BYTE)
      errors.image = "Image should be less than 2 MB!";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  createApiOptions = (method, url) => {
    const formData = this.createFormData();
    console.log("formData in create",formData);
    return {
      method,
      url,
      data: formData,
      headers: {
        accept: "application/json",
        "Accept-Language": "en-US,en;q=0.8",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    };
  };

  createFormData = () => {
    const { isNewWebsiteCategory, websiteCategory, selectedCountry } =
      this.state;
    const formData = new FormData();
      console.log("website category",websiteCategory.image,websiteCategory.coach_cons_pkgs);
    if (isNewWebsiteCategory)
      formData.append("country", selectedCountry ? selectedCountry.id : null);

    formData.append("name", websiteCategory.name);
    formData.append("name_arabic", websiteCategory.name_arabic);
    formData.append("position_value", websiteCategory.position_value);
    formData.append("is_active", websiteCategory.is_active);
    formData.append("request_icon",websiteCategory.image || "");
    if(websiteCategory.coach_cons_pkgs && websiteCategory.coach_cons_pkgs.length>0){
      const finaltest=websiteCategory.coach_cons_pkgs.filter((it)=>it !== undefined);
      
      finaltest.map((value)=>{formData.append("coach_cons_pkgs",value);});
     }
    formData.append("is_mini_packages_included",websiteCategory.is_mini_packages_included);
    
    return formData;
  };

  showModal = () => {
    const rankOptions = this.createRankOptions();
    this.setState({ rankOptions, showModal: true });
  };

  hideModal = () => {
    this.setState({ showModal: false });
    this.resetForm();
  };

  resetForm = () => {
    this.setState({
      errors: {},
      editables: {},
      websiteCategory: {},
      packagesRankingWebsite:[],
      isNewWebsiteCategory: false,
    });
  };

  createRankOptions = () => {
    const { minRank, maxRank, isNewWebsiteCategory } = this.state;
    if (
      minRank === null ||
      minRank === undefined ||
      maxRank === null ||
      maxRank === undefined
    )
      return;

    const until = isNewWebsiteCategory ? maxRank + 1 : maxRank;
    let rankOptions = [];
    for (let i = minRank; i <= until; i++)
      rankOptions.push({ key: i, value: i, text: i });

    if (rankOptions.length === 0)
      rankOptions.push({ key: 1, value: 1, text: 1 });
    console.log("Rank Options: ", rankOptions);
    return rankOptions;
  };

  findCountry = (id) => {
    const countries = this.state.countries || [];
    return countries.find((country) => country.id === id);
  };

  renderViewButton = (id) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleView(id);
        }}
      >
        View
      </button>
    </div>
  );

  fetchCoachPackages=async () => {
    this.showLoading();
    try {
      const coachPackages = (await fetchCoachPackages()) || [];
      
      this.setState({coachPackages},()=>this.createCoachPackageOptionsList() );
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching coach consultation packages: ",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  handleSelectImage = (e) => {
    if (e.target.files && e.target.files.length) {
      const image = e.target.files[0];
      const websiteCategory = { ...this.state.websiteCategory, image };
      this.setState({ websiteCategory });

      this.readImage(image);
    }
  };
  readImage = (image) => {
    const reader = new FileReader();
    reader.onloadend = function () {
      const websiteCategory= {
        ...this.state.websiteCategory,
        request_icon: [reader.result],
      };
      this.setState({ websiteCategory });
    }.bind(this);
    reader.readAsDataURL(image);
  };

  renderPencilIcon = (fieldName) =>
    this.state.isEditable && !this.state.isNewWebsiteCategory ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  getFilteredWebsiteCategories = () => {
    const { websiteCategories, selectedCountry } = this.state;
    const packagesForCurrentCountry =
      websiteCategories && selectedCountry
        ? websiteCategories.filter((p) => p.country === selectedCountry.id)
        : [];
    return this.getSearchedWebsiteCategories(packagesForCurrentCountry);
  };

  getSearchedWebsiteCategories = (websiteCategories) => {
    const { searchApplied, searchTerm } = this.state;

    if (!searchApplied || !searchTerm) return websiteCategories;

    const newValue = searchTerm.slice(0).trim().toLowerCase();
    return websiteCategories.filter((websiteCategory) => {
      const { id, name, name_arabic, position_value } = websiteCategory;
      return (
        (name && name.toLowerCase().indexOf(newValue) !== -1) ||
        (name_arabic && name_arabic.toLowerCase().indexOf(newValue) !== -1) ||
        (id && id.toString() === newValue) ||
        (position_value && position_value.toString() === newValue)
      );
    });
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  getAllWebsitePackages=async(categoryId)=>{
    
      

       if(categoryId){
       try{

        const response= await PhoenixAPI.get( `cms-package-ranking/category-id/${categoryId}`);
        console.log("allPackages are",response.data);
          return response.data;
       }
       catch(error){
           console.log("Error on fetching package ranking" + error);
       }
   }
}
  

  handleWebsitePackagesUpdated=(allWebsitePackages)=>{
    this.setState({packagesRankingWebsite:allWebsitePackages});
  }

  render() {
    const {
      countries,
      selectedCountry,
      editables,
      errors,
      isNewWebsiteCategory,
      searchTerm,
      websiteCategory,
      isEditable,
    } = this.state;

    const finalList = this.getFilteredWebsiteCategories();
    console.log(finalList);

    const rankForm=(
     <div className="container mt-4">
    <PackageRanking
      packages={this.state.packagesRankingWebsite || []}
      onPackagesUpdated={this.handleWebsitePackagesUpdated}
      isEditable={isEditable}
      type="WebsiteCategory"
    />
  </div>
    );
    

    const detailsForm = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="name">Name (English)*</label>
            <div className="input-group">
              <input
                value={websiteCategory.name}
                onChange={this.handleChange}
                id="name"
                name="name"
                type="text"
                placeholder="Name in English"
                className={
                  "form-control py-2" + (errors.name ? " has-error" : "")
                }
                disabled={!isNewWebsiteCategory && !editables.name}
              />
              {this.renderPencilIcon("name")}
            </div>
            <span className="help-block">{errors.name}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="name_arabic">Name (Arabic)*</label>
            <div className="input-group">
              <input
                value={websiteCategory.name_arabic}
                onChange={this.handleChange}
                id="name_arabic"
                name="name_arabic"
                type="text"
                placeholder="Name in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.name_arabic ? " has-error" : "")
                }
                disabled={!isNewWebsiteCategory && !editables.name_arabic}
              />
              {this.renderPencilIcon("name_arabic")}
            </div>
            <span className="help-block">{errors.name_arabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="rank">Rank*</label>
            <div className="input-group">
              <Dropdown
                value={websiteCategory.position_value}
                onChange={(event, value) => {
                  const category = { ...websiteCategory };
                  category.position_value = value.value;
                  this.setState({ websiteCategory: category });
                }}
                id="rank"
                placeholder="Rank"
                search
                selection
                options={this.state.rankOptions}
                disabled={!isNewWebsiteCategory && !editables.rank}
                className={"form-control" + (errors.rank ? " has-error" : "")}
              />
              {this.renderPencilIcon("rank")}
            </div>
            <span className="help-block">{errors.rank}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="status">Status*</label>
            <div className="input-group">
              <Dropdown
                value={websiteCategory.is_active}
                onChange={(event, value) => {
                  this.setState({
                    websiteCategory: {
                      ...websiteCategory,
                      is_active: value.value,
                    },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={this.state.statusOptions}
                disabled={!isNewWebsiteCategory && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="country">Country</label>
            <input
              value={
                isNewWebsiteCategory
                  ? selectedCountry && selectedCountry.country_name
                  : websiteCategory.country_name
              }
              type="text"
              id="country"
              name="country"
              readOnly
              className={
                "form-control py-2" + (errors.country ? " has-error" : "")
              }
            ></input>
            <span className="help-block">{errors.country}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="currency">Currency</label>
            <input
              value={
                isNewWebsiteCategory
                  ? selectedCountry && selectedCountry.currency
                  : websiteCategory.currency
              }
              type="text"
              id="currency"
              name="currency"
              readOnly
              className="form-control py-2"
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="imageInput">Icon</label>
            <div className="input-group">
              <input
                onChange={this.handleSelectImage}
                id="imageInput"
                name="image"
                type="file"
                accept="image/*"
                disabled={!isNewWebsiteCategory && !editables.image}
                className={"form-control" + (errors.image ? " has-error" : "")}
              />
              {this.renderPencilIcon("image")}
            </div>
            <span className="help-block">{errors.image}</span>
            
          </div>
          <div className="form-group col">
            {websiteCategory?.request_icon&& (
              <>
                <label htmlFor="selected_package_image">
                  Selected Icon
                </label>
                <div>
                  <img
                    id="selected_package_image"
                    name="selected_package_image"
                    src={websiteCategory?.request_icon}
                    alt="Selected"
                    className="img-thumbnail mx-auto"
                    style={{
                      width: "100%",
                      maxHeight: "200px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div className="row">
        <div className="form-group col">
             <label> Coach Consultation Packages</label>
             <div className="input-group">
             <SelectCoachPackages
              categories={this.state.coachPackageOptions??[]}
              selectedCategories={websiteCategory.coach_cons_pkgs??[]}
              onSelect={(values) => {
                this.setState({
                  websiteCategory: {
                    ...websiteCategory,
                    coach_cons_pkgs: values.map((it) => it.value),
                  }
                })
              }}
              isDisabled={!isNewWebsiteCategory && !editables.coach_packages}
                className={
                  "form-control" + (errors.coach_packages ? " has-error" : "")
                }
                menuPlacement={"top"}
                
             />
               {this.renderPencilIcon("coach_packages")}
             </div>
             <span className="help-block">{errors.coach_packages}</span>
          </div>
          <div className="form-group col-6">
            
            <label htmlFor="is_minipackage_include"> Does this category have minipackage?</label>
            <div className="input-group">
              <Dropdown
                value={websiteCategory.is_mini_packages_included}
                onChange={(event, value) => {
                  this.setState({  websiteCategory: {
                    ...websiteCategory,is_mini_packages_included: value.value} });
                }}
                id="is_mini_packages_included"
                placeholder="minipackage included"
                search
                selection
                options={this.state.miniPackageOptions}
                disabled={!isNewWebsiteCategory && !editables.is_mini_packages_included}
                className={
                  "form-control" + (errors.is_mini_packages_included ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("is_mini_packages_included")}
            </div>
              
            
            <span className="help-block">{errors.is_mini_packages_included}</span>
          </div>
          </div>
      </div>
    );

    if (this.state.accessDenied) {
      return <AccessDenied />
    }

    return (
      <div className="manage-package">
        <CountryDropdown
          countries={countries || []}
          selectedCountry={selectedCountry}
          onSelect={this.handleCountrySelection}
        />
        <div className="page-header">
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={searchTerm}
                handleSearch={this.handleSearch}
                clearSearch={this.handleSearchClear}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              ></Search>
            </div>
            <div className="action-item">
              <button
                style={{ backgroundColor: "#8FD14F" }}
                className="btn btn-md new-user"
                onClick={this.handleAddNew}
                disabled={!isEditable}
              >
                <i className="fa fa-plus-circle"></i>Add New
              </button>
            </div>
          </div>
        </div>

        <Card body>
          <DataTable
            data={finalList}
            columns={this.columns}
            defaultSortField="id"
            defaultSortAsc={false}
            sortIcon={<ArrowDownward></ArrowDownward>}
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            highlightOnHover
            pagination
            responsive
            noHeader={this.state.searchApplied ? false : true}
            title={
              this.state.searchApplied ? (
                <p className="table-header">
                  {"Found " + finalList.length + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              this.state.loadError ? (
                <AlertBox
                  message={this.state.loadError}
                  error={true}
                ></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>

        <Modal
          size="lg"
          show={this.state.showModal}
          onHide={this.hideModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
            <div className="user-title"></div>
            <button onClick={this.handleSave} disabled={!isEditable} className="btn modal-save-btn">
              Save
            </button>
          </Modal.Header>
          <Modal.Body>
            <Tabs id="tabs_website_categories">
              <Tab eventKey="key_details_form" title="Details">
                {detailsForm}
              </Tab>
           { !isNewWebsiteCategory?  <Tab eventKey="rank_form" title="Ranking">
                {rankForm}
              </Tab>:<></>}
            </Tabs>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WebsiteCategories);
