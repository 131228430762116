import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import PhoenixAPI from "utils/PhoenixAPI";
import { Card } from "react-bootstrap";
import {
  bookingStatus,
  findBookingStatusByValue,
} from "pages/HomeCarePortal/bookingStatus";
import { formatDate, formatSingleTimeslot } from "utils/commons";

HomecareInformationCard.propTypes = {
  orderId: PropTypes.number.isRequired,
};

function HomecareInformationCard({ orderId }) {
  const [homecareInfo, setHomecareInfo] = useState(null);

  useEffect(() => {
    const fetchHomecareInfo = async () => {
      if (!orderId) return;
      try {
        const { data } = await PhoenixAPI.get(
          `/assign-nurse/orderId/${orderId}`
        );
        setHomecareInfo(data);
      } catch (error) {
        console.log("Error fetching homecare info! error: ", error);
      }
    };
    fetchHomecareInfo();
  }, [orderId]);

  const {
    whatsapp_number,
    professional_name,
    nurse_appointment_status,
    homecare_name,
    phone_number,
    delivery_date,
    delivery_time,
    delivered_to,
    temperature,
    lab_referral_code,
    picture_of_sample,
    homecare_payment_status,
  } = homecareInfo ?? {};

  const status = findBookingStatusByValue(nurse_appointment_status);

  const isProfessionalDetailsVisible =
    status && status.position >= bookingStatus.NURSE_ACKNOWLEDGED.position;
  const isSampleDetailsVisible =
    status && status.position >= bookingStatus.SAMPLE_DELIVERED.position;

  const homecareInfoDetails = (
    <>
      <div className="row mx-2 pb-2">
        <div className="col">Status: {nurse_appointment_status ?? ""}</div>
      </div>
      <div className="row mx-2 pb-2">
        <div className="col">HomeCare: {homecare_name ?? ""}</div>
        <div className="col">
          Nurse Name:{" "}
          {isProfessionalDetailsVisible ? professional_name ?? "" : ""}
        </div>
      </div>
      <div className="row mx-2 pb-2">
        <div className="col">
          Phone Number: {isProfessionalDetailsVisible ? phone_number ?? "" : ""}
        </div>
        <div className="col">
          WhatsApp Number:{" "}
          {isProfessionalDetailsVisible ? whatsapp_number ?? "" : ""}
        </div>
      </div>
      <div className="row mx-2 pb-2">
        <div className="col">
          Delivery Date:{" "}
          {isSampleDetailsVisible && delivery_date
            ? formatDate(delivery_date)
            : ""}
        </div>
        <div className="col">
          Delivery Time:{" "}
          {isSampleDetailsVisible && delivery_time
            ? formatSingleTimeslot(delivery_time)
            : ""}
        </div>
      </div>
      <div className="row mx-2 pb-2">
        <div className="col">
          Name of the person they’ve delivered to:{" "}
          {isSampleDetailsVisible && delivered_to ? delivered_to : ""}
        </div>
        <div className="col">
          Lab Bar/Referral Code:{" "}
          {isSampleDetailsVisible && lab_referral_code ? lab_referral_code : ""}
        </div>
      </div>
      <div className="row mx-2 pb-2">
        <div className="col">
          Temperature:{" "}
          {isSampleDetailsVisible && temperature ? temperature : ""}
        </div>
        <div className="col">
          Picture of Sample:{" "}
          {isSampleDetailsVisible && picture_of_sample ? (
            <div>
              <img
                id="picture_of_sample"
                name="picture_of_sample"
                src={picture_of_sample}
                alt="Selected"
                className="img-thumbnail mx-auto"
                style={{
                  width: "100%",
                  maxHeight: "200px",
                  objectFit: "cover",
                }}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="row mx-2 pb-2">
        <div className="col">
          Payment Status:{" "}
          {isSampleDetailsVisible && homecare_payment_status ? homecare_payment_status : ""}
        </div>
      </div>
    </>
  );

  return (
    <Card className="px-0 py-0 mb-0">
      <Card.Body className="px-0 py-0">
        <div className="row ml-1 my-2">
          <div className="col">
            <p className="h6">HomeCare Information</p>
          </div>
        </div>
        {homecareInfoDetails}
      </Card.Body>
    </Card>
  );
}

export default HomecareInformationCard;
