import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Card from "react-bootstrap/Card";
import DataTable from "react-data-table-component";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import SelectHomecareCategories from "./SelectHomecareCategories";
import _ from "lodash";

const HOMECARE_CATEGORY_OPTIONS = [
  {
    id: 1,
    value: "Blood_Package",
    label: "Blood Package",
  },
  {
    id: 2,
    value: "Dna_Package",
    label: "Dna Package",
  },
  {
    id: 3,
    value: "Functional_Package",
    label: "Functional Package",
  },
  {
    id: 4,
    value: "Intolerance_And_Allergy_Package",
    label: "Intolerance And Allergy Package",
  },
  {
    id: 5,
    value: "IV_Package",
    label: "IV Package",
  },
  {
    id: 6,
    value: "Other_Custom_Package",
    label: "Other Custom Package",
  },
  {
    id: 7,
    value: "Physiotherapy_Package",
    label: "Physiotherapy Package",
  },
  {
    id: 8,
    value: "Mini_Package",
    label: "Mini Package",
  },
];

class HomecareBasketsForm extends Component {
  constructor(props) {
    super(props);

    const {
      homeacareServices = [],
      questions = [],
      onQuestionUpdated = (f) => f,
    } = this.props;

    this.onQuestionUpdated = onQuestionUpdated;

    this.state = {
      questions,
      question: {},
      errors: {},
      editables: {},
      selectedCategoryIds: [],
      sortDirection: "asc",
    };
  }

  clearForm = () => {
    this.setState({
      errors: {},
      editables: {},
      question: {},
      selectedCategoryIds: [],
    });
  };

  renderViewButton = (id) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleEdit(id);
        }}
      >
        View
      </button>
    </div>
  );
  renderCategoryName = (question) => {

    return question?.categories ??"";
  };

  columns = () => {
    return [
      {
        name: "ID",
        selector: "id",
        sortable: true,
      },
      {
        name: "Homecare",
        selector: "homecareName",
        sortable: true,
        wrap: true,
        format: (question) => (
          <div className="text-overflow-ellipsis">
            {question.homecareName ?? ""}
          </div>
        ),
      },
      {
        name: "Homecare Category",
        selector: "categories",
        wrap: true,
        minWidth: "200px",
        center: true,
        cell: (order) => this.renderCategoryName(order),
      },
      {
        name: "Actions",
        center: true,
        cell: (question) => this.renderViewButton(question.id),
      },
    ];
  };

  handleChange = ({ currentTarget: input }) => {
    const question = { ...this.state.question };
    question[input.name] = input.value;
    this.setState({ question });
  };

  handleEdit = (id) => {
    this.clearForm();
    const { questions = [] } = this.state;
    const question = questions.find((it) => it.id === id);
    if (!question) return;

    const { categories } = question;
    const categoryValues = categories.split(", ");

    // Filter and map to get the ids
    const selectedCategoryIds = HOMECARE_CATEGORY_OPTIONS.filter((option) =>
      categoryValues.includes(option.value)
    ).map((option) => option.id);

    this.setState({ question, selectedCategoryIds }, () =>
      this.showAddEditModal()
    );
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }

    const { question = {} } = this.state;
    if (question.id === null || question.id === undefined)
      this.createQuestion();
    else this.updateQuestion(question.id);
    this.hideAddEditModal();
  };

  validate = () => {
    const errors = {};

    return Object.keys(errors).length === 0 ? null : errors;
  };

  createQuestion = () => {
    const { questions = [], question, selectedCategoryIds } = this.state;
    const ids = questions.length > 0 ? questions.map((it) => it.id) : null;
    question.id = ids ? Math.max(...ids) + 1 : 1;

    question.is_new = true;
    if (selectedCategoryIds.length > 0) {
      const categoryNames = HOMECARE_CATEGORY_OPTIONS.filter((option) =>
        selectedCategoryIds.includes(option.id)
      )
        .map((option) => option.value)
        .join(", ");
      question.categories = categoryNames;
    }
    this.setState({ questions: [...questions, question] }, () => {
      this.showSuccessNotification("Homecare is Added Successfully!");
      this.onQuestionUpdatedLocally();
    });
  };

  updateQuestion = (id) => {
    const { questions = [], question, selectedCategoryIds } = this.state;
    if (selectedCategoryIds.length > 0) {
      const categoryNames = HOMECARE_CATEGORY_OPTIONS.filter((option) =>
        selectedCategoryIds.includes(option.id)
      )
        .map((option) => option.value)
        .join(", ");
      question.categories = categoryNames;
    }

    const newQuestions = questions.map((it) => (it.id === id ? question : it));
    this.setState({ questions: newQuestions }, () => {
      this.showSuccessNotification("Homecare is Updated Successfully!");
      this.onQuestionUpdatedLocally();
    });
  };

  onQuestionUpdatedLocally = () => {
    this.clearForm();
    this.onQuestionUpdated(
      this.state.questions.map((question) => {
        const q = { ...question };
        if (q.is_new) {
          delete q.id;
          delete q.is_new;
        }
        return q;
      })
    );
  };

  handleAddNewQuestion = () => {
    const question = {};
    this.setState({ question }, () => this.showAddEditModal());
  };

  showAddEditModal = () => {
    this.setState({ isAddEditModalVisible: true });
  };

  hideAddEditModal = () => {
    this.clearForm();
    this.setState({ isAddEditModalVisible: false });
  };

  createHomeCareServiceOption = (professional) => {
    return {
      key: professional.homecareId,
      value: professional.homecareId,
      label: professional.homecareName,
    };
  };

  renderPencilIcon = (fieldName) =>
    this.state.question.id ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  render() {
    const {
      questions = [],
      question = {},
      errors,
      editables,
      sortColumn,
      selectedCategoryIds,
    } = this.state;

    const isNewQuestion = question.id === null || question.id === undefined;

    const selectStyles = {
      container: (base) => ({
        ...base,
        flex: 1,
      }),
    };
    const form = (
      <>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="homecareName">Select HomeCare Service</label>
            <div className="input-group">
              <Select
                value={question && this.createHomeCareServiceOption(question)}
                onChange={(option) =>
                  this.setState({
                    question: {
                      ...question,
                      homecareId: option.value,
                      homecareName: option.label,
                    },
                  })
                }
                options={this.props.homeacareServices}
                styles={selectStyles}
                placeholder={"Select HomeCare service"}
                isDisabled={!isNewQuestion && !editables.homecareName}
              />
              {this.renderPencilIcon("homecareName")}
            </div>
          </div>
          <div className="form-group col">
            <label htmlFor="homeCareName">Select Category</label>
            <div className="input-group">
              <SelectHomecareCategories
                categories={HOMECARE_CATEGORY_OPTIONS ?? []}
                selectedCategoryIds={selectedCategoryIds ?? []}
                onSelect={(values) => {
                  this.setState({
                    selectedCategoryIds: values.map((it) => it.value),
                  });
                  console.log(this.state.selectedLabList);
                }}
                isDisabled={!isNewQuestion && !editables.homecareCategory}
                className={
                  "form-control" + (errors.homecareCategory ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("homecareCategory")}
            </div>
          </div>
        </div>
      </>
    );

    return (
      <div className="container mt-4 px-0">
        <div className="row">
          <div className="form-group col">
            <Card>
              <DataTable
                highlightOnHover
                columns={this.columns() || []}
                data={questions || []}
                onSort={(sortColumn, sortDirection) =>
                  this.setState({
                    sortColumn: sortColumn.selector,
                    sortDirection,
                  })
                }
                pagination
                responsive
                defaultSortField={sortColumn}
                sortIcon={<ArrowDownward></ArrowDownward>}
              />

              <button
                onClick={this.handleAddNewQuestion}
                disabled={!this.props.isEditable}
                className="btn btn-success mt-1"
              >
                Add Homecare
              </button>
            </Card>
          </div>
        </div>
        <Modal
          size="lg"
          show={this.state.isAddEditModalVisible}
          onHide={this.hideAddEditModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {question.id === null || question.id === undefined
                ? "Add Homecare"
                : "Edit Homecare"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{form}</Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary rounded-pill px-4 py-2"
              onClick={this.hideAddEditModal}
            >
              Cancel
            </button>
            <button
              onClick={this.handleSubmit}
              className="btn btn-lg btn-success rounded-pill px-4 py-2"
            >
              {question.id ? "Save" : "Add"}
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

HomecareBasketsForm.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.object).isRequired,
  onQuestionUpdated: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
};

HomecareBasketsForm.defaultProps = {
  questions: [],
  onQuestionUpdated: (f) => f,
  isEditable: true,
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomecareBasketsForm);
