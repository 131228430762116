export const internalCategoryOptions= [
    {
      key: "None",
      value: "None",
      text: "None",
    },
    {
      key: "Fasting BT",
      value: "Fasting BT",
      text: "Fasting BT",
    },
    {
      key: "Non Fasting BT",
      value: "Non Fasting BT",
      text: "Non Fasting BT",
    },
    {
      key: "Mini Packages",
      value: "Mini Packages",
      text: "Mini Packages",
    },
    {
      key: "PCR",
      value: "PCR",
      text: "PCR",
    },
    {
      key: "IV",
      value: "IV",
      text: "IV",
    },
    {
      key: "Physio",
      value: "Physio",
      text: "Physio",
    },
    {
      key: "DNA",
      value: "DNA",
      text: "DNA",
    },
    {
      key: "Functional Test",
      value: "Functional Test",
      text: "Functional Test",
    },
    {
      key: "Injection",
      value: "Injection",
      text: "Injection",
    },
    
    {
      key: "STD",
      value: "STD",
      text: "STD",
    },
    {
      key: "Supplements",
      value: "Supplements",
      text: "Supplements",
    },
    {
      key:"Gift Card",
      value:"Gift Card",
      text:"Gift Card"
    },
    {
      key:"Coach Consultation",
      value:"Coach Consultation",
      text:"Coach Consultation"
    },
    {
      key:"Medical Services",
      value:"Medical Services",
      text:"Medical Services"
    },
    {
      key:"Medicine",
      value:"Medicine",
      text:"Medicine"
    },
    {
      key:"Dummy Order",
      value:"Dummy Order",
      text:"Dummy Order"
    }
  ];

  export default internalCategoryOptions;

