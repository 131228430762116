import axios from "axios";
import { getUserId, getUserEmail } from "services/UserService";
const userId= getUserId();

const userEmail = getUserEmail();
const instance = axios.create({
  // baseURL:
  //   process.env.NODE_ENV === "development"
  //     ? process.env.REACT_APP_PHOENIX_QA_URL
  //     : process.env.REACT_APP_PHOENIX_PRODUCTION_URL,
  baseURL: process.env.REACT_APP_API_PHOENIX_URL,
  responseType: "json",
  timeout: 300000,
  headers: {
    'adminloginuserid': userId,
    'adminloginuseremail': userEmail,
  },
});

export default instance;
