import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import SelectCoupon from "../../SelectCoupon";
import PhoenixAPI from "utils/PhoenixAPI";
import { Dropdown } from "semantic-ui-react";

  const PAYMENT_MODE_OPTIONS = [
    {
      key: "CARD",
      value: "CARD",
      text: "Card",
    },
    {
      key: "APPLE_PAY",
      value: "APPLE_PAY",
      text: "Apple Pay",
    },
    {
      key: "COD",
      value: "COD",
      text: "COD",
    },
  ];
const PAYMENT_GATEWAY_OPTIONS = [
  {
    key: "MAMOPAY",
    value: "MAMOPAY",
    text: "Mamopay",
  }, 
  {
    key: "COD",
    value: "COD",
    text: "COD",
  },
];


function PaymentInfoDraftOrder(props) {
  const {
    cartItems = [],
    coupons = [],
    country,
    city,
    userId,
    onDiscountDetailsUpdated,
    errors = {},
    clientDetails = [],
    disableFlag=false,
    nationality=""
  } = props;

  
  const [discountDetails, setDiscountDetails] = useState(props.discountDetails);
  const [walletDetails, setWalletDetails] = useState(null);
  const isCartHasAnySubscriptionOrder = cartItems.some((it) => it.isSubscriptionOrder || false);
  const isMiniPackageOrder= cartItems.some((it) => it.itemType==="Mini_Package" || false);
  const [editables,setEditables]=useState({})

  useEffect(() => {
    const fetchWalletDetails = async () => {
      try {
        props.toggleLoading({ isLoading: true });
        const { data: walletDetails } = await PhoenixAPI.get(`/api/v1/wallets/customer/${userId}?country=${country.id}`)
        setWalletDetails(walletDetails);

        const newDiscountDetails = { ...discountDetails };
        newDiscountDetails.walletDetails = walletDetails;
        setDiscountDetails(newDiscountDetails);
        onDiscountDetailsUpdated(newDiscountDetails);
      } catch (error) {
        console.log("fetchWalletDetails:: Error on fetching wallet details!", error);
      } finally {
        props.toggleLoading({ isLoading: false });
      }
    }
    if (userId && country && country.id) {
      fetchWalletDetails();
    }
  }, [userId, country]);

  const setEditable = (field) => {
    const edit={...editables}
    edit[field] = true;
    setEditables(edit);
  };

 const  renderPencilIcon = (fieldName) =>
  (
    <div
      onClick={() => setEditable(fieldName)}
      style={{ cursor: "pointer" }}
      className="input-group-append"
    >
      <i className="input-group-text fa fa-pencil"></i>
    </div>
  );
 

  const showNotification = (notificationMessage, isSuccessMessage) =>
    props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  const showSuccessNotification = (notificationMessage) =>
    showNotification(notificationMessage, true);

  const showErrorNotification = (notificationMessage) =>
    showNotification(notificationMessage, false);

  const handleChange = ({ currentTarget: input }) => {
    const numberInputs = ["deliveryCharges", "nurseVisitFee"];
    const newDiscountDetails = { ...discountDetails };
    if (numberInputs.includes(input.name)){

      newDiscountDetails[input.name] = input.value ? Number(input.value) : "";
  
        newDiscountDetails.finalAmount =

        newDiscountDetails.totalAmount  + newDiscountDetails.deliveryCharges+ newDiscountDetails.nurseVisitFee-Number(newDiscountDetails.additionalDiscount?newDiscountDetails.additionalDiscount:0)+newDiscountDetails.vatAmount;

        newDiscountDetails.finalAmount=newDiscountDetails.finalAmount<0?0:Number(newDiscountDetails.finalAmount.toFixed(2));
    }
    if(input.name=="walletAmount")
    { newDiscountDetails[input.name] = input.value;
      newDiscountDetails.finalAmount =

    newDiscountDetails.totalAmount-newDiscountDetails.discount + newDiscountDetails.deliveryCharges+ newDiscountDetails.nurseVisitFee-Number(newDiscountDetails.additionalDiscount?newDiscountDetails.additionalDiscount:0)+newDiscountDetails.vatAmount;

    newDiscountDetails.finalAmount=newDiscountDetails.finalAmount<0?0:Number(newDiscountDetails.finalAmount.toFixed(2));}
    
    
    setDiscountDetails(newDiscountDetails);
    onDiscountDetailsUpdated(newDiscountDetails);
  };
  const handleChangeDiscountNote= ({ currentTarget: input }) => {
    const newDiscountDetails = { ...discountDetails };
  
    newDiscountDetails.additionalDicountNote = input.value;
   
    setDiscountDetails(newDiscountDetails);
    onDiscountDetailsUpdated(newDiscountDetails);
  };
  const handleAdditionalDiscount=({currentTarget:input})=>{
    const newDiscountDetails={...discountDetails};
    
    console.log("type of additional discount",typeof(input.value))
    newDiscountDetails[input.name] =input.value? Number(input.value):null;
    if(Number(input.value)>=0){
     
      newDiscountDetails.finalAmount =

      newDiscountDetails.totalAmount-newDiscountDetails.discount + newDiscountDetails.deliveryCharges + newDiscountDetails.nurseVisitFee-Number(newDiscountDetails.additionalDiscount)+newDiscountDetails.vatAmount;

      newDiscountDetails.finalAmount=newDiscountDetails.finalAmount<0?0:Number(newDiscountDetails.finalAmount.toFixed(2));
      setDiscountDetails(newDiscountDetails);
      onDiscountDetailsUpdated(newDiscountDetails);
    }
    

  }

  const handleChangeFinalAmount=({currentTarget: input})=>{
    const newDiscountDetails = { ...discountDetails };
      
      newDiscountDetails[input.name] = input.value ? Number(input.value) : "";
    
    setDiscountDetails(newDiscountDetails);
    onDiscountDetailsUpdated(newDiscountDetails);
  }


  const handlePaymentModeChange = (paymentMode) => {
    const newDiscountDetails = { ...discountDetails };
    newDiscountDetails.paymentMode = paymentMode;
    setDiscountDetails(newDiscountDetails);
    onDiscountDetailsUpdated(newDiscountDetails);
  };

  const handlePaymentGatewayChange = (paymentGateway) => {
    const newDiscountDetails = { ...discountDetails };
    newDiscountDetails.paymentGateway = paymentGateway;
   

    setDiscountDetails(newDiscountDetails);
    onDiscountDetailsUpdated(newDiscountDetails);
  };

  const applyCoupon = async (couponCode) => {
    const itemMapper = (item) => ({ id: item.itemId, quantity: item.quantity });
    const bloodPackages = cartItems
      .filter((it) => it.itemType === "Blood_Package")
      .map(itemMapper);
    const coachPackages = cartItems
      .filter((it) => it.itemType === "Coach_Package")
      .map(itemMapper);
    const customPackages = cartItems
      .filter((it) => it.itemType === "Custom_Package")
      .map(itemMapper);
    const bundles = cartItems
      .filter((it) => it.itemType === "Bundle")
      .map(itemMapper);
    const medicines = cartItems
      .filter((it) => it.itemType === "Medicine")
      .map(itemMapper);
    const supplements = cartItems
      .filter((it) => it.itemType === "Supplement")
      .map(itemMapper);
    const cartItemMiniPackage = cartItems.find(it => it.itemType === "Mini_Package");
    const miniPackage = cartItemMiniPackage && cartItemMiniPackage.miniPackages
      ? cartItemMiniPackage.miniPackages.map((item) => ({ id: item, quantity: 1 }))
      : [];

  
        const addOnMiniPackageIds = [...new Set(cartItems.flatMap(item => item.miniPackages ?? []))];
   
        if(addOnMiniPackageIds.length>0){
          addOnMiniPackageIds.forEach((item)=>{
            miniPackage.push({id:item, quantity:1})
          })
        }
      
        const uniqueIDsSet = new Set();

        // Create a  new MinPackages array with unique IDs
        const newMiniPackagesArray = miniPackage.length>0 && miniPackage.filter(item => {
            if (!uniqueIDsSet.has(item.id)) {
                uniqueIDsSet.add(item.id);
                return true;
            }
            return false;
        });
        
        console.log(newMiniPackagesArray);
        const totalCartPrice = cartItems
        // .map((cartItem) => {
        //   const { quantity, price } = cartItem;
        //   return quantity * price;
        // })
        .map((cartItem) => cartItem.totalPrice ?? 0)
        .reduce((total, current) => total + current, 0)
        const couponCodeOrders = cartItems.map(item => ({
          orderItemType: item.itemType,
          orderItemId: item.itemId,
          packagePrice: item.price,
          addOnPrice: item?.miniPackagePrice ??0,
          couponCode: couponCode, // Assuming this is a constant value
          countryId: country.id, // Assuming this is a constant value
          totalCartPrice,
          quantity: item.quantity
      }));

    const payload = {
      couponCode,
      bloodPackages:
        bloodPackages && bloodPackages.length ? bloodPackages : null,
      coachPackages:
        coachPackages && coachPackages.length ? coachPackages : null,
      customPackages:
        customPackages && customPackages.length ? customPackages : null,
      bundles: bundles && bundles.length ? bundles : null,
      medicines: medicines && medicines.length ? medicines : null,
      supplements: supplements && supplements.length ? supplements : null,
      miniPackage: (newMiniPackagesArray && newMiniPackagesArray.length>0? newMiniPackagesArray:null),
      userId,
      country: country.id,
      cityId:city.id,
      isCityLevelPricing:true,
      couponOrders :couponCodeOrders.length>0?couponCodeOrders:[]
    };
    try {
      props.toggleLoading(true);
      const response = await PhoenixAPI.post("apply-coupon-cart/", payload);
      const { data } = response;
      const { couponApplied, couponCode, discount, reason, cashback, childCouponDiscount=[]} = data;
      if (discount > 0) {
        const newDiscountDetails = { ...discountDetails };
        newDiscountDetails.couponApplied = true;
        newDiscountDetails.discount = discount;
        newDiscountDetails.couponCode = couponCode;
        newDiscountDetails.finalAmount =

          newDiscountDetails.totalAmount - discount + newDiscountDetails.deliveryCharges+ newDiscountDetails.nurseVisitFee-Number(newDiscountDetails.additionalDiscount?newDiscountDetails.additionalDiscount:0);

          newDiscountDetails.finalAmount=newDiscountDetails.finalAmount<0?0:Number(newDiscountDetails.finalAmount.toFixed(2));
          newDiscountDetails.childCouponDiscount=childCouponDiscount ??[];
          let modifiedCartItems=cartItems.map(itemH => {
            const matchingGItem = childCouponDiscount.length>0 ?childCouponDiscount.find(itemG => itemG.orderItemId === itemH.itemId):[];
            if (matchingGItem) {
                return {
                    ...itemH,
                    price:itemH.price-matchingGItem.childOrderDiscount,
                    couponDiscount: matchingGItem.childOrderDiscount ??0
                };
            } else {
                return itemH;
            }
        });
        let modifiedWithoutSupplementsAndMedicineItems=modifiedCartItems.filter((it) => (it.itemType!== "Medicine") &&( it.itemType !== "Supplement"))
        let modifiedAllCartItemsPrice=modifiedCartItems.map((cartItem) => cartItem.price ?? 0)
        .reduce((total, current) => total + current, 0)

        const totalModifiedCartPrice = modifiedWithoutSupplementsAndMedicineItems
        // .map((cartItem) => {
        //   const { quantity, price } = cartItem;
        //   return quantity * price;
        // })
        .map((cartItem) => cartItem.price ?? 0)
        .reduce((total, current) => total + current, 0)

        
          let nationalityTaxAmount=0;
           const vatPercentage = country
           ? Number(country.vat_percentage)
           : null;
           if(nationality==="expats" && country.country_name==="Kingdom of Saudi Arabia"){
           
             nationalityTaxAmount=totalModifiedCartPrice*vatPercentage/100;
             
            
            newDiscountDetails.vatAmount=Number(nationalityTaxAmount.toFixed(2));
              
           newDiscountDetails.finalAmount= newDiscountDetails.finalAmount<0?0:Number(newDiscountDetails.finalAmount.toFixed(2))+newDiscountDetails.vatAmount;

           
            console.log("nationality expats :", newDiscountDetails, totalModifiedCartPrice, newDiscountDetails.finalAmount );
           }
           else if(nationality==="saudi" && country.country_name==="Kingdom of Saudi Arabia"){
            nationalityTaxAmount=0
            newDiscountDetails.vatAmount=nationalityTaxAmount;
            console.log("nationality saudi:", newDiscountDetails);
           }
         
          setDiscountDetails(newDiscountDetails);
        onDiscountDetailsUpdated(newDiscountDetails);
        showSuccessNotification("Coupon Applied Successfully!");
      } 
      else if(cashback>0){
        console.log("cashback",cashback);
        const newDiscountDetails = { ...discountDetails };
        newDiscountDetails.couponApplied = true;
        newDiscountDetails.discount=discount;
        newDiscountDetails.cashbackAmount=cashback;
        newDiscountDetails.couponCode = couponCode;
        newDiscountDetails.finalAmount =

          newDiscountDetails.totalAmount - discount + newDiscountDetails.deliveryCharges+ newDiscountDetails.nurseVisitFee-Number(newDiscountDetails.additionalDiscount?newDiscountDetails.additionalDiscount:0)+newDiscountDetails.vatAmount;


          newDiscountDetails.finalAmount=newDiscountDetails.finalAmount<0?0:Number(newDiscountDetails.finalAmount.toFixed(2));
          newDiscountDetails.childCouponDiscount=childCouponDiscount ??[]
        setDiscountDetails(newDiscountDetails);
        onDiscountDetailsUpdated(newDiscountDetails);
        showSuccessNotification("Coupon Applied Successfully!");
           
      }

      else {
        showErrorNotification("Coupon not applied " + reason);
      }
      console.log("Response: ", response);
    } catch (error) {
      console.log("Error on applying coupon", error);
      showErrorNotification("Error on applying coupon " + error.message);
    } finally {
      props.toggleLoading(false);
    }
  };

  const handleCouponSelect = (couponCode) => {
    applyCoupon(couponCode);
  };

  return (
    <div className="container">
      <div className="row">
        <div className="form-group col">
          <label htmlFor="coupon">Coupon</label>
          <SelectCoupon
            coupons={coupons ?? []}
            selectedCouponCode={discountDetails?.couponCode ?? null}
            onCouponSelect={handleCouponSelect}
            isDisabled={disableFlag}
          />
        </div>
        <div className="form-group col">
          <label htmlFor="discount">Discount</label>
          <input
            value={`${country?.currency} ${discountDetails?.discount}`}
            type="text"
            id="discount"
            name="discount"
            className="form-control py-2"
            readOnly={true}
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-6">
          <label htmlFor="walletAmount">Wallet Balance</label>
          <div className="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">{walletDetails?.country?.currency ?? ""}</span>
            </div>
            <input
              value={walletDetails?.balance}
              type="number"
              id="walletAmount"
              name="walletBalance"
              className="form-control py-2"
              readOnly={true}
            />
          </div>
        </div>
        <div className="form-group col">
          <label htmlFor="discount">Additional Discount</label>
          <div className="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">{country?.currency ?? ""}</span>
            </div>
          <input
            value={discountDetails?.additionalDiscount??null}
            onChange={handleAdditionalDiscount}
            type="number"
            id="additionalDiscount"
            name="additionalDiscount"
            
            onKeyPress={(event) => {
              if (!/[0-9,.]*$/.test(event.key)) event.preventDefault();
            }}
            className="form-control py-2"
            
          />
        </div>
        <span className="help-block">{errors.additionalDiscount ?? ""}</span>
      </div>


      </div>
      <div className="row">
        <div className="form-group col-6">
          <label htmlFor="walletAmount">Wallet Amount</label>
          <div className="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">{country?.currency ?? ""}</span>
            </div>
            <input
              value={discountDetails?.walletAmount}
              onChange={handleChange}
              type="number"
              id="walletAmount"
              name="walletAmount"
              className="form-control py-2"
              min={0}
              max={walletDetails?.balance ?? 0}
              disabled={!walletDetails || walletDetails.balance === 0 || disableFlag}
            />
          </div>
          <span className="help-block">{errors.walletAmount ?? ""}</span>
        </div>
        <div className="form-group col-6">
          <label htmlFor="deliveryCharges">Delivery Charges</label>
          <div className="input-group">
          <div class="input-group-prepend">
              <span class="input-group-text">{country?.currency ?? ""}</span>
            </div>
          <input
            value={`${discountDetails?.deliveryCharges}`}
            type="number"
            id="deliveryCharges"
            name="deliveryCharges"
            className="form-control py-2"
            readOnly={false}
            onChange={handleChange}
            disabled={!editables.deliveryCharges}
          />
           {renderPencilIcon("deliveryCharges")}
          </div>
          <span className="help-block">{errors.deliveryCharges ?? ""}</span>
        </div>
      </div>
      <div className="row">
        {discountDetails?.vatAmount?<div className="form-group col">
        <label htmlFor="vatAmount">Total Vat Amount Included</label>
          <input
            value={`${country?.currency} ${discountDetails?.vatAmount}`}
            type="text"
            id="vatAmount"
            name="vatAmount"
            className="form-control py-2"
            readOnly={true}
          />
        </div>: <div className="form-group col-6"></div>}
        <div className="form-group col-6">
          <label htmlFor="nurseVisitFee">Nurse Visit Fee</label>
          <div className="input-group">
          <div class="input-group-prepend">
              <span class="input-group-text">{country?.currency ?? ""}</span>
            </div>
          <input
            value={`${discountDetails?.nurseVisitFee}`}
            type="number"
            id="nurseVisitFee"
            name="nurseVisitFee"
            className="form-control py-2"
            readOnly={false}
            onChange={handleChange}
            disabled={!editables.nurseVisitFee}
          />
           {renderPencilIcon("nurseVisitFee")}
          </div>
          <span className="help-block">{errors.nurseVisitFee ?? ""}</span>
        </div>
      </div>
      <div className="row">
        <div className="form-group col-6">
          <label htmlFor="totalAmount">Subtotal</label>
          <input
            value={`${country?.currency} ${discountDetails?.totalAmount}`}
            type="text"
            id="totalAmount"
            name="totalAmount"
            className="form-control py-2"
            readOnly={true}
          />
        </div>
        <div className="form-group col-6">
          <label htmlFor="finalAmount">Total</label>
          <div className="input-group">
          <div class="input-group-prepend">
              <span class="input-group-text">{country?.currency ?? ""}</span>
            </div>
            <input
            value={`${(discountDetails?.finalAmount)}`}
            type="number"
            id="finalAmount"
            name="finalAmount"
            className="form-control py-2"
            readOnly={false}
            onChange={handleChangeFinalAmount}
            disabled={!editables.finalAmount}
          />
          { isMiniPackageOrder && renderPencilIcon("finalAmount")}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="form-group col-6">
          <label htmlFor="paymentGateway">Payment Gateway*</label>
          <Dropdown
            value={discountDetails?.paymentGateway}
            onChange={(event, value) => handlePaymentGatewayChange(value.value)}
            id="paymentGateway"
            name="paymentGateway"
            placeholder="Payment Gateway"
            search
            selection
            options={isCartHasAnySubscriptionOrder 
              ? PAYMENT_GATEWAY_OPTIONS.filter((it) => it.value !== "COD") 
              : PAYMENT_GATEWAY_OPTIONS
            }
            className={"form-control" + (errors.paymentGateway ? " has-error" : "")}
            disabled={disableFlag}
          />
          <span className="help-block">{errors.paymentGateway}</span>
        </div>
        {/* {selectedMamoPay ? <div className="form-group col-6" style={{ paddingTop: "32px" }}>
          <button
            onClick={generateMamoPayLink}
            style={{ backgroundColor: "#0CA789", color: "white" }}
            className="btn btn-md new-user"

          >
            Generate MamoPay Link
          </button>
        </div> : ""} */}
      </div>
      {/* {selectedMamoPay ? <div className="row">
        <div className="form-group col">
          <label htmlFor="mamoPayLink">MamoPay Link</label>
          <input
            value={mamoPayDetails.paymentUrl ?? ""}
            type="text"
            id="mamoPayLink"
            name="mamoPayLink"
            className="form-control py-2"
            readOnly={true}
          />
        </div>
      </div> : ""} */}
      <div className="row">
        <div className="form-group col-6">
          <label htmlFor="paymentMode">Payment Mode*</label>
          <Dropdown
            value={discountDetails?.paymentMode}
            onChange={(event, value) => handlePaymentModeChange(value.value)}
            id="paymentMode"
            name="paymentMode"
            placeholder="Payment Mode"
            search
            selection
            options={isCartHasAnySubscriptionOrder 
              ? PAYMENT_MODE_OPTIONS.filter((it) => it.value !== "COD")
              : PAYMENT_MODE_OPTIONS
            }
            className={"form-control" + (errors.paymentMode ? " has-error" : "")}
            disabled={disableFlag}
          />
          <span className="help-block">{errors.paymentMode}</span>
        </div>
        {discountDetails.additionalDiscount>0? <div className="form-group col-6">
          <label htmlFor="transactionReference">Additional Discount Notes*</label>
          <input
            value={discountDetails?.additionalDicountNote }
            onChange={handleChangeDiscountNote}
            type="text"
            id="transactionReference"
            name="additionalDicountNote"
            className={"form-control py-2" + (errors.additionalDicountNote ? " has-error" : "")}
          />
          <span className="help-block">{errors.additionalDicountNote}</span>
        </div>:<></>}
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentInfoDraftOrder);
