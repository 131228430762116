import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Dropdown } from "semantic-ui-react";
import { ArrowDownward } from "@material-ui/icons";
import AlertBox from "components/AlertBox/AlertBox";
import CountryDropdown from "components/Common/CountryDropdown";
import Search from "components/Search/Search";
import Status from "components/Common/Status";
import { fetchCountries } from "services/RegionService";
import { getUserId } from "services/UserService";
import PhoenixAPI from "utils/PhoenixAPI";
import { Page } from "utils/constant";
import {
  isAccessDenied,
  isUserSuperAdmin,
  isUserAdmin,
} from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];

const STATUS_OPTIONS = [
  {
    key: "Active",
    value: true,
    text: "Active",
  },
  {
    key: "Inactive",
    value: false,
    text: "Inactive",
  },
];

const WIDGET_INTERNAL_OPTIONS=[
  {
    key: "longevity_widget",
    value: "longevity_widget",
    text: "Longevity_Widget",
  },
  {
    key: "result_section",
    value: "result_section",
    text: "Result_Section",
  },
  {
    key: "app_banner",
    value: "app_banner",
    text: "App_Banner",
  },
  {
    key: "mobile_promotions",
    value: "mobile_promotions",
    text: "Mobile_promotions",
  }
]

class HomePageBannersRankings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      countries: [],
      banners: [],
      rankOptions: [],
      banner: {},
      selectedCountry: null,
      searchTerm: null,
      searchApplied: false,
      errors: {},
      editables: {},
      isBannerViewModalVisible: false,
    };

    this.columns = [
      {
        name: "ID",
        selector: "id",
        width: "100px",
        sortable: true,
      },
      {
        name: "Widget Name",
        selector: "widgetName",
        wrap: true,
        sortable: true,
      },
      {
        name: "Rank",
        selector: "rank",
        width: "100px",
        sortable: true,
        center: true,
      },
      {
        name: "Status",
        selector: "status",
        maxWidth: "150px",
        center: true,
        cell: (banner) => <Status isActive={banner.isActive} />,
        sortable: true,
      },
      {
        name: "View",
        center: true,
        minWidth: "170px",
        cell: (banner) => this.renderViewButton(banner.id),
      },
    ];
  }

  componentDidMount() {
    this.fetchAclUser();
    this.fetchCountries();
    this.fetchHomePageRankings();
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if (!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  };

  fetchAclUser = async () => {
    const userId = getUserId();
    if (!userId) {
      console.log("fetchAclUser:: Invalid user id!", { userId });
      return;
    }

    try {
      const pageName = Page.HOME_PAGE_RANKINGS;
      const { data: aclUser } = await PhoenixAPI.get(
        `/api/v1/acl/users/userId/${userId}`
      );
      const pagePermission =
        aclUser?.pagePermissions?.find(
          (it) => pageName?.toLowerCase() === it.pageName?.toLowerCase()
        ) ?? null;
      const pageAccessRights =
        pagePermission?.pageAccessRights
          ?.filter((it) => it.isActive ?? false)
          ?.map((it) => it.accessRightName?.toLowerCase()) ?? null;
      const accessDenied = isAccessDenied(aclUser, pageAccessRights);
      this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
        const isEditable = this.hasPageAccessRight("edit");
        this.setState({ isEditable });
      });
    } catch (error) {
      console.log("fetchAclUser:: Error on fetching acl user!", error);
    }
  };

  fetchHomePageRankings = async () => {
    try {
      this.showLoading();
      const { data: homePageWidgets = [] } = await PhoenixAPI(
        "app-homepage_widgets"
      );
      this.setState({ banners: homePageWidgets });
    } catch (error) {
      console.log("Error on fetching app home page widgets!", error);
    } finally {
      this.hideLoading();
    }
  };

  fetchCountries = async () => {
    try {
      const countries = (await fetchCountries()) || [];
      const defaultCountry = countries.find((country) => country.is_default);
      this.setState({ countries, selectedCountry: defaultCountry });
    } catch (error) {}
  };

  findCountry = (id) => {
    const countries = this.state.countries || [];
    return countries.find((country) => country.id === id);
  };

  handleCountrySelection = (selectedCountry) =>
    this.setState({ selectedCountry });

  handleSearch = (queryString) => {
    const searchTerm = queryString ? queryString : "";
    this.setState({ searchApplied: true, searchTerm });
  };

  handleSearchClear = () => {
    this.setState({ searchTerm: "", searchApplied: false });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({ searchTerm: "", searchApplied: false });
  };

  handleChange = ({ currentTarget: input }) => {
    const banner = { ...this.state.banner };
    banner[input.name] = input.value;
    this.setState({ banner });
  };

  handleAddNewOtherItem = () => {
    this.setState({ isNewBanner: true }, () => this.showBannerViewModal());
  };

  handleViewBanner = (bannerId) => {
    const banners = this.state.banners ?? [];
    const banner = banners.find((it) => it.id === bannerId);
    if (!banner) {
      console.log(`Home Page Ranking Widget with id ${bannerId} is not found!`);
      return;
    }

    this.setState({ banner, isNewBanner: false }, () =>
      this.showBannerViewModal()
    );
  };

  showBannerViewModal = () => {
    const { selectedCountry, isNewBanner } = this.state;
    const rankOptions = this.createRankOptions() || [];

    if (isNewBanner) {
      const rank = Math.max(...rankOptions.map((it) => it.value));
      const banner = {
        countryId: selectedCountry?.id,
        isActive: false,
        rank,
      };
      this.setState({
        banner,
        rankOptions,
        isBannerViewModalVisible: true,
      });
    } else {
      this.setState({ rankOptions, isBannerViewModalVisible: true });
    }
  };

  hideBannerViewModal = () => {
    this.setState({ isBannerViewModalVisible: false });
    this.resetForm();
  };

  updateBanner = async (banner) => {
   let payload=[]
    const { createdAt, modifiedAt, ...payloadM } = banner;
    payload.push(payloadM);
    try {
      this.showLoading();
      const response = await PhoenixAPI.patch("app-homepage_widgets", payload);
      return response;
    } catch (error) {
      console.log("Error in updating homepage ", error);
    } finally {
      this.hideLoading();
    }
  };
  saveBanner = async (banner) => {
   let payload=[]
   payload.push(banner)
    try {
      this.showLoading();
      const response = await PhoenixAPI.patch("app-homepage_widgets", payload);
      return response;
    } catch (error) {
      console.log("Error in saving homepage ", error);
    } finally {
      this.hideLoading();
    }
  };
  handlesaveBanner = async () => {
    const { selectedCountry, banner } = this.state;
    if (!selectedCountry) {
      this.showErrorNotification("Please select a country!");
      return;
    }

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }

    try {
      this.showLoading();
      const isValidId = banner.id !== null && banner.id !== undefined;

      const response = isValidId
        ? await this.updateBanner(banner)
        : await this.saveBanner(banner);

      this.hideBannerViewModal();
      this.fetchHomePageRankings();
      console.log("Response: ", response);
      this.showSuccessNotification(
        isValidId
          ? "Home Page Ranking Updated Successfully."
          : "Home Page Ranking Created Successfully."
      );
      //   this.fetchOtherItems();
    } catch (error) {
      console.log("Error in saving the Home page ranking: ", error);
      this.showErrorNotification("Error in saving the Home page ranking", error.message);
    } finally {
      this.hideLoading();
    }
  };

  validate = () => {
    const { banner = {} } = this.state;
    const errors = {};

    return Object.keys(errors).length === 0 ? null : errors;
  };

  isInBetween = (number, start, end) => number >= start && number <= end;

  createRankOptions = () => {
    const { isNewBanner } = this.state;
    const banners = this.getOtherItemsForCurrentCountry();
    const ranks = banners.filter((it) => it.rank).map((it) => it.rank);
    const minRank = Math.min(1, ...ranks) || 0;
    const maxRank = Math.max(...ranks) || 0;
    const until = isNewBanner ? maxRank + 1 : maxRank;
    const rankOptions = [];
    for (let i = minRank; i <= until; i++)
      rankOptions.push({ key: i, value: i, text: i });

    if (rankOptions.length === 0)
      rankOptions.push({ key: 1, value: 1, text: 1 });
    console.log("Rank Options: ", rankOptions);
    return rankOptions;
  };

  resetForm = () => {
    this.setState({
      banner: {},
      errors: {},
      editables: {},
    });
  };

  renderViewButton = (id) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleViewBanner(id);
        }}
      >
        View
      </button>
    </div>
  );

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  renderPencilIcon = (fieldName) =>
    this.state.isEditable && !this.state.isNewBanner ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  getFilteredOtherItems = () => {
    const banners = this.getOtherItemsForCurrentCountry();
    return this.getSearchedOtherItems(banners);
  };

  getOtherItemsForCurrentCountry = () => {
    const { banners, selectedCountry } = this.state;
    return banners && selectedCountry
      ? banners.filter((banner) => banner.countryId === selectedCountry.id)
      : [];
  };

  getSearchedOtherItems = (banners) => {
    const { searchApplied, searchTerm } = this.state;

    if (!searchApplied || !searchTerm) return banners;

    const newValue = searchTerm.slice(0).trim().toLowerCase();
    return banners.filter((banner) => {
      const {
        id,
        widgetName,

        rank,
      } = banner;
      return (
        (widgetName && widgetName.toLowerCase().indexOf(newValue) !== -1) ||
        (id && id.toString() === newValue) ||
        (rank && rank.toString() === newValue)
      );
    });
  };

  render() {
    const {
      banners,
      banner,
      isNewBanner,
      countries,
      selectedCountry,
      searchTerm,
      searchApplied,
      loadError,
      errors,
      editables,
      isEditable,
    } = this.state;

    const filteredBanners = this.getFilteredOtherItems(banners) ?? [];

    const formBasicInformation = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="widgetName">Widget Name*</label>
            <div className="input-group">
              <input
                value={banner?.widgetName || ""}
                onChange={this.handleChange}
                id="widgetName"
                name="widgetName"
                type="text"
                placeholder="Enter widget name"
                className={
                  "form-control py-2" + (errors.widgetName ? " has-error" : "")
                }
                disabled={!isNewBanner && !editables.widgetName}
              />
              {this.renderPencilIcon("widgetName")}
            </div>
            <span className="help-block">{errors.widgetName}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="status">Status</label>
            <div className="input-group">
              <Dropdown
                value={banner?.isActive}
                onChange={(event, value) => {
                  this.setState({
                    banner: {
                      ...banner,
                      isActive: value.value,
                    },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewBanner && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
        </div>

        <div className="row">
          <div className="form-group col">
            <label htmlFor="rank">Rank</label>
            <div className="input-group">
              <Dropdown
                value={banner?.rank}
                onChange={(event, value) => {
                  this.setState({
                    banner: { ...banner, rank: value.value },
                  });
                }}
                id="rank"
                placeholder="Rank"
                search
                selection
                options={this.state.rankOptions}
                disabled={!isNewBanner && !editables.rank}
                className={"form-control" + (errors.rank ? " has-error" : "")}
              />
              {this.renderPencilIcon("rank")}
            </div>
            <span className="help-block">{errors.rank}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="status">Widget Internal Name</label>
            <div className="input-group">
              <Dropdown
                value={banner?.widgetInternalName}
                onChange={(event, value) => {
                  this.setState({
                    banner: {
                      ...banner,
                      widgetInternalName: value.value,
                    },
                  });
                }}
                id="widgetInternalName"
                name="widgetInternalName"
                placeholder="Widget Internal Name"
                search
                selection
                options={WIDGET_INTERNAL_OPTIONS}
                disabled={!isNewBanner && !editables.widgetInternalName}
                className={"form-control" + (errors.widgetInternalName ? " has-error" : "")}
              />
              {this.renderPencilIcon("widgetInternalName")}
            </div>
            <span className="help-block">{errors.widgetInternalName}</span>
          </div>
        </div>
      </div>
    );

    if (this.state.accessDenied) {
      return <AccessDenied />;
    }

    return (
      <div className="manage-package">
        <CountryDropdown
          countries={countries || []}
          selectedCountry={selectedCountry}
          onSelect={this.handleCountrySelection}
        />
        <div className="page-header">
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={searchTerm}
                handleSearch={this.handleSearch}
                clearSearch={this.handleSearchClear}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              ></Search>
            </div>
            <div className="action-item">
              <button
                onClick={this.handleAddNewOtherItem}
                disabled={!isEditable}
                style={{ backgroundColor: "#8FD14F" }}
                className="btn btn-md new-user"
              >
                <i className="fa fa-plus-circle"></i>Add New
              </button>
            </div>
          </div>
        </div>
        <Card body>
          <DataTable
            data={filteredBanners || []}
            columns={this.columns}
            defaultSortField="status"
            sortIcon={<ArrowDownward></ArrowDownward>}
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            highlightOnHover
            pagination
            responsive
            noHeader={searchApplied ? false : true}
            title={
              searchApplied ? (
                <p className="table-header">
                  {"Found " + filteredBanners.length + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              this.state.loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
        <Modal
          size="lg"
          show={this.state.isBannerViewModalVisible}
          onHide={this.hideBannerViewModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
            <div className="user-title"></div>
            <button
              onClick={this.handlesaveBanner}
              disabled={!isEditable}
              className="btn modal-save-btn"
            >
              Save
            </button>
          </Modal.Header>
          <Modal.Body>{formBasicInformation}</Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomePageBannersRankings);
