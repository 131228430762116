import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Modal } from "react-bootstrap";
import AccessDenied from "../../components/Common/AccessDenied";
import LongevityScore from "./LongevityScore";
import { getUserId } from "services/UserService";
import { isAccessDenied, isUserSuperAdmin } from "services/aclService";
import { Page } from "utils/constant";
import PhoenixAPI from "utils/PhoenixAPI";
import CoachSuggestionsDetails from "./CoachSuggestionsDetails";
import CoachSuggestedArticles from "./CoachSuggestedArticles";
import CoachMeal from "./CoachMeal";
import {fetchNewLongevityCategories} from "services/LongevityService";
import DataTable from "react-data-table-component";
import { ArrowDownward } from "@material-ui/icons";
import FitnessQuestionsAnswers from "./FitnessQuestionsAnswers";


const BASE_URL_CUSTOMER_LONGEVITYSCORE = "customer-parameter-score/user-id/";
const BASE_URL_COACH_PLANS="coach-view-longevity-score-page/user-id/";

class CustomerLongevityScoreDetail extends Component {
  constructor(props) {
    super(props);
 
    this.state = {
      customerId: this.props.match.params.customerId,
      countryId :this.props.match.params.countryId,
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      customerLongevityScore: {},
      isLoaded: false,
      loadError: null,
      activeAccordianIndex: "",
      customerName: "",
      customeScore: "",
      percentile:"",
      longevityExpire:false,
      customerParameterScores: [],
      collapsibles: {},
      fitnessPlans:[],
      supplementPlans:[],
      sleepPlans:[],
      mealPlans:[],
      foodPlans:[],
      suggestedSupplements:[],
      orderId:null,
      dependentId:this.props.match.params.dependentId?this.props.match.params.dependentId:null,
      articleOptions:[],
      longevityCategoryId:this.props.match.params.longevityCategoryId,
      adminfoodPlans:[],
      adminsleepPlans:[],
      adminfitnessPlans:[],
      adminsupplementPlans:[],
      isFitnessViewModal:false,
      errors:{},
      editables: {},
    };
    this.fitnessQuestionnaireColumns=[
      {
        name: "Steps Goal",
        selector: "defaultSteps",
        sortable: true,
      },
      {
        name: "Caloire Goal",
        selector: "defaultCalorie",
        sortable: true,
      },
      {
        name: "Workout Goal",
        selector: "defaultWorkoutTime",
        sortable: true,
      },
      {
        name: "View ",
        center:true,
        cell:(question)=>this.renderViewButton(question)
      },
      
    ]
  }

  async componentDidMount() {
    await this.fetchAclUser();
   // this.fetchCustomerLongevityScore();\
   
    this.fetchCoachSuggestedPlans();
    await this.fetchArticles();
    await this.fetchLongevityCategories();
  }

  fetchAclUser = async () => {
    const userId = getUserId();
    if (!userId) {
      console.log("fetchAclUser:: Invalid user id!", { userId });
      return;
    }

    try {
      const pageName = Page.HOME;
      const { data: aclUser } = await PhoenixAPI.get(
        `/api/v1/acl/users/userId/${userId}`
      );
      const pagePermission =
        aclUser?.pagePermissions?.find(
          (it) => pageName?.toLowerCase() === it.pageName?.toLowerCase()
        ) ?? null;
      const pageAccessRights =
        pagePermission?.pageAccessRights
          ?.filter((it) => it.isActive ?? false)
          ?.map((it) => it.accessRightName?.toLowerCase()) ?? null;
      const accessDenied = isAccessDenied(aclUser, pageAccessRights);
      this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
        const isEditable = this.hasPageAccessRight("edit");
        this.setState({ isEditable });
      });
    } catch (error) {
      console.log("fetchAclUser:: Error on fetching acl user!", error);
    }
  };

  fetchLongevityCategories = async () => {
   const{countryId}=this.state;
    this.showLoading();
    try {
      const categories = (await fetchNewLongevityCategories()) || [];
     
     
      this.setState({ 
      adminfoodPlans:categories.foodPlans?.filter((item)=>item.isActive && item.countryId==countryId) ?? [],
       adminsleepPlans:categories.sleepPlans?.filter((item)=>item.isActive && item.countryId==countryId) ?? [],
      adminfitnessPlans:categories.fitnessPlans?.filter((item)=>item.isActive && item.countryId==countryId) ?? [],
      adminsupplementPlans:categories.supplementPlans?.filter((item)=>item.isActive && item.countryId==countryId) ?? []});
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching Longevity Categories:",
        error.message
      );
      this.setState({ loadError: "Some Error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };


  fetchArticles=async()=>{
    const{heading,countryId}=this.state;
    console.log("countrty",countryId);
    const {data=[]}= await PhoenixAPI.get("health-hub");
   
   
  
    let articles=[];
    data.length>0 && data.filter((item)=>item.country==countryId)
    .map((item) => {
      let articledetail = {};
      articledetail["text"] = item.articleNameEnglish;
    articledetail["value"] = item.id;
      articledetail["key"] = item.id;
      
    articles.push(articledetail);
    })

   this.setState({articleOptions:articles})
  console.log("articles",articles);

  };
  fetchCoachSuggestedPlans=async()=>{
    const { customerId, countryId,dependentId ,longevityCategoryId} = this.props.match.params;
    this.showLoading();
    try {
      const { data: AllDetails = {} } = await PhoenixAPI.get(
        BASE_URL_COACH_PLANS + customerId,{params:{countryId:countryId,longevityCategoryId:longevityCategoryId,dependentId:dependentId?dependentId:null}}
      );
      const{parameterScore,coachSuggestedSupplementPlanArticles,coachSuggestedSleepPlanArticles,
        coachSuggestedFoodPlanArticles,coachSuggestedFitnessPlanArticles,mealPlan,suggestedSupplement, coachFitnessQuestionnaires
      }=AllDetails;
      const { userName, longevityScore,isExpired, customerParameterScores,percentile } =
        parameterScore || {};
      const{orderId,coachSupplementSuggested}=suggestedSupplement;
      let updatedMealplans=[];
      if(mealPlan!==null)
       updatedMealplans= [...this.state.mealPlans, mealPlan];
       
      this.setState({
        customerName: userName,
        customeScore: longevityScore,
        customerParameterScores: customerParameterScores,
        longevityExpire:isExpired,
        activeAccordianIndex: 1,
        percentile:percentile,
        supplementPlans:coachSuggestedSupplementPlanArticles,
        sleepPlans:coachSuggestedSleepPlanArticles,
        foodPlans:coachSuggestedFoodPlanArticles,
        mealPlans:updatedMealplans,
        fitnessPlans:coachSuggestedFitnessPlanArticles,
        suggestedSupplements:coachSupplementSuggested ,
        coachFitnessQuestionnaires:coachFitnessQuestionnaires,
      });

      }
      catch (error) {
        this.showErrorNotification(
          "Error on fetching customer longevity score: ",
          error.message
        );
        this.setState({ loadError: "Some error has occured. Please try again" });
      } finally {
        this.hideLoading();
      }


  }


  fetchCustomerLongevityScore = async () => {
    const { customerId, countryId,dependentId } = this.props.match.params;
    this.showLoading();
    try {
      const { data: customerLongevityScore = {} } = await PhoenixAPI.get(
        BASE_URL_CUSTOMER_LONGEVITYSCORE + customerId,{params:{countryId:countryId,dependentId:dependentId?dependentId:null}}
      );
      const { userName, longevityScore,isExpired, customerParameterScores,percentile } =
        customerLongevityScore || {};

      this.setState({
        customerName: userName,
        customeScore: longevityScore,
        customerParameterScores: customerParameterScores,
        longevityExpire:isExpired,
        activeAccordianIndex: 1,
        percentile:percentile,
        
      });
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching customer longevity score: ",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  handleCollapsibleChange = (key) => {
    const collapsibles = {};
    if (this.state.collapsibles[key] === undefined) collapsibles[key] = true;
    this.setState({ collapsibles });
  };

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  };

  renderViewButton = (question) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleViewFitnessQuestion(question);
        }}
      >
        View
      </button>
    </div>
  );

  handleViewFitnessQuestion=(question)=>{
    const {coachFitnessQuestionnaires}= this.state;
    const coachFitnessQuestion=coachFitnessQuestionnaires[0];
   this.setState({isFitnessViewModal:true, coachFitnessQuestion:coachFitnessQuestion, isNewFitnessQuestion:false}) 
  }

  hideFitnessQuestion=()=>{
    this.setState({isFitnessViewModal:false, coachFitnessQuestion:{},errors:{},editables:{}}) 
  }

  handleChange = ({ currentTarget: input }) => {
    const numberInputs = ["defaultSteps", "defaultCalorie"];
    const coachFitnessQuestion = { ...this.state.coachFitnessQuestion };
    if (numberInputs.includes(input.name))
    coachFitnessQuestion[input.name] = input.value ? Number(input.value) : "";
    else coachFitnessQuestion[input.name] = input.value;
    this.setState({ coachFitnessQuestion });
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  renderHeader = () => {
    const { customerName = "", customeScore = "",longevityExpire="",percentile="" } = this.state;
    return (
      <div className="row">
        <div
          className="col-md"
          style={{
            display: "flex",
            flexDirection:"column",
            justifyContent:"center",
            // alignItems: "center",
          }}
        >
          <span>
            <span className="font-weight-bold">Customer Name: </span>
            {customerName}
          </span>
          <span style={{fontWeight:500}}>
            <span className="font-weight" >Longevity Percentile - </span>
            {percentile}%
          </span>
        </div>
        <div className="col-lg">
          <Card style={{ background: "#FFFFFF" }}>
            <Card.Body
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <p className="font-weight-bold">Longevity Score</p>
              <LongevityScore score={customeScore} expire={longevityExpire}  />
            </Card.Body>
          </Card>
        </div>
      </div>
    );
  };



  renderTestScroes = () => {
    const {
      activeAccordianIndex,
      customerParameterScores = [],
      collapsibles = {},
    } = this.state;
  
    return (
      <>
        {customerParameterScores.length > 0 &&
          customerParameterScores.map((value, index) => {
            return (
              <Card key={index}>
                <Card.Body>
                  <div className="row">
                    <div className="col-1">
                      <div style={{ fontWeight: 500 }}>{index + 1}.</div>
                    </div>
                    <div className="col">
                      <div style={{ fontWeight: 500 }}>
                        {value.parameterName}
                      </div>
                    </div>
                    <div className="col ml-auto">
                      <div style={{ fontWeight: 500 }}>
                        {value.generatedDate}
                      </div>
                    </div>
                    <div className="col text-right">
                      <div style={{ fontWeight: 500 }}>
                        {value.parameterScore ?? "N/A"}
                      </div>
                    </div>

                    <div className="col text-right mr-1 pr-0">
                      <i
                        onClick={() =>
                          this.handleCollapsibleChange(
                            value.longevityParameterId
                          )
                        }
                        className={`caret square ${
                          collapsibles[value.longevityParameterId]
                            ? "up"
                            : "down"
                        } icon big`}
                        style={{ cursor: "pointer" }}
                      ></i>
                    </div>
                  </div>
                  {collapsibles[value.longevityParameterId] ? (
                    <div className="row">
                      <div className="col-1"></div>
                      <div
                        className="col-auto"
                        style={{ padding: 0, margin: 0 }}
                      >
                        <table className="table table-borderless">
                          <thead>
                            <tr>
                              <td colSpan={2}  style={{fontWeight:600, color:"#757575", fontSize:"12px"}}>Biomarkers</td>
                              <td colSpan={2} style={{fontWeight:600, color:"#757575", fontSize:"12px"}}>Score</td>
                            </tr>
                          </thead>
                          {value.biomarkerScores.length &&
                            value.biomarkerScores.map((biomarker, index) => {
                              return (
                                <tbody key={index}>
                                  <tr>
                                    <td colSpan={2} style={{fontWeight:500, color:"#07141A", fontSize:"14px"}}>{biomarker.biomarkerName ?? ""}</td>
                                   { biomarker.biomarkerScore?<td colSpan={2} style={{fontWeight:500, color:"#07141A", fontSize:"14px"}}>{biomarker.biomarkerScore}</td>:<td style={{fontWeight:500, color:"#C8C8C8", fontSize:"14px"}}>N/A</td>}
                                  </tr>
                                </tbody>
                              );
                            })}
                        </table>
                      </div>
                    </div>
                  ) : null}
                </Card.Body>
              </Card>
            );
          })}
      </>
    );
  };

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  renderPencilIcon = (fieldName) =>
  this.state.isEditable  ? (
    <div
      onClick={() => this.setEditable(fieldName)}
      style={{ cursor: "pointer" }}
      className="input-group-append"
    >
      <i className="input-group-text fa fa-pencil"></i>
    </div>
  ) : (
    ""
  );

  handleSaveFitnessQuestion=async()=>{
    const {coachFitnessQuestion,customerId=null, dependentId=null}= this.state;
    const coachId= getUserId()
    const payload={
      userId:customerId,
      dependentId:dependentId,
      defaultSteps:coachFitnessQuestion.defaultSteps,
      defaultCalorie:coachFitnessQuestion.defaultCalorie,
      defaultWorkoutTime:coachFitnessQuestion.defaultWorkoutTime,
      coachId:coachId
    }

 try{
   const response= await PhoenixAPI.post("fitness-default-value-user-levels", payload);

   this.hideFitnessQuestion();
   this.showSuccessNotification("Fitness Questionnaire is saved successfully");
   this.fetchCoachSuggestedPlans();
 }
 catch(error){
   console.log("Error in saving Default Fitness Questionnaire", error.message);
   this.showErrorNotification("Error in saving Default Fitness Questionnaire", error.message)
 }

    console.log("cvhjkl",payload);
  }

  render() {
    const { accessDenied, customerLongevityScore = [] , customerId, customerName, countryId,dependentId,suggestedSupplements,supplementPlans,orderId,sleepPlans,fitnessPlans,foodPlans,mealPlans,isEditable,articleOptions,longevityCategoryId,adminfitnessPlans,adminfoodPlans,adminsleepPlans,adminsupplementPlans,coachFitnessQuestionnaires} = this.state;

     const {errors,editables,coachFitnessQuestion, isNewFitnessQuestion}= this.state;
    if (accessDenied) {
      return <AccessDenied />;
    }

    const header = this.renderHeader();
    const testScores = this.renderTestScroes();
    const formFitnessQuestion=(
      <div className="container mt-4">
          <div className="row">
          <div className="form-group col">
            <label htmlFor="defaultSteps">Steps goal (Number of steps per day)</label>
            <div className="input-group">
              <input
                value={coachFitnessQuestion?.defaultSteps || ""}
                onChange={this.handleChange}
                id="defaultSteps"
                name="defaultSteps"
                type="number"
                placeholder="Enter Steps Goal"
                className={
                  "form-control py-2" +
                  (errors.defaultSteps ? " has-error" : "")
                }
                disabled={!isNewFitnessQuestion && !editables.defaultSteps}
              />
              {this.renderPencilIcon("defaultSteps")}
            </div>
            <span className="help-block">{errors.defaultSteps}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="defaultCalorie">Calorie burn (No. of calorie to be burn per day in KCAL)</label>
            <div className="input-group">
              <input
                value={coachFitnessQuestion?.defaultCalorie || ""}
                onChange={this.handleChange}
                id="defaultCalorie"
                name="defaultCalorie"
                type="number"
                placeholder="Enter Default Calorie"
                className={
                  "form-control py-2" +
                  (errors.defaultCalorie ? " has-error" : "")
                }
                disabled={!isNewFitnessQuestion && !editables.defaultCalorie}
              />
              {this.renderPencilIcon("defaultCalorie")}
            </div>
            <span className="help-block">{errors.defaultCalorie}</span>
          </div>
        </div>
         <div className="row">
         <div className="form-group col-6">
            <label htmlFor="defaultWorkoutTime">Workout Time (No. of minutes of workout every day)</label>
            <div className="input-group">
              <input
                value={coachFitnessQuestion?.defaultWorkoutTime || ""}
                onChange={this.handleChange}
                id="defaultWorkoutTime"
                name="defaultWorkoutTime"
                type="text"
                placeholder="Enter Default Workout Time"
                className={
                  "form-control py-2" +
                  (errors.defaultWorkoutTime ? " has-error" : "")
                }
                disabled={!isNewFitnessQuestion && !editables.defaultWorkoutTime}
              />
              {this.renderPencilIcon("defaultWorkoutTime")}
            </div>
            <span className="help-block">{errors.defaultWorkoutTime}</span>
          </div>
         </div>
         
         <FitnessQuestionsAnswers questions={coachFitnessQuestion?.fitnessQuestionAnswers??[]}/>
         
      </div>
    )
    return (
      <>
        <div className="container-fluid">
        <div className="col-8">{header}</div>
        <div className="col-8">{testScores}</div>
      </div>
       <CoachSuggestionsDetails customerId={customerId} customerName={customerName} countryId={countryId} dependentId={dependentId} suggestions={suggestedSupplements} orderId={orderId} isEditable={isEditable} longevityCategoryId ={longevityCategoryId} />
       <p className="font-weight-bold">Supplement Plan</p>
      <CoachSuggestedArticles customerId={customerId}  country={countryId} dependentId={dependentId} heading={"Supplement"} suggestedDefaultArticles={supplementPlans} isEditable={isEditable}  articleOptions={articleOptions} longevityCategoryId ={longevityCategoryId} adminSuggestedArticles={adminsupplementPlans}/>
      <p className="font-weight-bold">Meal Plan</p>
      <CoachMeal customerId={customerId} country={countryId} dependentId={dependentId} suggestedMealPlans={mealPlans} isEditable={isEditable}/>
     <CoachSuggestedArticles customerId={customerId} country={countryId} dependentId={dependentId} heading={"Food"} suggestedDefaultArticles={foodPlans}  isEditable={isEditable} articleOptions={articleOptions} longevityCategoryId ={longevityCategoryId} adminSuggestedArticles={adminfoodPlans}/>
     <p className="font-weight-bold">Fitness Plan</p>
     <CoachSuggestedArticles customerId={customerId} country={countryId} dependentId={dependentId} heading={"Fitness"} suggestedDefaultArticles={fitnessPlans}  isEditable={isEditable} articleOptions={articleOptions} longevityCategoryId ={longevityCategoryId} adminSuggestedArticles={adminfitnessPlans} />
      <p className="font-weight-bold">Fitness Questionnaire</p>
        <Card body>
          <DataTable
            data={coachFitnessQuestionnaires || []}
            columns={this.fitnessQuestionnaireColumns}
            defaultSortField="defaultSteps"
            sortIcon={<ArrowDownward></ArrowDownward>}

            highlightOnHover

            responsive

          />
        </Card>
     <p className="font-weight-bold">Sleep Plan</p>
      <CoachSuggestedArticles customerId={customerId} country={countryId} dependentId={dependentId} heading={"Sleep"} suggestedDefaultArticles={sleepPlans}  isEditable={isEditable} articleOptions={articleOptions}  longevityCategoryId ={longevityCategoryId} adminSuggestedArticles={adminsleepPlans}/>
     
        <Modal
          size="lg"
          show={this.state.isFitnessViewModal}
          onHide={this.hideFitnessQuestion}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>

            </Modal.Title>
            <div className="user-title"></div>
            <button
              onClick={this.handleSaveFitnessQuestion}
              disabled={!isEditable}
              className="btn modal-save-btn"
            >
              Save
            </button>
          </Modal.Header>
          <Modal.Body>
             {formFitnessQuestion}
          </Modal.Body>
        </Modal>
    
      </>
    
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerLongevityScoreDetail);
