import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Modal, Tabs, Tab } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import API from "utils/API";
import PhoenixAPI from "utils/PhoenixAPI";
import PriorityBasket from "./PriorityBasket";
import { fetchBloodPackages, fetchMiniPackages } from "services/productService";
import SelectBloodPackages from "components/Common/SelectBloodPackages";
import SelectCustomPackages from "components/Common/SelectCustomPackages";
import SelectHomeCareServices from "./SelectHomeCareServices";
import SelectMiniPackages from "components/Common/SelectMiniPackages";
import Logs from "components/Logs/Logs";
import HomecareBasketsForm from "./HomecareBasketsForm";
import "./broadCasting.scss";
import { yellow } from "@material-ui/core/colors";

const STATUS_OPTIONS = [
  {
    key: "Active",
    value: true,
    text: "Active",
  },
  {
    key: "Inactive",
    value: false,
    text: "Inactive",
  },
];
const BASE_URL_HOMECARE_SERVICES = "home-care-service/";
class BroadCastingBaskets extends Component {
  constructor(props) {
    super(props);

    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: this.props.isEditable,
      countries: [],
      selectedCountry: null,
      baskets: [],
      basket: {},
      isPriorityBucketViewModalVisible: false,
      errors: {},
      editables: {},
      homecareServices: [],
      bloodPackages: [],
      customPackages: [],
      miniPackages: [],
      logsData: [],
      pageId : null,
    };
  }
  async componentDidMount() {
    this.fetchHomecareServices();
    // this.fetchBloodPackages();
    // this.fetchCustomPackages();
    // this.fetchMiniPackages();
    await this.fetchBaskets();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.selectedCountry !== nextProps.selectedCountry) {
      this.setState({
        countries: nextProps.countries,
        selectedCountry: nextProps.selectedCountry,
      });
    }
  }

  fetchBaskets = async () => {
    const { selectedCountry } = this.props || {};
    try {
      this.showLoading();
      const { data: baskets = [] } = await PhoenixAPI.get("broadcast-basket");
      this.setState({ baskets });
    } catch (error) {
      this.showErrorNotification("Error on fetching baskets: " + error.message);
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  fetchHomecareServices = async () => {
    try {
      const { data: homecareServices = [] } = await PhoenixAPI.get(
        BASE_URL_HOMECARE_SERVICES
      );
      console.log("homecare services broad", homecareServices);
      this.setState({ homecareServices });
    } catch (error) {
      console.log("Error on fetching homecare services", error);
      this.showErrorNotification(
        "Error on fetching homecare services: ",
        error.message
      );
    }
  };
  fetchBloodPackages = async () => {
    try {
      const bloodPackages = await fetchBloodPackages();
      this.setState({ bloodPackages: bloodPackages ?? [] });
    } catch (error) {
      console.log("Error on fetching blood packages", error);
    }
  };

  fetchCustomPackages = async () => {
    try {
      const { data: customPackages = [] } = await PhoenixAPI.get(
        "custom/packages"
      );
      this.setState({ customPackages: customPackages ?? [] });
    } catch (error) {
      console.log("Error on fetching custom packages", error);
    }
  };

  fetchMiniPackages = async () => {
    try {
      const miniPackages = await fetchMiniPackages();
      this.setState({ miniPackages: miniPackages ?? [] });
    } catch (error) {
      console.log("Error on fetching mini packages", error);
    }
  };

  handleAddNewPriorityBasket = () => {
    this.setState({ isNewPriorityBucket: true }, () =>
      this.showPriorityBasketViewModal()
    );
  };

  fetchFilteringHomeCareServices = async () => {
    const { selectedCountry = null } = this.state;
    if (!selectedCountry) {
      console.log("Please select a country");
      return;
    }
    try {
      const { data: homecareServices = [] } = await PhoenixAPI.get(
        `/broadcast-homecare-dropdown/country/${selectedCountry.id}`
      );
      return homecareServices;
    } catch (error) {
      console.log(
        "Error on fetching filtering home care services",
        error.message
      );
    }
  };
  showPriorityBasketViewModal = async () => {
    const {
      isNewPriorityBucket,
      selectedCountry,
      homecareServices,
      bloodPackages,
      customPackages,
      miniPackages,
      baskets,
      basket,
    } = this.state;
    const homecareServicesNew = await this.fetchFilteringHomeCareServices();
    const priorityOptions = this.createPriorityOptions() || [];
    const homeCareServicesForCountry =
      homecareServices.filter(
        (homecareService) => homecareService.countryId === selectedCountry.id
      ) ?? [];
    const bloodPackagesForCountry =
      bloodPackages.filter((item) => item.country === selectedCountry.id) ?? [];
    const customPackagesForCountry =
      customPackages.filter((item) => item.country === selectedCountry.id) ??
      [];
    const miniPackagesForCountry =
      miniPackages.filter((item) => item.countryId === selectedCountry.id) ??
      [];
    if (isNewPriorityBucket) {
      const priority = Math.max(...priorityOptions.map((it) => it.value));
      const basket = {
        countryId: selectedCountry?.id,
        isActive: false,
        priority,
      };
      this.setState({
        basket,
        priorityOptions,
        isPriorityBucketViewModalVisible: true,
        homeCareServicesForCountry,
        bloodPackagesForCountry,
        customPackagesForCountry,
        miniPackagesForCountry,
        homeCareServicesForCountry: homecareServicesNew,
      });
    } else {
      const { homecares = [] } = basket || {};
      homeCareServicesForCountry &&
        homeCareServicesForCountry.forEach((item) => {
          if (homecares.includes(item.id)) {
            homecareServicesNew.push(item);
          }
        });
      this.setState({
        priorityOptions,
        isPriorityBucketViewModalVisible: true,
        homeCareServicesForCountry: homecareServicesNew,
        bloodPackagesForCountry,
        customPackagesForCountry,
        miniPackagesForCountry,
      });
    }
  };

  hidePriorityBasketViewModal = () => {
    this.setState({ isPriorityBucketViewModalVisible: false, pageId: null });
    this.resetForm();
  };

  handleChange = ({ currentTarget: input }) => {
    const basket = { ...this.state.basket };
    basket[input.name] = input.value;
    this.setState({ basket });
  };

  // handleFetchLogs = async(basketId)=>{
  //   console.log("hello world it is new function",basketId)
  //   const url = `content-logs?pageId=${basketId}&pageType=BROADCASTBASKET`;
  //   const response = await PhoenixAPI.get(url);
  //   const data = await response.data;
  //   this.setState({
  //     logsData : data
  //   })
  //   console.log("this is response of handleFetchLogs",data);
  // }

  renderView = (basketId) => {
    console.log("this is the basket id ",basketId)
    const { baskets } = this.state || {};
    const basket = baskets.find((b) => b.id === basketId);
    if (!basket) {
      console.log("Basket with Id is not found");
      return;
    }
    // this.handleFetchLogs(basketId)
    this.setState(
      {
        basket,
        isNewPriorityBucket: false,
        pageId: basketId
      },
      () => this.showPriorityBasketViewModal()
    );
  };

  getBasketsForCurrentCountry = () => {
    const { baskets, selectedCountry } = this.state;
    return baskets && selectedCountry
      ? baskets.filter((baskets) => baskets.countryId === selectedCountry.id)
      : [];
  };

  createPriorityOptions = () => {
    const { isNewPriorityBucket } = this.state;
    const baskets = this.getBasketsForCurrentCountry();
    const ranks = baskets.filter((it) => it.priority).map((it) => it.priority);
    const minRank = Math.min(1, ...ranks) || 0;
    const maxRank = Math.max(...ranks) || 0;
    const until = isNewPriorityBucket ? maxRank + 1 : maxRank;
    const priorityOptions = [];
    for (let i = minRank; i <= until; i++)
      priorityOptions.push({ key: i, value: i, text: i });

    if (priorityOptions.length === 0)
      priorityOptions.push({ key: 1, value: 1, text: 1 });

    return priorityOptions;
  };

  handleQuestionsUpdated=(homecareServices=[])=>{    
    const filteredBasketHomecares =homecareServices && homecareServices.map((item)=>{
      return{
       
        homecareId: item.homecareId,
        categories: item.categories
      }
    })
    const basket = { ...this.state.basket, basketHomecares: filteredBasketHomecares };
    this.setState({ basket });
  }

  resetForm = () => {
    this.setState({
      basket: {},
      errors: {},
      editables: {},
    });
  };

  updateBucket = async (bucket) => {
    const {homecares, ...payload}= bucket;
    try {
      this.showLoading();
      const response = await PhoenixAPI.patch(
        `broadcast-basket/${bucket.id}`,
        payload
      );
      return response;
    } catch (error) {
      this.showErrorNotification("Error on updating basket: " + error.message);
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  saveBucket = async (bucket) => {
    const {homecares, ...payload}= bucket;
    try {
      this.showLoading();
      const response = await PhoenixAPI.post(`broadcast-basket`, payload);
      return response;
    } catch (error) {
      this.showErrorNotification("Error on updating basket: " + error.message);
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  validate = () => {
    const { basket = {} } = this.state;
    const errors = {};

    const {
      basketName,
      timer,
      isActive,
      homecares = [],
      bloodPackages = [],
      customPackages = [],
      miniPackages = [],
      priority,
      basketHomecares
    } = basket;

    if (isActive === null) errors.status = "Status is a required field!";
    if (!basketName || basketName.trim() === "")
      errors.basketName = "Basket Name cannot be blank";
    else if (basketName.length > 100)
      errors.basketName = "Basket Name is limited to a 100 characters";
    if (!timer || timer.trim() === "")
      errors.timer = "Basket Name cannot be blank";
    if (priority === null || priority === undefined)
      errors.priority = "priority cannot be blank";
    // if (basketHomecares.length === 0) {
    //   errors.homecares = "Homecares cannot be blank";
    // }
    // if (bloodPackages && bloodPackages.length === 0) {
    //   errors.bloodPackages = "Blood Packages  cannot be blank";
    // }
    // if (customPackages && customPackages.length === 0) {
    //   errors.customPackages = "Custom Packages cannot be blank";
    // }
    // if (miniPackages && miniPackages.length === 0) {
    //   errors.miniPackages = "Custom Packages cannot be blank";
    // }
    return Object.keys(errors).length === 0 ? null : errors;
  };

  handleSaveBasket = async () => {
    const { selectedCountry, basket } = this.state || {};
    if (!selectedCountry) {
      this.showErrorNotification("Please select a country!");
      return;
    }
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }
    try {
      // this.showLoading();
      const isValidId = basket.id !== null && basket.id !== undefined;

      const response = isValidId
        ? await this.updateBucket(basket)
        : await this.saveBucket(basket);

      this.hidePriorityBasketViewModal();
      console.log("Response: ", response);
      if (isValidId) {
        window.location.reload();
      } else {
        this.fetchBaskets();
      }

      this.showSuccessNotification(
        isValidId
          ? "Priortiy Basket  Updated Successfully."
          : "Priortiy Basket Created Successfully."
      );
    } catch (error) {
      console.log("Error in Saving Priority Basket", error.message);
    }
  };
  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  renderPencilIcon = (fieldName) =>
    this.props.isEditable && !this.state.isNewPriorityBucket ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

    filteredHomecareServices=()=>{
     const {homeCareServicesForCountry=[]}= this.state;
     return  homeCareServicesForCountry.length>0 && homeCareServicesForCountry.map((service)=>{
      return {
        key: service.id,
        value: service.id,
        label: service.name,
      };
     })
    }
  render() {
    const {
      isEditable,
      baskets,
      isPriorityBucketViewModalVisible,
      basket,
      isNewPriorityBucket,
      errors,
      editables,
      homecareServices,
      bloodPackages,
      customPackages,
      miniPackages,
    } = this.state;
    const filteredBaskets = this.getBasketsForCurrentCountry() ?? [];
    
    // const logsDetails = (
    //   <div style={{margin : '12px 0px'}} >
    //     <table className="log-table" >
    //       <thead>
    //         <tr className="log-table-tr" >
    //           <th className="log-table-th" >Date</th>
    //           <th className="log-table-th" >By whom</th>
    //           <th className="log-table-th" >Status</th>
    //           <th className="log-table-th" >From</th>
    //           <th className="log-table-th" >To</th>
    //         </tr>
    //       </thead>
    //       <tbody>
    //         {this.state.logsData.map((elem,index)=>{
    //           const originalDateString = elem.createdAt;
    //           // Convert the string to a Date object 
    //           const dateObject = new Date(originalDateString);
    //           // Format the date to "YYYY-MM-DD HH:MM"
    //           const formattedDateString = dateObject.toISOString().slice(0, 16).replace('T', ' ');
    //           return <tr key={index} className="log-table-tr" >
    //             <td className="log-table-td" >{formattedDateString}</td>
    //             <td className="log-table-td" >{elem.createdByName}</td>
    //             <td className="log-table-td" >{elem.type}</td>
    //             <td className="log-table-td" >{elem.previousValue}</td>
    //             <td className="log-table-td" >{elem.newValue}</td>
    //           </tr>
    //         })}
    //       </tbody>
    //     </table>
    //   </div>
    // )

    const basketDetails = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="basketName">Basket Name *</label>
            <div className="input-group">
              <input
                value={basket?.basketName || ""}
                onChange={this.handleChange}
                id="basketName"
                name="basketName"
                type="text"
                placeholder="Basket Name"
                className={
                  "form-control py-2" + (errors.basketName ? " has-error" : "")
                }
                disabled={!isNewPriorityBucket && !editables.basketName}
              />
              {this.renderPencilIcon("basketName")}
            </div>
          </div>
          <div className="form-group col">
            <label htmlFor="rank">Priority *</label>
            <div className="input-group">
              <Dropdown
                value={basket?.priority}
                onChange={(event, value) => {
                  this.setState({
                    basket: { ...basket, priority: value.value },
                  });
                }}
                id="priority"
                placeholder="Priority"
                search
                selection
                options={this.state.priorityOptions}
                disabled={!isNewPriorityBucket && !editables.priority}
                className={
                  "form-control" + (errors.priority ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("priority")}
            </div>
            <span className="help-block">{errors.priority}</span>
          </div>
        </div>
        {/* <div className="row">
          <div className="form-group col">
            <label htmlFor="homecares">Select Home Cares *</label>
            <div className="input-group">
              <SelectHomeCareServices
                id="homecares"
                tests={this.state.homeCareServicesForCountry ?? []}
                selectedTestIds={basket.homecares ?? []}
                onSelect={(values) => {
                  this.setState({
                    basket: {
                      ...basket,
                      homecares: values.map((it) => it.value),
                    },
                  });
                }}
                isDisabled={!isNewPriorityBucket && !editables.homecares}
                className={
                  "form-control" + (errors.homecares ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("homecares")}
            </div>
            <span className="help-block">{errors.homecares}</span>
          </div>
        </div> */}
        {/* <div className="row">
          <div className="form-group col">
            <label htmlFor="bloodPackages">Add Blood Packages</label>
            <div className="input-group">
              <SelectBloodPackages
                id="bloodPackages"
                bloodPackages={this.state.bloodPackagesForCountry ?? []}
                selectedBloodPackageIds={basket.bloodPackages ?? []}
                onSelect={(values) => {
                  this.setState({
                    basket: {
                      ...basket,
                      bloodPackages: values.map((it) => it.value),
                    },
                  });
                }}
                isDisabled={!isNewPriorityBucket && !editables.bloodPackages}
                menuPlacement="bottom"
                showOnlyActive={true}
              />
              {this.renderPencilIcon("bloodPackages")}
            </div>

            <span className="help-block">{errors.bloodPackages}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="customPackages">Add Custom Packages</label>
            <div className="input-group">
              <SelectCustomPackages
                id="customPackages"
                customPackages={this.state.customPackagesForCountry ?? []}
                selectedCustomPackageIds={basket.customPackages ?? []}
                onSelect={(values) => {
                  this.setState({
                    basket: {
                      ...basket,
                      customPackages: values.map((it) => it.value),
                    },
                  });
                }}
                isDisabled={!isNewPriorityBucket && !editables.customPackages}
                menuPlacement="bottom"
                showOnlyActive={true}
              />
              {this.renderPencilIcon("customPackages")}
            </div>

            <span className="help-block">{errors.customPackages}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="miniPackages">Add Mini Packages</label>
            <div className="input-group">
              <SelectMiniPackages
                id="miniPackages"
                miniPackages={this.state.miniPackagesForCountry ?? []}
                selectedMiniPackageIds={basket.miniPackages ?? []}
                onSelect={(values) => {
                  this.setState({
                    basket: {
                      ...basket,
                      miniPackages: values.map((it) => it.value),
                    },
                  });
                }}
                isDisabled={!isNewPriorityBucket && !editables.miniPackages}
                menuPlacement="bottom"
                showOnlyActive={true}
              />
              {this.renderPencilIcon("miniPackages")}
            </div>

            <span className="help-block">{errors.miniPackages}</span>
          </div>
        </div> */}
        <div className="row">
          <div className="form-group col">
            <label htmlFor="timer">Countdown Timer</label>
            <div className="input-group">
              <input
                value={(basket && basket.timer) || ""}
                onChange={this.handleChange}
                id="timer"
                name="timer"
                type="text"
                placeholder="HH: MM : SS"
                className={
                  "form-control py-2" + (errors.timer ? " has-error" : "")
                }
                disabled={!isNewPriorityBucket && !editables.timer}
              />
              {this.renderPencilIcon("timer")}
            </div>
          </div>
          <div className="form-group col">
            <label htmlFor="status">Status</label>
            <div className="input-group">
              <Dropdown
                value={basket.isActive}
                onChange={(event, value) => {
                  this.setState({
                    basket: {
                      ...basket,
                      isActive: value.value,
                    },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewPriorityBucket && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
        </div>
        <HomecareBasketsForm
        questions={basket?.basketHomecares??[]}
        homeacareServices={this.filteredHomecareServices()??[]}
        onQuestionUpdated={this.handleQuestionsUpdated}
        isEditable={isEditable}
        
        />
      </div>
    );
    return (
      <div className="manage-package">
        <div className="page-header">
          <h5>Priority Basket</h5>
          <div className="actions">
            <div className="action-item">
              <button
                style={{ backgroundColor: "#8FD14F" }}
                className="btn btn-md new-user"
                onClick={this.handleAddNewPriorityBasket}
                disabled={!isEditable}
              >
                <i className="fa fa-plus-circle"></i>Add New
              </button>
            </div>
          </div>
        </div>
        <div className="basket-main">
          {filteredBaskets.length > 0 &&
            filteredBaskets.map((item) => {
              return (
                <div className="priority-basket">
                  <PriorityBasket
                    basket={item}
                    handleView={(basket) => this.renderView(basket.id)}
                    homecareServices={homecareServices}
                    // allBloodPackages={bloodPackages}
                    // allCustomPackages={customPackages}
                    // allMiniPackages={miniPackages}
                  />
                </div>
              );
            })}
        </div>

        <Modal
          size="lg"
          show={isPriorityBucketViewModalVisible}
          onHide={this.hidePriorityBasketViewModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <p style={{ fontWeight: "normal", fontSize: "1rem" }}>
                <span style={{ fontWeight: "bold" }}>
                  {isNewPriorityBucket
                    ? `Add new priority basket`
                    : `${basket.basketName}`}
                </span>
              </p>
            </Modal.Title>
            <div className="user-title"></div>
            <button
              onClick={this.handleSaveBasket}
              disabled={!isEditable}
              className="btn modal-save-btn"
            >
              Save
            </button>
          </Modal.Header>
          <Modal.Body>
            {/* {basketDetails} */}
            <Tabs id="tabs_broadCastingBaskets" justify>
              <Tab
                eventKey="basketDetails"
                title="Details"
              >
                {basketDetails}
              </Tab>
              {this.state.pageId!=null? 
                <Tab
                  eventKey="basket-logs"
                  title="Logs"
                >
                  {/* {logsDetails} */}
                  <Logs pageId={this.state.pageId} pageType='BROADCASTBASKET' />
                </Tab>
              : <div></div> }
            </Tabs>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BroadCastingBaskets);
