export const Page = Object.freeze({
  HOME: "Home",

  // Services section.
  SERVICE_CATEGORY: "Service Category",
  CONDITION_CATEGORIES: "Conditions/Treatment Plans",
  QUESTIONNAIRES: "Questionnaires",
  BUNDLES: "Bundles",
  MEDICINES: "Medicines",
  SUPPLEMENTS: "Supplements",
  OTHER_ITEMS: "Other Items",

  // User management section.
  CLIENTS: "Clients",
  ADMIN_USERS: "Admin Users",
  LAB_USERS: "Lab Users",
  AUTHOR_USERS: "Author Users",
  INTERESTED_PEOPLE: "Interested People",
  CONTACTED_US: "Contacted Us",

  // Order management section.
  CART_ORDERS: "Cart Orders",
  ACTIVE_ORDERS: "Active Orders",
  PAST_ORDERS: "Past Orders",
  ORDERS_CALENDAR_VIEW: "Orders Calendar View",
  WELLBEING_COACH_SUBSCRIPTIONS: "Wellbeing Coach Subscriptions",
  SUBSCRIPTION_ORDERS: "Subscription Orders",

  // Surveys section.
  HEALTH_ASSESSMENT_CATEGORY: "Health Assessment Category",
  HEALTH_ASSESSMENT_QUESTIONS : "Health Assessment Questions",
  HEALTH_ASSESSMENTS_RESULTS: "Health Assessments Results",
  HEALTH_PROFILE_QUESTIONS: "Health Profile Questions",
  HEALTH_PROFILE_RESULTS: "Health Profile Results",
  LIFESTYLE_PROFILE_QUESTIONS: "Lifestyle Profile Questions",
  LIFESTYLE_PROFILE_RESULTS: "Lifestyle Profile Results",
  SURVEY_RESULTS: "Survey Results",

  // Package management section.
  MINI_PACKAGE_CITY_UPDATE: "Mini Package City Update",
  MINI_PACKAGE_UPDATE: "Mini Package Update",
  PRODUCT_CATEGORIES: "Product Categories",
  CUSTOM_PACKAGES: "Custom Packages",
  BLOOD_BIOMARKER_PACKAGES: "Blood Biomarker Packages",
  HEALTH_COACH_PACKAGES: "Health Coach Packages",
  BIOMARKER_CATEGORIES: "Biomarker Categories",
  BIOMARKER_LIST: "Blood Biomarker List",

  NON_BLOOD_BIOMARKER_LIST: "Non Blood Biomarker List",

  // Lab management section.
  TIME_SLOTS: "Time Slots",
  REGIONS: "Regions",
  LABS: "Labs",
  HOMECARE_SERVICES: "Homecare Services",
  LAB_AVAILABILITY_SLOTS: "Lab Availability Slots",  
  LAB_AVAILABILITY_DASHBOARD: "Lab Availability Dashboard",

  // Coach management section.
  WELLBEING_COACHES: "Wellbeing coaches",
  COACH_AVAILABILITY_SLOTS: "Coach Availability Slots",

  // Website cms section.
  WEBSITE_CATEGORIES: "Website Categories",
  PACKAGES_CMS: "Packages CMS",
  LANDING_PAGE: "Landing Page",
  ARTICLE_CATEGORIES: "Article Categories",
  ARTICLES: "Articles",
  BANNERS: "Banners",
  SEARCH_KEYWORDS:"Search Keywords",

  // FAQ section.
  FAQ_DETAILS: "FAQ Details",

  // Ratings section.
  HOW_DO_YOU_HEAR_ABOUT_US :"How Do you Hear About Us",

  REVIEWS: "Reviews",

  // Coupon codes section.
  COUPON_CODES: "Coupon Codes",

  // Referral codes section.
  REFERRAL_CODES: "Referral Codes",

  // Application settings section
  FORCE_RECOMMEND_UPDATE_VERSION: "Force/Recommend update version",
  MOBILE_PROMOTIONS: "Mobile Promotions",

  // Exchange rates
  EXCHANGE_RATES: "Exchange Rates",

  // Corporate partners section.
  VALEO_AMBASSADORS: "Valeo Ambassadors",



  //Homecare users

  HOMECARE_USERS: "Homecare Users",


  //Lab Costs

 LAB_COSTS: "Lab Costs",



 //Homecare costs

 HOMECARE_COSTS: "Homecare Costs",

 FOOD_CATEGORIES: "Food Categories",

 LIST_OF_FOOD:"List Of Food",




 //Custom Test Addons

 CUSTOM_TEST_ADDONS: "Custom Test Add-ons",

  FITNESSPLAN:"Fitness Plan",

  DEWA_PARTNERS:"Dewa",



  APP_BANNERS:"App Banners",
  HOME_PAGE_RANKINGS:"Home Page Rankings",

});
